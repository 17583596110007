import { ReactiveMap } from "@solid-primitives/map";
import { RealtimeChannel } from "@supabase/supabase-js";
import { batch } from "solid-js";
import { Tables } from "supabase";
import { supabase } from "./supabase";

export async function fetchTaxistations() {
  const { data, error } = await supabase
    .from("taxistations")
    .select("*")
    .order("zipcode_id", { ascending: true })
    .order("sortorder", { ascending: true });

  if (error) throw error;

  batch(() => {
    for (const taxistation of data) {
      liveTaxistationsMap.set(taxistation.id, taxistation);
    }
  });

  return data;
}

export const liveTaxistationsMap = new ReactiveMap<number, Tables<"taxistations">>();

let liveTaxistationsSubscription: RealtimeChannel | undefined = undefined;

export async function subscribeToLiveTaxistations() {
  if (!liveTaxistationsSubscription) {
    await fetchTaxistations();
    liveTaxistationsSubscription = supabase
      .channel("live_taxistations")
      .on<Tables<"taxistations">>(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "taxistations",
        },
        (payload) => {
          if (payload.eventType === "DELETE") {
            liveTaxistationsMap.delete(payload.old.id!);
          } else {
            liveTaxistationsMap.set(payload.new.id, payload.new);
          }
        }
      )
      .subscribe();

    console.log("sub taxistations");
  }
}

export async function unsubscribeFromLiveTaxistations() {
  console.log("unsub taxistations");
  if (liveTaxistationsSubscription) {
    liveTaxistationsMap.clear();
    await supabase.removeChannel(liveTaxistationsSubscription);
    liveTaxistationsSubscription = undefined;
  }
}

export async function setActiveTaxistationRequest(taxistation_id: number, zipcode_id: number) {
  await supabase
    .from("taxistations")
    .update({
      active: false,
    })
    .eq("zipcode_id", zipcode_id)
    .neq("id", taxistation_id)
    .throwOnError();

  await supabase
    .from("taxistations")
    .update({
      active: true,
    })
    .eq("id", taxistation_id)
    .throwOnError();
}

export async function setDisabledTaxistationRequest(taxistation_id: number, disabled: boolean) {
  await supabase
    .from("taxistations")
    .update({
      disabled,
    })
    .eq("id", taxistation_id)
    .throwOnError();
}

export async function setDisabledZipcodeRequest(zipcode_id: number, disabled: boolean) {
  await supabase
    .from("taxistations")
    .update({
      disabled,
      active: disabled ? false : undefined,
    })
    .eq("zipcode_id", zipcode_id)
    .throwOnError();
}
