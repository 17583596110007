import { Transformer, useDragDropContext } from "@thisbeyond/solid-dnd";
import { Component } from "solid-js";

type Props = {
  axis: "x" | "y";
};

export const DragDropConstrainAxis: Component<Props> = (props) => {
  const [, { onDragStart, onDragEnd, addTransformer, removeTransformer }] = useDragDropContext()!;

  const transformer: Transformer = {
    id: `constrain-${props.axis}-axis`,
    order: 100,
    callback: (transform) => ({ ...transform, [props.axis]: 0 }),
  };

  onDragStart(({ draggable }) => {
    addTransformer("draggables", draggable.id, transformer);
  });

  onDragEnd(({ draggable }) => {
    removeTransformer("draggables", draggable.id, transformer.id);
  });

  return null;
};
