import { MyDate } from "./MyDate.class";
import { MyTimestamp } from "./MyTimestamp.class";
import { Clonable, Comparable, Serializable } from "./utils";

/**
 * Class representing a time value within a single day.
 */
export class MyTime implements Serializable<string>, Clonable<MyTime>, Comparable {
  private constructor(seconds: number) {
    // Clamp the value to be within a single day
    this.seconds = Math.max(0, Math.min(seconds, 86399));
  }

  private seconds: number;

  /**
   * Creates a MyTime instance from a JSON string representation.
   * @param value - A string in the format "hh:mm:ss", "hh:mm", or "hh".
   * @returns A new MyTime instance created from the parsed string.
   * @throws {Error} Implicitly, if the string cannot be parsed correctly.
   * @example
   * const time1 = MyTime.fromJSON("14:30:45");
   * const time2 = MyTime.fromJSON("09:15");
   * const time3 = MyTime.fromJSON("23");
   */
  static fromJSON(value: string): MyTime {
    const parts = value.split(":");
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (parts.length > 0) {
      hours = parseInt(parts[0], 10);
    }
    if (parts.length > 1) {
      minutes = parseInt(parts[1], 10);
    }
    if (parts.length > 2) {
      seconds = parseInt(parts[2], 10);
    }

    return MyTime.fromParts({ hours, minutes, seconds });
  }

  /**
   * Creates a MyTime instance from a Date object.
   * @param date - The Date object to extract time from.
   * @returns A new MyTime instance representing the time of the given date.
   */
  static fromDate(date: Date): MyTime {
    const seconds = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
    return new MyTime(seconds);
  }

  /**
   * Creates a MyTime instance representing the current time.
   * @returns A new MyTime instance representing the current time.
   */
  static now(): MyTime {
    return MyTime.fromDate(new Date());
  }

  /**
   * Creates a MyTime instance from an object with properties hours, minutes, and seconds.
   * @param values - An object with properties hours, minutes, and seconds.
   * @returns A new MyTime instance with the specified time values.
   */
  static fromParts(values: { hours?: number; minutes?: number; seconds?: number }): MyTime {
    const hours = values.hours ?? 0;
    const minutes = values.minutes ?? 0;
    const seconds = values.seconds ?? 0;

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return new MyTime(totalSeconds);
  }

  /**
   * Creates a MyTime instance from a given number of seconds.
   * @param seconds - The number of seconds.
   * @returns A new MyTime instance.
   */
  static fromSeconds(seconds: number): MyTime {
    return new MyTime(seconds);
  }

  /**
   * Creates a MyTime instance from a given number of minutes.
   * @param minutes - The number of minutes.
   * @returns A new MyTime instance.
   */
  static fromMinutes(minutes: number): MyTime {
    return new MyTime(minutes * 60);
  }

  /**
   * Creates a MyTime instance from a given number of hours.
   * @param hours - The number of hours.
   * @returns A new MyTime instance.
   */
  static fromHours(hours: number): MyTime {
    return new MyTime(hours * 3600);
  }

  /**
   * Returns the stored time as a string in the format "hh:mm:ss".
   * @returns The time as a string.
   */
  toString(): string;

  /**
   * Returns the stored time as a string with the specified number of parts.
   * @param parts - The number of parts to include (1: hours, 2: hours and minutes, 3: hours, minutes, and seconds).
   * @returns The time as a string with the specified parts.
   */
  toString(parts: number): string;
  toString(parts?: number): string {
    const hours = Math.floor(this.seconds / 3600);
    const minutes = Math.floor((this.seconds % 3600) / 60);
    const seconds = this.seconds % 60;

    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secondsStr = seconds.toString().padStart(2, "0");

    switch (parts) {
      case 1:
        return `${hoursStr}`;
      case 2:
        return `${hoursStr}:${minutesStr}`;
      case 3:
      default:
        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }
  }

  /**
   * Returns the seconds part of the stored time.
   * @returns The seconds part as a number.
   */
  getSeconds(): number {
    return this.seconds % 60;
  }

  /**
   * Returns the minutes part of the stored time.
   * @returns The minutes part as a number.
   */
  getMinutes(): number {
    return Math.floor((this.seconds % 3600) / 60);
  }

  /**
   * Returns the hours part of the stored time.
   * @returns The hours part as a number.
   */
  getHours(): number {
    return Math.floor(this.seconds / 3600);
  }

  /**
   * Sets the time values and returns a new MyTime instance with those values set.
   * @param values - An object with properties hours, minutes, and seconds.
   * @returns A new MyTime instance with the specified time values.
   */
  set(values: { hours?: number; minutes?: number; seconds?: number }): MyTime {
    const hours = values.hours ?? this.getHours();
    const minutes = values.minutes ?? this.getMinutes();
    const seconds = values.seconds ?? this.getSeconds();

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return new MyTime(totalSeconds);
  }

  clone(): MyTime {
    return new MyTime(this.seconds);
  }

  valueOf(): number {
    return this.seconds;
  }

  toJSON(): string {
    return this.toString();
  }

  getMyTimestamp(myDate?: MyDate): MyTimestamp {
    return MyTimestamp.fromMyDateAndMyTime(myDate ?? MyDate.today(), this);
  }
}
