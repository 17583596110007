import * as API from "mooovex-api-schema";
import { Component, For } from "solid-js";
import { destinations, origins, routes } from "../../services/state/newpricelist.state";

export const HTMLTable: Component = () => {
  return (
    <table>
      <thead>
        <tr>
          <td>Preisliste</td>
          <For each={destinations()}>
            {(destination) => (
              <>
                <td>{destination.name}</td>
                <td></td>
              </>
            )}
          </For>
        </tr>
      </thead>
      <tbody>
        <For each={origins()}>
          {(origin) => (
            <tr>
              <td>{origin.name}</td>
              <For each={destinations()}>
                {(destination) => {
                  const route = () =>
                    routes().find(
                      (route) =>
                        route.originId === API.PlaceAdapter.getId(origin) &&
                        route.destinationId === API.PlaceAdapter.getId(destination)
                    );
                  return (
                    <>
                      <td>{route()?.price?.full.toFixed(2).replaceAll(".", ",")}</td>
                      <td>{route()?.price?.person.toFixed(2).replaceAll(".", ",")}</td>
                    </>
                  );
                }}
              </For>
            </tr>
          )}
        </For>
      </tbody>
    </table>
  );
};
export default HTMLTable;
