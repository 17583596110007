import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { A } from "@solidjs/router";
import { Component, createSignal, Show } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import * as localized from "./localized_schemas";

const getSchema = () =>
  z.object({
    email: localized.email(),
  });

type Schema = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  onSubmit?: (resetData: Schema) => Promise<void>;
  email?: string;
  loginHref: string;
};

export const ResetPasswordForm: Component<Props> = (props) => {
  const [resetStatus, setResetStatus] = createSignal<"ready" | "error" | "loading" | "success">("ready");

  const { form, errors, isValid, data } = createForm<Schema>({
    extend: [validator({ schema: getSchema() })],
    onSubmit: async (values) => {
      setResetStatus("loading");
      try {
        await props.onSubmit?.(values);
        setResetStatus("success");
      } catch (error) {
        console.log(error);
        setResetStatus("error");
      }
    },
    initialValues: { email: props.email },
  });

  return (
    <form ref={form}>
      <fieldset disabled={resetStatus() === "loading" || resetStatus() === "success"}>
        <div class="form-text mb-3">{ct.account.passwordResetInstructions()}</div>
        <div class="form-floating mb-1">
          <input
            name="email"
            type="email"
            class="form-control"
            classList={{ "is-invalid": !!errors().email?.length }}
            placeholder="name@example.com"
          />
          <label for="email" class="form-label">
            {ct.account.email._()}
          </label>
          <div class="text-danger" classList={{ invisible: !errors().email?.length }}>
            {errors().email?.[0] ?? "-"}
          </div>
        </div>
        <Show when={resetStatus() === "error"}>
          <div class="alert alert-danger">{ct.validation.passwordResetFail()}</div>
        </Show>
        <Show when={resetStatus() !== "success"}>
          <button
            type="submit"
            class="btn btn-lg btn-primary w-100 d-flex justify-content-center align-items-center gap-2"
            disabled={!isValid()}
          >
            <Show when={resetStatus() === "loading"}>
              <div class="spinner-border spinner-border-sm" />
            </Show>
            {ct.account.resetPassword()}
          </button>
        </Show>
      </fieldset>
      <Show when={resetStatus() === "success"}>
        <div class="alert alert-success">{ct.validation.passwordResetSuccess(data().email)}</div>
      </Show>
      <A href={props.loginHref} class="form-text">
        {ct.account.login()}
      </A>
    </form>
  );
};

export default ResetPasswordForm;
