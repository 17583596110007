import { z } from "./openapizod";
import * as Shared from "./shared.api.schema";

export const rideVisibility = z.enum(["connected", "global", "hidden"]);

export type RideVisibility = z.infer<typeof rideVisibility>;

export const rideStatus = z.enum(["aborted", "cancelled", "completed", "ongoing"]);

export type RideStatus = z.infer<typeof rideStatus>;

export const responseBody = z.object({
  id: z.string(),
  booking_id: z.string().optional(),
  previous_ride_id: z.string().optional(),
  transportcompany_id: z.string().optional(),
  transportcompany_name: z.string().optional(),
  ride_visibility: rideVisibility,
  ride_status: rideStatus.optional(),
  comments: z.string().optional(),
  passenger_count: z.number().int().optional(),
  extras: Shared.json.optional(),
  price: z.number().optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;

export namespace Get {
  export const requestParams = z.object({ id: z.string() });
  export type RequestParams = z.infer<typeof requestParams>;
}

export namespace Post {
  export const requestBody = z.object({
    id: z.string().optional(),
    booking_id: z.string().optional(),
    previous_ride_id: z.string().optional(),
    transportcompany_id: z.string().optional(),
    transportcompany_name: z.string().optional(),
    ride_visibility: rideVisibility.optional(),
    ride_status: rideStatus.optional(),
    comments: z.string().optional(),
    passenger_count: z.number().int().optional(),
    extras: Shared.json.optional(),
    price: z.number().optional(),
  });
  export type RequestBody = z.infer<typeof requestBody>;
}
