import { ParentComponent } from "solid-js";
import classes from "./MapControlWrapper.module.scss";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export const MapControlWrapper: ParentComponent<Props> = (props) => {
  const inset = () => `${props.top ?? 0}px ${props.right ?? 0}px ${props.bottom ?? 0}px ${props.left ?? 0}px`;

  return (
    <>
      <div
        class={classes.parent}
        style={{
          inset: inset(),
        }}
      >
        {props.children}
      </div>
    </>
  );
};
