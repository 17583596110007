import { captureException } from "@sentry/browser";
import { ModalContext, createPlaceSearchBox, ct, currentLocale } from "components";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, createSignal, useContext } from "solid-js";
import { addDestination, addOrigin } from "../../services/state/newpricelist.state";

async function autocomplete(value: string) {
  if (value.length < 3) {
    return [];
  }

  try {
    const result = await mooovexApiClient.place.autocomplete(value, currentLocale());
    return result;
  } catch (error) {
    alert(ct.place.failedToGetPlaces());
    captureException(error);
    return [];
  }
}

async function autocompleteItemToPlace(
  value: API.google_autocomplete.ResponseBodyItem
): Promise<API.google_place_details.ResponseBody> {
  const response = await mooovexApiClient.place.get(value.google_place_id, currentLocale());
  return response;
}

export const AddPlaceModal: Component = () => {
  const [place, setPlace] = createSignal<API.google_place_details.ResponseBody | undefined>(undefined);

  const [ModalComponent, { onShow }] = useContext(ModalContext)!;

  onShow(() => clearInput());

  const [
    PlaceSearch,
    {
      clear: clearInput,
      inputFocused: isInputFocused,
      getCurrentLocation: GetCurrentLocation,
      setPlace: inputSetPlace,
    },
  ] = createPlaceSearchBox({
    onChoose: async (autocompleteItem) => {
      if (autocompleteItem) {
        try {
          const result = await autocompleteItemToPlace(autocompleteItem);
          setPlace(result);
        } catch (error) {
          captureException(error);
          alert(ct.place.failedToGetDetails());
        }
      }
    },
    onSearch: autocomplete,
  });

  return (
    <>
      <div class="modal-header">
        <h5 class="modal-title">Add Place</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" />
      </div>
      <div class="modal-body">
        <PlaceSearch placeholder="Search..." />
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-dismiss="modal" disabled={!place()} onclick={() => addOrigin(place()!)}>
          Add Origin
        </button>
        <button
          class="btn btn-primary"
          data-bs-dismiss="modal"
          disabled={!place()}
          onclick={() => addDestination(place()!)}
        >
          Add Destination
        </button>
      </div>
    </>
  );
};

export default AddPlaceModal;
