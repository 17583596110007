export * from "./ClearRideFormButton";
export * from "./DragAndDropPlaces";
export * from "./PassengerCountButton";
export * from "./PassengerCountSelectorOverlay";
export * from "./PlaceAddButton";
export * from "./PlaceSwapButton";
export * from "./PriceOptionsOverlay";
export * from "./RideFormComponent";
export * from "./RideFormProvider";
export * from "./RideInfo";
export * from "./SimplePriceOptionsOverlay";
export * from "./StartDateButton";
export * from "./StartTimeButton";
import { MyDate, MyTime } from "helpers";
import * as API from "mooovex-api-schema";
import { useContext } from "solid-js";
import TypedEmitter from "typed-emitter";
import { RideFormContext } from "./RideFormProvider";

type Events = {
  "passengerCount.show": (value?: number) => void;
  "passengerCount.value": (value?: number) => void;
  "passengerCount.hide": () => void;
  "startDatePicker.show": (value?: MyDate) => void;
  "startDatePicker.value": (value?: MyDate) => void;
  "startTimePicker.show": (value?: MyTime) => void;
  "startTimePicker.value": (value?: MyTime) => void;
  "placeSearch.show": (containerId: string, value?: string) => void;
  "placeSearch.value": (containerId: string, value?: API.google_place_details.ResponseBody) => void;
  "placeSearch.error": (message: string) => void;
  "placeSearch.currentLocation": (containerId: string) => void;
  "placeSearch.favorite.add": (place: API.google_autocomplete.ResponseBodyItem) => void;
  "placeSearch.favorite.remove": (id: number | string) => void;
  "placeSearch.openInGoogleMaps": (
    place: API.google_place_details.ResponseBody | API.Shared.GeoJson.Coordinates
  ) => void;
  "priceOptions.show": () => void;
  "priceOptions.hide": () => void;
  "priceOptions.toggle": () => void;
  "priceOptions.reset": () => void;
  clear: () => void;
};

export type RideFormEmitter = TypedEmitter<Events>;

export function useRideFormContext() {
  const context = useContext(RideFormContext);
  if (!context) throw new Error("You didn't use useRideFormContext inside a RideFormProvider");
  return context;
}
