import { captureException } from "@sentry/browser";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, Show } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useRideFormContext } from "../rideForm/rideForm.common";
import { PlaceSearchIcon } from "./placeSearch.common";

type Props = {
  place: API.google_autocomplete.ResponseBodyItem;
  containerId: string;
  isFavorited?: boolean;
  compact?: boolean;
};

async function autocompleteItemToPlace(autocompleteItem: API.google_autocomplete.ResponseBodyItem) {
  const result = await mooovexApiClient.place.get(autocompleteItem.google_place_id, currentLocale());
  result.name = autocompleteItem.name;
  result.formatted_address = autocompleteItem.formatted_address;
  return result;
}

export const PlaceSearchAutocompleteItem: Component<Props> = (props) => {
  const { emitter } = useRideFormContext();
  const address = () => API.PlaceAdapter.getAddress(props.place);

  return (
    <>
      <div
        class="d-flex align-items-center gap-2 cursor-pointer"
        onclick={async () => {
          try {
            const place = await autocompleteItemToPlace(props.place);
            emitter.emit("placeSearch.value", props.containerId, place);
          } catch (error) {
            emitter.emit("placeSearch.error", ct.place.failedToGetDetails());
            captureException(error);
          }
        }}
      >
        <PlaceSearchIcon place={props.place} />
        <div
          class="flex-grow-1"
          classList={{
            "text-nowrap": props.compact,
            "text-truncate": props.compact,
          }}
        >
          <span class="fw-bold">{props.place.name}</span>&nbsp;<span class="text-muted">{address()}</span>
        </div>
        <Show
          when={!props.isFavorited}
          fallback={
            <i
              onclick={(e) => {
                e.stopPropagation();
                emitter.emit("placeSearch.favorite.remove", API.PlaceAdapter.getId(props.place));
              }}
              class="text-primary bi-star-fill cursor-pointer"
            />
          }
        >
          <i
            onclick={(e) => {
              e.stopPropagation();
              emitter.emit("placeSearch.favorite.add", props.place);
            }}
            class="text-primary bi-star"
          />
        </Show>
      </div>
    </>
  );
};
