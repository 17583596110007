import { Component, Show } from "solid-js";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  disabled?: boolean;
};

export const PassengerCountButton: Component<Props> = (props) => {
  const {
    emitter,
    passengerCountSignal: [passengerCount],
  } = useRideFormContext();

  return (
    <button
      disabled={props.disabled}
      class="d-flex btn btn-lg flex-grow-1 gap-1 justify-content-center flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !passengerCount(),
        "btn-primary": !!passengerCount(),
      }}
      onclick={() => emitter.emit("passengerCount.show", passengerCount())}
    >
      <i class="bi-person-fill" />
      <Show when={passengerCount()}>
        <span>{passengerCount()}</span>
      </Show>
    </button>
  );
};
