import { makePersisted } from "@solid-primitives/storage";
import { X } from "lucide-solid";
import {
  Component,
  For,
  Match,
  Switch,
  createEffect,
  createSelector,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";
import { ct } from "../i18n";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  max: number;
  min: number;
  step: number;
};

type PriceDiscountPreset = { name: string; discount: number } | "default";

const presets: PriceDiscountPreset[] = [
  "default",
  { name: "-10%", discount: -0.1 },
  { name: "Diurno (-20%)", discount: -0.2 },
];

export const SimplePriceOptionsOverlay: Component<Props> = (props) => {
  const {
    emitter,
    discountSignal: [discount, setDiscount],
  } = useRideFormContext();
  const [isShown, setIsShown] = createSignal();
  const [selectedPresetIndex, setSelectedPresetIndex] = makePersisted(createSignal<number>(0), {
    name: "mooovex:currentRide.priceOptions.selectedPresetIndex",
  });
  const isSelected = createSelector(selectedPresetIndex);

  const show = () => setIsShown(true);
  const hide = () => setIsShown(false);
  const toggle = () => setIsShown(!isShown());
  const reset = () => setSelectedPresetIndex(0);

  onMount(() => {
    emitter.on("priceOptions.show", show);
    emitter.on("priceOptions.hide", hide);
    emitter.on("priceOptions.toggle", toggle);
    emitter.on("priceOptions.reset", reset);
  });

  onCleanup(() => {
    emitter.off("priceOptions.show", show);
    emitter.off("priceOptions.hide", hide);
    emitter.off("priceOptions.toggle", toggle);
    emitter.off("priceOptions.reset", reset);
  });

  createEffect(() => {
    const selectedPreset = presets[selectedPresetIndex()];
    if (typeof selectedPreset === "object") {
      setDiscount(selectedPreset.discount);
    } else if (selectedPreset === "default") {
      setDiscount(0);
    }
  });

  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        "z-index": 4,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${isShown() ? "0" : "100%"})`,
        overflow: "hidden",
        padding: "6px",
      }}
    >
      <div class="position-relative d-flex gap-2 justify-content-center align-items-center h-100">
        <X
          class="position-absolute cursor-pointer"
          style={{ top: "6px", right: "6px" }}
          onclick={() => emitter.emit("priceOptions.hide")}
        />
        <div class="btn-group">
          <For each={presets}>
            {(preset, i) => (
              <button
                class="btn"
                classList={{
                  "btn-border-primary": !isSelected(i()),
                  "btn-primary": isSelected(i()),
                }}
                onclick={() => setSelectedPresetIndex(i())}
              >
                {typeof preset === "object" ? (
                  preset.name
                ) : (
                  <Switch>
                    <Match when={preset === "default"}>{ct.common.normal()}</Match>
                  </Switch>
                )}
              </button>
            )}
          </For>
        </div>
      </div>
    </div>
  );
};
