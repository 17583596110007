import { Component, mergeProps } from "solid-js";

type Props = {
  value?: string;
};

export const LicensePlate: Component<Props> = (componentProps) => {
  const props = mergeProps({ value: "" }, componentProps);
  return <span class="border border-dark rounded ps-1 pe-1 text-nowrap">{props.value}</span>;
};
