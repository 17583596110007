import { X } from "lucide-solid";
import { Component, Index, createSelector, createSignal, onCleanup, onMount } from "solid-js";
import classes from "./PassengerCountSelectorOverlay.module.scss";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  max: number;
  min: number;
};

export const PassengerCountSelectorOverlay: Component<Props> = (props) => {
  const {
    emitter,
    passengerCountSignal: [passengerCount, setPassengerCount],
  } = useRideFormContext();
  const [isShown, setIsShown] = createSignal(false);
  const isSelected = createSelector(passengerCount);

  const show = () => setIsShown(true);
  const hide = () => setIsShown(false);

  onMount(() => {
    emitter
      .on("passengerCount.show", show)
      .on("passengerCount.hide", hide)
      .on("passengerCount.value", setPassengerCount);
  });

  onCleanup(() => {
    emitter
      .off("passengerCount.show", show)
      .off("passengerCount.hide", hide)
      .off("passengerCount.value", setPassengerCount);
  });

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "z-index": 4,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${isShown() ? "0" : "100%"})`,
        overflow: "hidden",
        padding: "6px",
      }}
    >
      <div class="position-relative d-flex gap-2 justify-content-center align-items-center h-100">
        <X
          class="position-absolute cursor-pointer"
          style={{ top: "6px", right: "6px" }}
          onclick={() => {
            emitter.emit("passengerCount.value", undefined);
            emitter.emit("passengerCount.hide");
          }}
        />
        <div class={classes.container}>
          <Index each={new Array(props.max - props.min + 1)}>
            {(_, i) => {
              return (
                <button
                  class={`btn ${classes.button}`}
                  classList={{
                    "btn-primary": isSelected(i + props.min),
                    "btn-border-primary": !isSelected(i + props.min),
                  }}
                  onclick={() => {
                    emitter.emit("passengerCount.value", i + props.min);
                    emitter.emit("passengerCount.hide");
                  }}
                >
                  {i + props.min}
                </button>
              );
            }}
          </Index>
        </div>
      </div>
    </div>
  );
};
