import { responseBody as confirmationsResponseBody } from "./onlinebooking.confirm.api.schema";
import { z } from "./openapizod";
import * as PlaceAdapter from "./placeAdapter.shared.api.schema";
import { chosenVehicleExtra } from "./price.api.schema";
import * as Shared from "./shared.api.schema";
import { vehicleExtraOrGroup } from "./vehicleproducts.api.schema";

export const booking_timing = z.enum(["now", "later"]);
export type BookingTiming = z.infer<typeof booking_timing>;

export const onlinebooking_type = z.enum(["request", "booking"]);
export type OnlinebookingType = z.infer<typeof onlinebooking_type>;

const baseTransfer = z.object({
  id: z.string(),
  created_at: z.string(),
  origin: PlaceAdapter.place,
  destination: PlaceAdapter.place,
  route_id: z.string().optional(),
  date: Shared.date,
  time: Shared.time,
  passenger_count: z.number().int().nonnegative(),
  flightnumber: z.string().max(8).optional(),
  trainnumber: z.string().optional(),
  comments: z.string().optional(),
  vehicleproduct_id: z.string().optional(),
  extras: z.array(chosenVehicleExtra),
  price: z.number().nonnegative(),
  confirmations: confirmationsResponseBody.array(),
});

export namespace Get {
  export const requestParams = z.object({
    onlinebooking_config_id: z.string(),
    onlinebooking_id: z.string(),
  });
  export type RequestParams = z.infer<typeof requestParams>;

  export const transfer = baseTransfer.extend({
    vehicleproduct: z
      .object({
        id: z.string(),
        imageUrls: z.array(z.string()),
        name_i18n: z
          .record(Shared.locale, z.string())
          .openapi({ example: { de: "Deutsch", en: "English", it: "Italiano" } }),
        maxPassengerCount: z.number().int(),
        minPassengerCount: z.number().int(),
        extras: z.array(vehicleExtraOrGroup),
      })
      .optional(),
  });
  export type Transfer = z.infer<typeof transfer>;
}
export const responseBody = z.object({
  id: z.string(),
  created_at: z.string(),
  booking_number: z.string(),
  booking_type: onlinebooking_type,
  booking_timing: booking_timing,
  transfer: Get.transfer,
  return_transfer: Get.transfer.optional(),
  customer_name: z.string(),
  customer_email: z.string().email().optional(),
  customer_phone: Shared.phoneNumber,
  receive_updates: z.boolean(),
  price: z.number().nonnegative(),
  language: Shared.locale.optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;
export namespace Post {
  export const requestParams = z.object({
    onlinebooking_config_id: z.string(),
  });
  export type RequestParams = z.infer<typeof requestParams>;

  export const transfer = baseTransfer.omit({ id: true, created_at: true, confirmations: true });
  export type Transfer = z.infer<typeof transfer>;

  export const requestBody = responseBody
    .extend({ transfer, return_transfer: transfer.optional() })
    .omit({ id: true, created_at: true, booking_number: true });
  export type RequestBody = z.infer<typeof requestBody>;
}

export namespace Put {
  export const requestParams = z.object({
    onlinebooking_config_id: z.string(),
    onlinebooking_id: z.string(),
  });
  export type RequestParams = z.infer<typeof requestParams>;

  export const transfer = baseTransfer.omit({ id: true, created_at: true, confirmations: true });
  export type Transfer = z.infer<typeof transfer>;

  export const requestBody = responseBody
    .extend({ transfer, return_transfer: transfer.optional() })
    .omit({ id: true, created_at: true, booking_number: true });
  export type RequestBody = z.infer<typeof requestBody>;
}
