import { Locale } from "mooovex-api-schema/src/shared.api.schema";
import { Component, For, createSignal } from "solid-js";
import { setLocaleRequest } from "supabase-client";
import { SUPPORTED_LOCALES, currentLocale, setLocale } from "./i18n";

type Props = {
  onError?: (error: unknown) => void;
};

export const LocaleSelector: Component<Props> = (props) => {
  const [loading, setLoading] = createSignal(false);

  async function changeLocale(locale: Locale) {
    setLoading(true);
    try {
      const updatedLocale = await setLocaleRequest(locale);
      setLocale(updatedLocale);
    } catch (error) {
      props.onError?.(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        class="btn-group w-100"
        classList={{
          "grayed-out": loading(),
        }}
      >
        <For each={SUPPORTED_LOCALES}>
          {(locale) => (
            <button
              class="btn"
              onclick={() => changeLocale(locale)}
              classList={{
                "btn-border-primary": currentLocale() !== locale,
                "btn-primary": currentLocale() === locale,
              }}
            >
              {locale.toUpperCase()}
            </button>
          )}
        </For>
      </div>
    </>
  );
};
