import { useNavigate, useSearchParams } from "@solidjs/router";
import { FormCard, RegisterForm, currentLocale } from "components";
import { Component } from "solid-js";
import { signUp } from "supabase-client";

const RegisterPage: Component = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<{ email?: string }>();

  return (
    <FormCard>
      <RegisterForm
        onSubmit={async (userData) => {
          if (
            await signUp(userData.email, userData.password, {
              first_name: userData.name,
              last_name: userData.surname,
              phone: userData.phone,
              locale: currentLocale(),
            })
          ) {
            navigate(`/login?confirmEmail=${userData.email}`);
          } else {
            throw new Error("Registration failed");
          }
        }}
        loginHref="/login"
        email={searchParams.email}
      />
    </FormCard>
  );
};

export default RegisterPage;
