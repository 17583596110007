import { GeoJSONSource } from "maplibre-gl";
import type * as API from "mooovex-api-schema";
import { Component, createEffect, createUniqueId, mergeProps, onCleanup, onMount } from "solid-js";
import { useMapContext } from "./map.common";

type Props = {
  features: API.Shared.GeoJson.Feature[];
  radius?: number;
  color?: string;
  opacity?: number;
  alignment?: "map" | "viewport";
  minzoom?: number;
  maxzoom?: number;
  propertyKeys?: {
    color?: string;
    opacity?: string;
    radius?: string;
    disabled?: string;
  };
};

export const MapFeatureCircles: Component<Props> = (componentProps) => {
  const props = mergeProps<[Partial<Props>, Props]>(
    {
      alignment: "map",
      color: "#002672",
      opacity: 0.3,
      minzoom: 13,
      maxzoom: 24,
      radius: 18,
    },
    componentProps
  );

  const map = useMapContext();
  const layerId = createUniqueId();
  onMount(() => {
    map.addLayer({
      id: layerId,
      type: "circle",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
      filter: props.propertyKeys?.disabled ? ["!=", "true", props.propertyKeys.disabled] : undefined,
      paint: {
        "circle-radius": props.propertyKeys?.radius ? ["get", props.propertyKeys.radius] : props.radius,
        "circle-color": props.propertyKeys?.color ? ["get", props.propertyKeys.color] : props.color,
        "circle-opacity": props.propertyKeys?.opacity ? ["get", props.propertyKeys.opacity] : props.opacity,
        "circle-stroke-width": 1,
        "circle-stroke-color": props.propertyKeys?.color ? ["get", props.propertyKeys.color] : props.color,
        "circle-stroke-opacity": 1,
        "circle-pitch-alignment": props.alignment,
        "circle-pitch-scale": props.alignment,
      },
      minzoom: props.minzoom,
      maxzoom: props.maxzoom,
    });
  });

  createEffect(() => {
    const source = map.getSource(layerId) as GeoJSONSource | undefined;
    if (source) {
      source.setData({
        type: "FeatureCollection",
        features: props.features,
      });
    }
  });

  onCleanup(() => {
    map.removeLayer(layerId);
  });
  return null;
};
