import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/mobile";
import semverValid from "semver/functions/valid";
import { z } from "./openapizod";
export { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export const locale = z.enum(["de", "en", "it"]);
export type Locale = z.infer<typeof locale>;

export const i18nValue = z.object({
  de: z.string(),
  en: z.string(),
  it: z.string(),
});
export type I18nValue = z.infer<typeof i18nValue>;

export namespace Driverstatus {
  export const values = ["available", "occupied", "empty", "invisible", "outofservice"] as const;

  export const driverstatus = z.enum(values);
  export type DriverStatus = z.infer<typeof driverstatus>;

  export const visible_driverstatus = driverstatus.extract(["available", "occupied", "empty"]);
  export type VisibleDriverstatus = z.infer<typeof visible_driverstatus>;
  export const isVisible = (status: DriverStatus) => visible_driverstatus.safeParse(status).success;

  export const invisible_driverstatus = driverstatus.extract(["outofservice", "invisible"]);
  export type InvisibleDriverstatus = z.infer<typeof invisible_driverstatus>;
  export const isInvisible = (status: DriverStatus) => invisible_driverstatus.safeParse(status).success;

  export const occupy_vehicle_driverstatus = driverstatus.extract(["available", "occupied", "empty", "invisible"]);
  export type OccupyVehicleDriverstatus = z.infer<typeof occupy_vehicle_driverstatus>;
  export const canOccupyVehicle = (status: DriverStatus) => occupy_vehicle_driverstatus.safeParse(status).success;
}

export type Json = string | number | boolean | null | { [key: string]: Json | undefined } | Json[];
export const json = z.custom<Json>();

export namespace GeoJson {
  export const coordinates = z.array(z.number()).min(2);

  export const point = z.object({
    type: z.literal("Point"),
    coordinates,
  });

  export const linestring = z.object({
    type: z.literal("LineString"),
    coordinates: z.custom<number[][]>(),
  });

  export const feature = z.object({
    type: z.literal("Feature"),
    geometry: point.or(linestring),
    properties: z.record(z.string(), z.any()),
  });

  export type Coordinates = z.infer<typeof coordinates>;
  export type Point = z.infer<typeof point>;
  export type LineString = z.infer<typeof linestring>;
  export type Feature = z.infer<typeof feature>;

  export function makePoint(coordinates: Coordinates): Point {
    return {
      type: "Point",
      coordinates,
    };
  }
}

type ReplaceNullWithUndefined<T> = T extends null ? undefined : T;

type TopLevelReplaceNullWithUndefined<T> = T extends Date
  ? T
  : T extends (infer U)[]
    ? ReplaceNullWithUndefined<U>[]
    : {
        [K in keyof T]: ReplaceNullWithUndefined<T[K]>;
      };

export function nullsToUndefined<T>(obj: T): TopLevelReplaceNullWithUndefined<T> {
  if (obj === null) {
    return undefined as any;
  }

  // object check based on: https://stackoverflow.com/a/51458052/6489012
  if (obj?.constructor.name === "Object") {
    for (let key in obj) {
      if (obj[key] === null) {
        obj[key] = undefined as any;
      }
    }
  }
  return obj as any;
}

function isValidDate(value: string) {
  if (dateRegex.test(value)) {
    const [year, month, day] = value.split("-").map((v) => parseInt(v));
    const date = new Date(year, month - 1, day);

    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  }

  return false;
}

function isValidTime(value: string) {
  if (timeRegex.test(value)) {
    const [hours, minutes, seconds] = value.split(":").map((v) => parseInt(v));
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59 && seconds >= 0 && seconds <= 59;
  }
  return false;
}

const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

export const date = z.string().refine(isValidDate, { message: "invalid date" }).openapi({ example: "2024-02-15" });
export const time = z.string().refine(isValidTime, { message: "invalid time" }).openapi({ example: "14:25:00" });

export const phoneNumber = z
  .string()
  .refine((input) => isValidPhoneNumber(input))
  .transform((input) => parsePhoneNumber(input).number.toString())
  .openapi({ example: "+393351412548" });

export type PhoneNumber = z.infer<typeof phoneNumber>;

export const stringToInt = (val: string, ctx: z.RefinementCtx) => {
  const number = parseInt(val);
  if (isNaN(number)) {
    ctx.addIssue({
      code: "invalid_type",
      expected: "number",
      received: "string",
    });
    return z.NEVER;
  }
  return number;
};

export const stringToBoolean = (val: string) => {
  return val === "true";
};

export const semverSuperRefine = (input: string, ctx: z.RefinementCtx) => {
  const semver = semverValid(input);
  if (semver === null) {
    ctx.addIssue({
      code: "custom",
      message: "Invalid Semver",
    });

    return z.NEVER;
  }
  return semver;
};

export const updateNotificationData = z.object({
  version: z.string(),
  url: z.string(),
  forceInstall: z.boolean(),
});
