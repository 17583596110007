import { ReactiveMap } from "@solid-primitives/map";
import { batch } from "solid-js";
import { supabase } from "./supabase";
import { Tables } from "supabase";

export const zipcodesCache = new ReactiveMap<number, Tables<"zipcodes">>();

export async function fetchZipcodes() {
  const { data, error } = await supabase.from("zipcodes").select("*");
  if (error) throw error;

  batch(() => {
    for (const zipcode of data) {
      zipcodesCache.set(zipcode.id, zipcode);
    }
  });

  return data;
}
