import { debounce } from "@solid-primitives/scheduled";
import { LngLatBounds, LngLatBoundsLike, LngLatLike } from "maplibre-gl";
import * as API from "mooovex-api-schema";
import { Component, Signal, createEffect, on, onCleanup, onMount } from "solid-js";
import { useMapContext } from "./map.common";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  routeSignal?: Signal<API.route.ResponseBody | undefined>;
  centeredDriverIdSignal?: Signal<string | undefined>;
  sortedWaypoints?: () => API.google_place_details.ResponseBody[];
};

export const MapAnimations: Component<Props> = (props) => {
  const map = useMapContext();

  onMount(() => {
    map.on("dragstart", onDragstart);
  });
  onCleanup(() => {
    map.off("dragstart", onDragstart);
  });

  createEffect(() => {
    map.setPadding({
      top: props.top ?? 0,
      right: props.right ?? 0,
      bottom: props.bottom ?? 0,
      left: props.left ?? 0,
    });
  });

  function onDragstart() {
    uncenterDriver();
  }

  function uncenterDriver() {
    props.centeredDriverIdSignal?.[1](undefined);
  }

  const debouncedAnimations = debounce(() => {
    const boundsOptions: maplibregl.FitBoundsOptions = {
      padding: 40,
      maxZoom: 15,
    };

    if (props.sortedWaypoints && props.sortedWaypoints().length === 1) {
      uncenterDriver();
      const place = props.sortedWaypoints()[0];
      const point = API.PlaceAdapter.getCoordinates(place) as LngLatLike;
      map.flyTo({
        center: point,
        zoom: 15,
        ...boundsOptions,
      });
    } else if (props.sortedWaypoints && props.sortedWaypoints().length > 1) {
      uncenterDriver();

      const bounds = new LngLatBounds();

      for (const place of props.sortedWaypoints()) {
        const point = API.PlaceAdapter.getCoordinates(place) as LngLatLike;
        bounds.extend(point);
      }

      let route: API.route.ResponseBody | undefined;

      if (props.routeSignal && (route = props.routeSignal[0]())) {
        bounds.extend(route.bbox as LngLatBoundsLike);
      }

      map.fitBounds(bounds, boundsOptions);
    }
  }, 300);

  createEffect(
    on([props.sortedWaypoints ?? (() => {}), props.routeSignal?.[0] ?? (() => {})], debouncedAnimations, {
      defer: true,
    })
  );

  return null;
};
