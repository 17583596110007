import { Component } from "solid-js";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  disabled?: boolean;
};

export const PlaceSwapButton: Component<Props> = (props) => {
  const {
    emitter,
    routeSignal: [route, setRoute],
  } = useRideFormContext();

  function swap() {
    // const o = waypointMap.get(0);
    // const d = waypointMap.get(1);
    // setRoute(undefined);
    // emitter.emit("placeSearch.value", 0, undefined);
    // emitter.emit("placeSearch.value", 1, o);
    // emitter.emit("placeSearch.value", 0, d);
  }

  return <button disabled={props.disabled} class="btn btn-border-dark bi-arrow-down-up" onclick={swap} />;
};
