import { PercentIcon } from "lucide-solid";
import { Component, Match, Switch } from "solid-js";
import { F } from "../formatters";
import { ct } from "../i18n";
import { useRideFormContext } from "./rideForm.common";

type Props = {};

export const RideInfo: Component<Props> = (props) => {
  const {
    routeSignal: [route],
    priceSignal: [price],
    passengerCountSignal: [passengerCount],
    emitter,
    routeLoadingSignal: [routeLoading],
    priceLoadingSignal: [priceLoading],
    discountSignal: [discount],
  } = useRideFormContext();

  return (
    <div
      class="d-flex justify-content-between align-items-center shadow text-nowrap"
      style={{
        position: "absolute",
        bottom: "6px",
        right: "53px",
        left: "6px",
        "background-color": "white",
        height: "82px",
        "border-radius": "0.375rem",
        border: "1px solid black",
      }}
    >
      <Switch>
        <Match when={route()}>
          {(route) => (
            <>
              <div class="d-flex flex-column gap-2 overflow-hidden my-3 ms-3">
                <div class="fw-bold fs-6 text-truncate">{F.humanDuration(route().duration * 1000)}</div>
                <div class="fw-bold fs-6 text-truncate">{F.kilometers(route().distance / 1000)}</div>
              </div>
              <button
                class="btn btn-light position-relative h-100 p-0"
                onclick={() => emitter.emit("priceOptions.toggle")}
              >
                <Switch>
                  <Match when={price() !== undefined}>
                    <div class="fw-bold text-primary fs-1 m-3">{F.euros(price()!)}</div>
                  </Match>
                  <Match when={priceLoading()}>
                    <div class="spinner-border mx-5" />
                  </Match>
                </Switch>
                <PercentIcon
                  color={discount() !== 0 ? "var(--bs-red)" : undefined}
                  class="position-absolute cursor-pointer"
                  style={{ top: "1px", right: "1px" }}
                  size={24}
                />
              </button>
            </>
          )}
        </Match>
        <Match when={routeLoading()}>
          <div class="d-flex align-items-center gap-2 overflow-hidden my-3 ms-3">
            <span class="spinner-border spinner-border-sm" />
            <span class="fw-bold fs-6 text-truncate">{ct.ride.calculatingRoute()}</span>
          </div>
        </Match>
      </Switch>
    </div>
  );
};
