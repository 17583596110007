import { Component, For, Show } from "solid-js";
import { Tables } from "supabase";
import { staffCache, vehiclesCache, zipcodesCache } from "supabase-client";
import { ct } from "./i18n";
import { IdentifierBadge } from "./vehicles";

type Props = {
  onDriverClick?: (driver_id: string) => void;
  liveLocations: Tables<"live_locations">[];
};

export const Driverlist: Component<Props> = (props) => {
  const zipcodes = () =>
    Array.from(
      new Set(
        Array.from(vehiclesCache.values())
          .filter((v) => props.liveLocations.some((l) => l.current_vehicle_id === v.id))
          .map((v) => zipcodesCache.get(v.zipcode_id))
          .sort((a, b) => parseInt(a?.zipcode ?? "") - parseInt(b?.zipcode ?? ""))
      )
    );

  return (
    <table class="table table-sm">
      <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
        {(zipcode) => {
          const zipcodeDrivers = () =>
            props.liveLocations
              .filter((liveLocation) => vehiclesCache.get(liveLocation.current_vehicle_id!)?.zipcode_id === zipcode?.id)
              .map((d) => ({
                ...d,
                vehicle: vehiclesCache.get(d.current_vehicle_id!),
                staff: staffCache.get(d.driver_id),
              }))
              .sort((a, b) => parseInt(a.vehicle?.identifier ?? "") - parseInt(b.vehicle?.identifier ?? ""));
          return (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th class="fw-bold text-primary">{zipcode?.municipiality ?? ct["driverlist.noZipcode"]()}</th>
                  <th class="text-center">
                    <i class="bi-people-fill" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <For each={zipcodeDrivers()}>
                  {(driver) => {
                    return (
                      <>
                        <tr class="cursor-pointer align-middle">
                          <td onclick={() => props.onDriverClick?.(driver.driver_id)}>
                            <IdentifierBadge value={driver.vehicle?.identifier} status={driver.status ?? undefined} />
                          </td>
                          <td class="fw-bold text-nowrap w-100" onclick={() => props.onDriverClick?.(driver.driver_id)}>
                            {driver.staff?.first_name} {driver.staff?.last_name}
                          </td>
                          <td class="text-end" onclick={() => props.onDriverClick?.(driver.driver_id)}>
                            {driver.vehicle?.seats}
                          </td>
                          <td>
                            <Show when={driver.staff?.phone}>
                              {(phone) => (
                                <button
                                  class="btn btn-light btn-sm"
                                  title={`${phone()} (${ct["common.rightClickToCopy"]()})`}
                                  onclick={() => window.open(`tel:${phone()}`)}
                                  oncontextmenu={async (e) => {
                                    e.preventDefault();
                                    await navigator.clipboard.writeText(phone());
                                  }}
                                >
                                  <i class="bi-telephone-fill text-primary" />
                                </button>
                              )}
                            </Show>
                          </td>
                        </tr>
                      </>
                    );
                  }}
                </For>
              </tbody>
            </>
          );
        }}
      </For>
    </table>
  );
};
