import { XIcon } from "lucide-solid";
import { Component } from "solid-js";
import { useRideFormContext } from "./rideForm.common";

type Props = {};

export const ClearRideFormButton: Component<Props> = (props) => {
  const { emitter } = useRideFormContext();

  return (
    <button class="btn btn-light p-2" onclick={() => emitter.emit("clear")}>
      <XIcon size={32} />
    </button>
  );
};
