const requireWkx = (function () {
  function r(e, n, t) {
    function o(i, f) {
      if (!n[i]) {
        if (!e[i]) {
          var c = "function" == typeof require && require;
          if (!f && c) return c(i, !0);
          if (u) return u(i, !0);
          var a = new Error("Cannot find module '" + i + "'");
          throw ((a.code = "MODULE_NOT_FOUND"), a);
        }
        var p = (n[i] = { exports: {} });
        e[i][0].call(
          p.exports,
          function (r) {
            var n = e[i][1][r];
            return o(n || r);
          },
          p,
          p.exports,
          r,
          e,
          n,
          t
        );
      }
      return n[i].exports;
    }
    for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);
    return o;
  }
  return r;
})()(
  {
    1: [
      function (require, module, exports) {
        (function (Buffer) {
          module.exports = BinaryReader;

          function BinaryReader(buffer, isBigEndian) {
            this.buffer = buffer;
            this.position = 0;
            this.isBigEndian = isBigEndian || false;
          }

          function _read(readLE, readBE, size) {
            return function () {
              var value;

              if (this.isBigEndian) value = readBE.call(this.buffer, this.position);
              else value = readLE.call(this.buffer, this.position);

              this.position += size;

              return value;
            };
          }

          BinaryReader.prototype.readUInt8 = _read(Buffer.prototype.readUInt8, Buffer.prototype.readUInt8, 1);
          BinaryReader.prototype.readUInt16 = _read(Buffer.prototype.readUInt16LE, Buffer.prototype.readUInt16BE, 2);
          BinaryReader.prototype.readUInt32 = _read(Buffer.prototype.readUInt32LE, Buffer.prototype.readUInt32BE, 4);
          BinaryReader.prototype.readInt8 = _read(Buffer.prototype.readInt8, Buffer.prototype.readInt8, 1);
          BinaryReader.prototype.readInt16 = _read(Buffer.prototype.readInt16LE, Buffer.prototype.readInt16BE, 2);
          BinaryReader.prototype.readInt32 = _read(Buffer.prototype.readInt32LE, Buffer.prototype.readInt32BE, 4);
          BinaryReader.prototype.readFloat = _read(Buffer.prototype.readFloatLE, Buffer.prototype.readFloatBE, 4);
          BinaryReader.prototype.readDouble = _read(Buffer.prototype.readDoubleLE, Buffer.prototype.readDoubleBE, 8);

          BinaryReader.prototype.readVarInt = function () {
            var nextByte,
              result = 0,
              bytesRead = 0;

            do {
              nextByte = this.buffer[this.position + bytesRead];
              result += (nextByte & 0x7f) << (7 * bytesRead);
              bytesRead++;
            } while (nextByte >= 0x80);

            this.position += bytesRead;

            return result;
          };
        }).call(this, require("buffer").Buffer);
      },
      { buffer: "buffer" },
    ],
    2: [
      function (require, module, exports) {
        (function (Buffer) {
          module.exports = BinaryWriter;

          function BinaryWriter(size, allowResize) {
            this.buffer = new Buffer(size);
            this.position = 0;
            this.allowResize = allowResize;
          }

          function _write(write, size) {
            return function (value, noAssert) {
              this.ensureSize(size);

              write.call(this.buffer, value, this.position, noAssert);
              this.position += size;
            };
          }

          BinaryWriter.prototype.writeUInt8 = _write(Buffer.prototype.writeUInt8, 1);
          BinaryWriter.prototype.writeUInt16LE = _write(Buffer.prototype.writeUInt16LE, 2);
          BinaryWriter.prototype.writeUInt16BE = _write(Buffer.prototype.writeUInt16BE, 2);
          BinaryWriter.prototype.writeUInt32LE = _write(Buffer.prototype.writeUInt32LE, 4);
          BinaryWriter.prototype.writeUInt32BE = _write(Buffer.prototype.writeUInt32BE, 4);
          BinaryWriter.prototype.writeInt8 = _write(Buffer.prototype.writeInt8, 1);
          BinaryWriter.prototype.writeInt16LE = _write(Buffer.prototype.writeInt16LE, 2);
          BinaryWriter.prototype.writeInt16BE = _write(Buffer.prototype.writeInt16BE, 2);
          BinaryWriter.prototype.writeInt32LE = _write(Buffer.prototype.writeInt32LE, 4);
          BinaryWriter.prototype.writeInt32BE = _write(Buffer.prototype.writeInt32BE, 4);
          BinaryWriter.prototype.writeFloatLE = _write(Buffer.prototype.writeFloatLE, 4);
          BinaryWriter.prototype.writeFloatBE = _write(Buffer.prototype.writeFloatBE, 4);
          BinaryWriter.prototype.writeDoubleLE = _write(Buffer.prototype.writeDoubleLE, 8);
          BinaryWriter.prototype.writeDoubleBE = _write(Buffer.prototype.writeDoubleBE, 8);

          BinaryWriter.prototype.writeBuffer = function (buffer) {
            this.ensureSize(buffer.length);

            buffer.copy(this.buffer, this.position, 0, buffer.length);
            this.position += buffer.length;
          };

          BinaryWriter.prototype.writeVarInt = function (value) {
            var length = 1;

            while ((value & 0xffffff80) !== 0) {
              this.writeUInt8((value & 0x7f) | 0x80);
              value >>>= 7;
              length++;
            }

            this.writeUInt8(value & 0x7f);

            return length;
          };

          BinaryWriter.prototype.ensureSize = function (size) {
            if (this.buffer.length < this.position + size) {
              if (this.allowResize) {
                var tempBuffer = new Buffer(this.position + size);
                this.buffer.copy(tempBuffer, 0, 0, this.buffer.length);
                this.buffer = tempBuffer;
              } else {
                throw new RangeError("index out of range");
              }
            }
          };
        }).call(this, require("buffer").Buffer);
      },
      { buffer: "buffer" },
    ],
    3: [
      function (require, module, exports) {
        (function (Buffer) {
          module.exports = Geometry;

          var Types = require("./types");
          var Point = require("./point");
          var LineString = require("./linestring");
          var Polygon = require("./polygon");
          var MultiPoint = require("./multipoint");
          var MultiLineString = require("./multilinestring");
          var MultiPolygon = require("./multipolygon");
          var GeometryCollection = require("./geometrycollection");
          var BinaryReader = require("./binaryreader");
          var BinaryWriter = require("./binarywriter");
          var WktParser = require("./wktparser");
          var ZigZag = require("./zigzag.js");

          function Geometry() {
            this.srid = undefined;
            this.hasZ = false;
            this.hasM = false;
          }

          Geometry.parse = function (value, options) {
            var valueType = typeof value;

            if (valueType === "string" || value instanceof WktParser) return Geometry._parseWkt(value);
            else if (Buffer.isBuffer(value) || value instanceof BinaryReader) return Geometry._parseWkb(value, options);
            else throw new Error("first argument must be a string or Buffer");
          };

          Geometry._parseWkt = function (value) {
            var wktParser, srid;

            if (value instanceof WktParser) wktParser = value;
            else wktParser = new WktParser(value);

            var match = wktParser.matchRegex([/^SRID=(\d+);/]);
            if (match) srid = parseInt(match[1], 10);

            var geometryType = wktParser.matchType();
            var dimension = wktParser.matchDimension();

            var options = {
              srid: srid,
              hasZ: dimension.hasZ,
              hasM: dimension.hasM,
            };

            switch (geometryType) {
              case Types.wkt.Point:
                return Point._parseWkt(wktParser, options);
              case Types.wkt.LineString:
                return LineString._parseWkt(wktParser, options);
              case Types.wkt.Polygon:
                return Polygon._parseWkt(wktParser, options);
              case Types.wkt.MultiPoint:
                return MultiPoint._parseWkt(wktParser, options);
              case Types.wkt.MultiLineString:
                return MultiLineString._parseWkt(wktParser, options);
              case Types.wkt.MultiPolygon:
                return MultiPolygon._parseWkt(wktParser, options);
              case Types.wkt.GeometryCollection:
                return GeometryCollection._parseWkt(wktParser, options);
            }
          };

          Geometry._parseWkb = function (value, parentOptions) {
            var binaryReader,
              wkbType,
              geometryType,
              options = {};

            if (value instanceof BinaryReader) binaryReader = value;
            else binaryReader = new BinaryReader(value);

            binaryReader.isBigEndian = !binaryReader.readInt8();

            wkbType = binaryReader.readUInt32();

            options.hasSrid = (wkbType & 0x20000000) === 0x20000000;
            options.isEwkb = wkbType & 0x20000000 || wkbType & 0x40000000 || wkbType & 0x80000000;

            if (options.hasSrid) options.srid = binaryReader.readUInt32();

            options.hasZ = false;
            options.hasM = false;

            if (!options.isEwkb && (!parentOptions || !parentOptions.isEwkb)) {
              if (wkbType >= 1000 && wkbType < 2000) {
                options.hasZ = true;
                geometryType = wkbType - 1000;
              } else if (wkbType >= 2000 && wkbType < 3000) {
                options.hasM = true;
                geometryType = wkbType - 2000;
              } else if (wkbType >= 3000 && wkbType < 4000) {
                options.hasZ = true;
                options.hasM = true;
                geometryType = wkbType - 3000;
              } else {
                geometryType = wkbType;
              }
            } else {
              if (wkbType & 0x80000000) options.hasZ = true;
              if (wkbType & 0x40000000) options.hasM = true;

              geometryType = wkbType & 0xf;
            }

            switch (geometryType) {
              case Types.wkb.Point:
                return Point._parseWkb(binaryReader, options);
              case Types.wkb.LineString:
                return LineString._parseWkb(binaryReader, options);
              case Types.wkb.Polygon:
                return Polygon._parseWkb(binaryReader, options);
              case Types.wkb.MultiPoint:
                return MultiPoint._parseWkb(binaryReader, options);
              case Types.wkb.MultiLineString:
                return MultiLineString._parseWkb(binaryReader, options);
              case Types.wkb.MultiPolygon:
                return MultiPolygon._parseWkb(binaryReader, options);
              case Types.wkb.GeometryCollection:
                return GeometryCollection._parseWkb(binaryReader, options);
              default:
                throw new Error("GeometryType " + geometryType + " not supported");
            }
          };

          Geometry.parseTwkb = function (value) {
            var binaryReader,
              options = {};

            if (value instanceof BinaryReader) binaryReader = value;
            else binaryReader = new BinaryReader(value);

            var type = binaryReader.readUInt8();
            var metadataHeader = binaryReader.readUInt8();

            var geometryType = type & 0x0f;
            options.precision = ZigZag.decode(type >> 4);
            options.precisionFactor = Math.pow(10, options.precision);

            options.hasBoundingBox = (metadataHeader >> 0) & 1;
            options.hasSizeAttribute = (metadataHeader >> 1) & 1;
            options.hasIdList = (metadataHeader >> 2) & 1;
            options.hasExtendedPrecision = (metadataHeader >> 3) & 1;
            options.isEmpty = (metadataHeader >> 4) & 1;

            if (options.hasExtendedPrecision) {
              var extendedPrecision = binaryReader.readUInt8();
              options.hasZ = (extendedPrecision & 0x01) === 0x01;
              options.hasM = (extendedPrecision & 0x02) === 0x02;

              options.zPrecision = ZigZag.decode((extendedPrecision & 0x1c) >> 2);
              options.zPrecisionFactor = Math.pow(10, options.zPrecision);

              options.mPrecision = ZigZag.decode((extendedPrecision & 0xe0) >> 5);
              options.mPrecisionFactor = Math.pow(10, options.mPrecision);
            } else {
              options.hasZ = false;
              options.hasM = false;
            }

            if (options.hasSizeAttribute) binaryReader.readVarInt();
            if (options.hasBoundingBox) {
              var dimensions = 2;

              if (options.hasZ) dimensions++;
              if (options.hasM) dimensions++;

              for (var i = 0; i < dimensions; i++) {
                binaryReader.readVarInt();
                binaryReader.readVarInt();
              }
            }

            switch (geometryType) {
              case Types.wkb.Point:
                return Point._parseTwkb(binaryReader, options);
              case Types.wkb.LineString:
                return LineString._parseTwkb(binaryReader, options);
              case Types.wkb.Polygon:
                return Polygon._parseTwkb(binaryReader, options);
              case Types.wkb.MultiPoint:
                return MultiPoint._parseTwkb(binaryReader, options);
              case Types.wkb.MultiLineString:
                return MultiLineString._parseTwkb(binaryReader, options);
              case Types.wkb.MultiPolygon:
                return MultiPolygon._parseTwkb(binaryReader, options);
              case Types.wkb.GeometryCollection:
                return GeometryCollection._parseTwkb(binaryReader, options);
              default:
                throw new Error("GeometryType " + geometryType + " not supported");
            }
          };

          Geometry.parseGeoJSON = function (value) {
            return Geometry._parseGeoJSON(value);
          };

          Geometry._parseGeoJSON = function (value, isSubGeometry) {
            var geometry;

            switch (value.type) {
              case Types.geoJSON.Point:
                geometry = Point._parseGeoJSON(value);
                break;
              case Types.geoJSON.LineString:
                geometry = LineString._parseGeoJSON(value);
                break;
              case Types.geoJSON.Polygon:
                geometry = Polygon._parseGeoJSON(value);
                break;
              case Types.geoJSON.MultiPoint:
                geometry = MultiPoint._parseGeoJSON(value);
                break;
              case Types.geoJSON.MultiLineString:
                geometry = MultiLineString._parseGeoJSON(value);
                break;
              case Types.geoJSON.MultiPolygon:
                geometry = MultiPolygon._parseGeoJSON(value);
                break;
              case Types.geoJSON.GeometryCollection:
                geometry = GeometryCollection._parseGeoJSON(value);
                break;
              default:
                throw new Error("GeometryType " + value.type + " not supported");
            }

            if (
              value.crs &&
              value.crs.type &&
              value.crs.type === "name" &&
              value.crs.properties &&
              value.crs.properties.name
            ) {
              var crs = value.crs.properties.name;

              if (crs.indexOf("EPSG:") === 0) geometry.srid = parseInt(crs.substring(5));
              else if (crs.indexOf("urn:ogc:def:crs:EPSG::") === 0) geometry.srid = parseInt(crs.substring(22));
              else throw new Error("Unsupported crs: " + crs);
            } else if (!isSubGeometry) {
              geometry.srid = 4326;
            }

            return geometry;
          };

          Geometry.prototype.toEwkt = function () {
            return "SRID=" + this.srid + ";" + this.toWkt();
          };

          Geometry.prototype.toEwkb = function () {
            var ewkb = new BinaryWriter(this._getWkbSize() + 4);
            var wkb = this.toWkb();

            ewkb.writeInt8(1);
            ewkb.writeUInt32LE((wkb.slice(1, 5).readUInt32LE(0) | 0x20000000) >>> 0, true);
            ewkb.writeUInt32LE(this.srid);

            ewkb.writeBuffer(wkb.slice(5));

            return ewkb.buffer;
          };

          Geometry.prototype._getWktType = function (wktType, isEmpty) {
            var wkt = wktType;

            if (this.hasZ && this.hasM) wkt += " ZM ";
            else if (this.hasZ) wkt += " Z ";
            else if (this.hasM) wkt += " M ";

            if (isEmpty && !this.hasZ && !this.hasM) wkt += " ";

            if (isEmpty) wkt += "EMPTY";

            return wkt;
          };

          Geometry.prototype._getWktCoordinate = function (point) {
            var coordinates = point.x + " " + point.y;

            if (this.hasZ) coordinates += " " + point.z;
            if (this.hasM) coordinates += " " + point.m;

            return coordinates;
          };

          Geometry.prototype._writeWkbType = function (wkb, geometryType, parentOptions) {
            var dimensionType = 0;

            if (typeof this.srid === "undefined" && (!parentOptions || typeof parentOptions.srid === "undefined")) {
              if (this.hasZ && this.hasM) dimensionType += 3000;
              else if (this.hasZ) dimensionType += 1000;
              else if (this.hasM) dimensionType += 2000;
            } else {
              if (this.hasZ) dimensionType |= 0x80000000;
              if (this.hasM) dimensionType |= 0x40000000;
            }

            wkb.writeUInt32LE((dimensionType + geometryType) >>> 0, true);
          };

          Geometry.getTwkbPrecision = function (xyPrecision, zPrecision, mPrecision) {
            return {
              xy: xyPrecision,
              z: zPrecision,
              m: mPrecision,
              xyFactor: Math.pow(10, xyPrecision),
              zFactor: Math.pow(10, zPrecision),
              mFactor: Math.pow(10, mPrecision),
            };
          };

          Geometry.prototype._writeTwkbHeader = function (twkb, geometryType, precision, isEmpty) {
            var type = (ZigZag.encode(precision.xy) << 4) + geometryType;
            var metadataHeader = (this.hasZ || this.hasM) << 3;
            metadataHeader += isEmpty << 4;

            twkb.writeUInt8(type);
            twkb.writeUInt8(metadataHeader);

            if (this.hasZ || this.hasM) {
              var extendedPrecision = 0;
              if (this.hasZ) extendedPrecision |= 0x1;
              if (this.hasM) extendedPrecision |= 0x2;

              twkb.writeUInt8(extendedPrecision);
            }
          };

          Geometry.prototype.toGeoJSON = function (options) {
            var geoJSON = {};

            if (this.srid) {
              if (options) {
                if (options.shortCrs) {
                  geoJSON.crs = {
                    type: "name",
                    properties: {
                      name: "EPSG:" + this.srid,
                    },
                  };
                } else if (options.longCrs) {
                  geoJSON.crs = {
                    type: "name",
                    properties: {
                      name: "urn:ogc:def:crs:EPSG::" + this.srid,
                    },
                  };
                }
              }
            }

            return geoJSON;
          };
        }).call(this, {
          isBuffer: require("../node_modules/is-buffer/index.js"),
        });
      },
      {
        "../node_modules/is-buffer/index.js": 17,
        "./binaryreader": 1,
        "./binarywriter": 2,
        "./geometrycollection": 4,
        "./linestring": 5,
        "./multilinestring": 6,
        "./multipoint": 7,
        "./multipolygon": 8,
        "./point": 9,
        "./polygon": 10,
        "./types": 11,
        "./wktparser": 12,
        "./zigzag.js": 13,
      },
    ],
    4: [
      function (require, module, exports) {
        module.exports = GeometryCollection;

        var util = require("util");

        var Types = require("./types");
        var Geometry = require("./geometry");
        var BinaryWriter = require("./binarywriter");

        function GeometryCollection(geometries, srid) {
          Geometry.call(this);

          this.geometries = geometries || [];
          this.srid = srid;

          if (this.geometries.length > 0) {
            this.hasZ = this.geometries[0].hasZ;
            this.hasM = this.geometries[0].hasM;
          }
        }

        util.inherits(GeometryCollection, Geometry);

        GeometryCollection.Z = function (geometries, srid) {
          var geometryCollection = new GeometryCollection(geometries, srid);
          geometryCollection.hasZ = true;
          return geometryCollection;
        };

        GeometryCollection.M = function (geometries, srid) {
          var geometryCollection = new GeometryCollection(geometries, srid);
          geometryCollection.hasM = true;
          return geometryCollection;
        };

        GeometryCollection.ZM = function (geometries, srid) {
          var geometryCollection = new GeometryCollection(geometries, srid);
          geometryCollection.hasZ = true;
          geometryCollection.hasM = true;
          return geometryCollection;
        };

        GeometryCollection._parseWkt = function (value, options) {
          var geometryCollection = new GeometryCollection();
          geometryCollection.srid = options.srid;
          geometryCollection.hasZ = options.hasZ;
          geometryCollection.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return geometryCollection;

          value.expectGroupStart();

          do {
            geometryCollection.geometries.push(Geometry.parse(value));
          } while (value.isMatch([","]));

          value.expectGroupEnd();

          return geometryCollection;
        };

        GeometryCollection._parseWkb = function (value, options) {
          var geometryCollection = new GeometryCollection();
          geometryCollection.srid = options.srid;
          geometryCollection.hasZ = options.hasZ;
          geometryCollection.hasM = options.hasM;

          var geometryCount = value.readUInt32();

          for (var i = 0; i < geometryCount; i++) geometryCollection.geometries.push(Geometry.parse(value, options));

          return geometryCollection;
        };

        GeometryCollection._parseTwkb = function (value, options) {
          var geometryCollection = new GeometryCollection();
          geometryCollection.hasZ = options.hasZ;
          geometryCollection.hasM = options.hasM;

          if (options.isEmpty) return geometryCollection;

          var geometryCount = value.readVarInt();

          for (var i = 0; i < geometryCount; i++) geometryCollection.geometries.push(Geometry.parseTwkb(value));

          return geometryCollection;
        };

        GeometryCollection._parseGeoJSON = function (value) {
          var geometryCollection = new GeometryCollection();

          for (var i = 0; i < value.geometries.length; i++)
            geometryCollection.geometries.push(Geometry._parseGeoJSON(value.geometries[i], true));

          if (geometryCollection.geometries.length > 0) geometryCollection.hasZ = geometryCollection.geometries[0].hasZ;

          return geometryCollection;
        };

        GeometryCollection.prototype.toWkt = function () {
          if (this.geometries.length === 0) return this._getWktType(Types.wkt.GeometryCollection, true);

          var wkt = this._getWktType(Types.wkt.GeometryCollection, false) + "(";

          for (var i = 0; i < this.geometries.length; i++) wkt += this.geometries[i].toWkt() + ",";

          wkt = wkt.slice(0, -1);
          wkt += ")";

          return wkt;
        };

        GeometryCollection.prototype.toWkb = function () {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.GeometryCollection);
          wkb.writeUInt32LE(this.geometries.length);

          for (var i = 0; i < this.geometries.length; i++)
            wkb.writeBuffer(this.geometries[i].toWkb({ srid: this.srid }));

          return wkb.buffer;
        };

        GeometryCollection.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.geometries.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.GeometryCollection, precision, isEmpty);

          if (this.geometries.length > 0) {
            twkb.writeVarInt(this.geometries.length);

            for (var i = 0; i < this.geometries.length; i++) twkb.writeBuffer(this.geometries[i].toTwkb());
          }

          return twkb.buffer;
        };

        GeometryCollection.prototype._getWkbSize = function () {
          var size = 1 + 4 + 4;

          for (var i = 0; i < this.geometries.length; i++) size += this.geometries[i]._getWkbSize();

          return size;
        };

        GeometryCollection.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.GeometryCollection;
          geoJSON.geometries = [];

          for (var i = 0; i < this.geometries.length; i++) geoJSON.geometries.push(this.geometries[i].toGeoJSON());

          return geoJSON;
        };
      },
      { "./binarywriter": 2, "./geometry": 3, "./types": 11, util: 20 },
    ],
    5: [
      function (require, module, exports) {
        module.exports = LineString;

        var util = require("util");

        var Geometry = require("./geometry");
        var Types = require("./types");
        var Point = require("./point");
        var BinaryWriter = require("./binarywriter");

        function LineString(points, srid) {
          Geometry.call(this);

          this.points = points || [];
          this.srid = srid;

          if (this.points.length > 0) {
            this.hasZ = this.points[0].hasZ;
            this.hasM = this.points[0].hasM;
          }
        }

        util.inherits(LineString, Geometry);

        LineString.Z = function (points, srid) {
          var lineString = new LineString(points, srid);
          lineString.hasZ = true;
          return lineString;
        };

        LineString.M = function (points, srid) {
          var lineString = new LineString(points, srid);
          lineString.hasM = true;
          return lineString;
        };

        LineString.ZM = function (points, srid) {
          var lineString = new LineString(points, srid);
          lineString.hasZ = true;
          lineString.hasM = true;
          return lineString;
        };

        LineString._parseWkt = function (value, options) {
          var lineString = new LineString();
          lineString.srid = options.srid;
          lineString.hasZ = options.hasZ;
          lineString.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return lineString;

          value.expectGroupStart();
          lineString.points.push.apply(lineString.points, value.matchCoordinates(options));
          value.expectGroupEnd();

          return lineString;
        };

        LineString._parseWkb = function (value, options) {
          var lineString = new LineString();
          lineString.srid = options.srid;
          lineString.hasZ = options.hasZ;
          lineString.hasM = options.hasM;

          var pointCount = value.readUInt32();

          for (var i = 0; i < pointCount; i++) lineString.points.push(Point._readWkbPoint(value, options));

          return lineString;
        };

        LineString._parseTwkb = function (value, options) {
          var lineString = new LineString();
          lineString.hasZ = options.hasZ;
          lineString.hasM = options.hasM;

          if (options.isEmpty) return lineString;

          var previousPoint = new Point(0, 0, options.hasZ ? 0 : undefined, options.hasM ? 0 : undefined);
          var pointCount = value.readVarInt();

          for (var i = 0; i < pointCount; i++)
            lineString.points.push(Point._readTwkbPoint(value, options, previousPoint));

          return lineString;
        };

        LineString._parseGeoJSON = function (value) {
          var lineString = new LineString();

          if (value.coordinates.length > 0) lineString.hasZ = value.coordinates[0].length > 2;

          for (var i = 0; i < value.coordinates.length; i++)
            lineString.points.push(Point._readGeoJSONPoint(value.coordinates[i]));

          return lineString;
        };

        LineString.prototype.toWkt = function () {
          if (this.points.length === 0) return this._getWktType(Types.wkt.LineString, true);

          return this._getWktType(Types.wkt.LineString, false) + this._toInnerWkt();
        };

        LineString.prototype._toInnerWkt = function () {
          var innerWkt = "(";

          for (var i = 0; i < this.points.length; i++) innerWkt += this._getWktCoordinate(this.points[i]) + ",";

          innerWkt = innerWkt.slice(0, -1);
          innerWkt += ")";

          return innerWkt;
        };

        LineString.prototype.toWkb = function (parentOptions) {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.LineString, parentOptions);
          wkb.writeUInt32LE(this.points.length);

          for (var i = 0; i < this.points.length; i++) this.points[i]._writeWkbPoint(wkb);

          return wkb.buffer;
        };

        LineString.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.points.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.LineString, precision, isEmpty);

          if (this.points.length > 0) {
            twkb.writeVarInt(this.points.length);

            var previousPoint = new Point(0, 0, 0, 0);
            for (var i = 0; i < this.points.length; i++) this.points[i]._writeTwkbPoint(twkb, precision, previousPoint);
          }

          return twkb.buffer;
        };

        LineString.prototype._getWkbSize = function () {
          var coordinateSize = 16;

          if (this.hasZ) coordinateSize += 8;
          if (this.hasM) coordinateSize += 8;

          return 1 + 4 + 4 + this.points.length * coordinateSize;
        };

        LineString.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.LineString;
          geoJSON.coordinates = [];

          for (var i = 0; i < this.points.length; i++) {
            if (this.hasZ) geoJSON.coordinates.push([this.points[i].x, this.points[i].y, this.points[i].z]);
            else geoJSON.coordinates.push([this.points[i].x, this.points[i].y]);
          }

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./point": 9,
        "./types": 11,
        util: 20,
      },
    ],
    6: [
      function (require, module, exports) {
        module.exports = MultiLineString;

        var util = require("util");

        var Types = require("./types");
        var Geometry = require("./geometry");
        var Point = require("./point");
        var LineString = require("./linestring");
        var BinaryWriter = require("./binarywriter");

        function MultiLineString(lineStrings, srid) {
          Geometry.call(this);

          this.lineStrings = lineStrings || [];
          this.srid = srid;

          if (this.lineStrings.length > 0) {
            this.hasZ = this.lineStrings[0].hasZ;
            this.hasM = this.lineStrings[0].hasM;
          }
        }

        util.inherits(MultiLineString, Geometry);

        MultiLineString.Z = function (lineStrings, srid) {
          var multiLineString = new MultiLineString(lineStrings, srid);
          multiLineString.hasZ = true;
          return multiLineString;
        };

        MultiLineString.M = function (lineStrings, srid) {
          var multiLineString = new MultiLineString(lineStrings, srid);
          multiLineString.hasM = true;
          return multiLineString;
        };

        MultiLineString.ZM = function (lineStrings, srid) {
          var multiLineString = new MultiLineString(lineStrings, srid);
          multiLineString.hasZ = true;
          multiLineString.hasM = true;
          return multiLineString;
        };

        MultiLineString._parseWkt = function (value, options) {
          var multiLineString = new MultiLineString();
          multiLineString.srid = options.srid;
          multiLineString.hasZ = options.hasZ;
          multiLineString.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return multiLineString;

          value.expectGroupStart();

          do {
            value.expectGroupStart();
            multiLineString.lineStrings.push(new LineString(value.matchCoordinates(options)));
            value.expectGroupEnd();
          } while (value.isMatch([","]));

          value.expectGroupEnd();

          return multiLineString;
        };

        MultiLineString._parseWkb = function (value, options) {
          var multiLineString = new MultiLineString();
          multiLineString.srid = options.srid;
          multiLineString.hasZ = options.hasZ;
          multiLineString.hasM = options.hasM;

          var lineStringCount = value.readUInt32();

          for (var i = 0; i < lineStringCount; i++) multiLineString.lineStrings.push(Geometry.parse(value, options));

          return multiLineString;
        };

        MultiLineString._parseTwkb = function (value, options) {
          var multiLineString = new MultiLineString();
          multiLineString.hasZ = options.hasZ;
          multiLineString.hasM = options.hasM;

          if (options.isEmpty) return multiLineString;

          var previousPoint = new Point(0, 0, options.hasZ ? 0 : undefined, options.hasM ? 0 : undefined);
          var lineStringCount = value.readVarInt();

          for (var i = 0; i < lineStringCount; i++) {
            var lineString = new LineString();
            lineString.hasZ = options.hasZ;
            lineString.hasM = options.hasM;

            var pointCount = value.readVarInt();

            for (var j = 0; j < pointCount; j++)
              lineString.points.push(Point._readTwkbPoint(value, options, previousPoint));

            multiLineString.lineStrings.push(lineString);
          }

          return multiLineString;
        };

        MultiLineString._parseGeoJSON = function (value) {
          var multiLineString = new MultiLineString();

          if (value.coordinates.length > 0 && value.coordinates[0].length > 0)
            multiLineString.hasZ = value.coordinates[0][0].length > 2;

          for (var i = 0; i < value.coordinates.length; i++)
            multiLineString.lineStrings.push(LineString._parseGeoJSON({ coordinates: value.coordinates[i] }));

          return multiLineString;
        };

        MultiLineString.prototype.toWkt = function () {
          if (this.lineStrings.length === 0) return this._getWktType(Types.wkt.MultiLineString, true);

          var wkt = this._getWktType(Types.wkt.MultiLineString, false) + "(";

          for (var i = 0; i < this.lineStrings.length; i++) wkt += this.lineStrings[i]._toInnerWkt() + ",";

          wkt = wkt.slice(0, -1);
          wkt += ")";

          return wkt;
        };

        MultiLineString.prototype.toWkb = function () {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.MultiLineString);
          wkb.writeUInt32LE(this.lineStrings.length);

          for (var i = 0; i < this.lineStrings.length; i++)
            wkb.writeBuffer(this.lineStrings[i].toWkb({ srid: this.srid }));

          return wkb.buffer;
        };

        MultiLineString.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.lineStrings.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.MultiLineString, precision, isEmpty);

          if (this.lineStrings.length > 0) {
            twkb.writeVarInt(this.lineStrings.length);

            var previousPoint = new Point(0, 0, 0, 0);
            for (var i = 0; i < this.lineStrings.length; i++) {
              twkb.writeVarInt(this.lineStrings[i].points.length);

              for (var j = 0; j < this.lineStrings[i].points.length; j++)
                this.lineStrings[i].points[j]._writeTwkbPoint(twkb, precision, previousPoint);
            }
          }

          return twkb.buffer;
        };

        MultiLineString.prototype._getWkbSize = function () {
          var size = 1 + 4 + 4;

          for (var i = 0; i < this.lineStrings.length; i++) size += this.lineStrings[i]._getWkbSize();

          return size;
        };

        MultiLineString.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.MultiLineString;
          geoJSON.coordinates = [];

          for (var i = 0; i < this.lineStrings.length; i++)
            geoJSON.coordinates.push(this.lineStrings[i].toGeoJSON().coordinates);

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./linestring": 5,
        "./point": 9,
        "./types": 11,
        util: 20,
      },
    ],
    7: [
      function (require, module, exports) {
        module.exports = MultiPoint;

        var util = require("util");

        var Types = require("./types");
        var Geometry = require("./geometry");
        var Point = require("./point");
        var BinaryWriter = require("./binarywriter");

        function MultiPoint(points, srid) {
          Geometry.call(this);

          this.points = points || [];
          this.srid = srid;

          if (this.points.length > 0) {
            this.hasZ = this.points[0].hasZ;
            this.hasM = this.points[0].hasM;
          }
        }

        util.inherits(MultiPoint, Geometry);

        MultiPoint.Z = function (points, srid) {
          var multiPoint = new MultiPoint(points, srid);
          multiPoint.hasZ = true;
          return multiPoint;
        };

        MultiPoint.M = function (points, srid) {
          var multiPoint = new MultiPoint(points, srid);
          multiPoint.hasM = true;
          return multiPoint;
        };

        MultiPoint.ZM = function (points, srid) {
          var multiPoint = new MultiPoint(points, srid);
          multiPoint.hasZ = true;
          multiPoint.hasM = true;
          return multiPoint;
        };

        MultiPoint._parseWkt = function (value, options) {
          var multiPoint = new MultiPoint();
          multiPoint.srid = options.srid;
          multiPoint.hasZ = options.hasZ;
          multiPoint.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return multiPoint;

          value.expectGroupStart();
          multiPoint.points.push.apply(multiPoint.points, value.matchCoordinates(options));
          value.expectGroupEnd();

          return multiPoint;
        };

        MultiPoint._parseWkb = function (value, options) {
          var multiPoint = new MultiPoint();
          multiPoint.srid = options.srid;
          multiPoint.hasZ = options.hasZ;
          multiPoint.hasM = options.hasM;

          var pointCount = value.readUInt32();

          for (var i = 0; i < pointCount; i++) multiPoint.points.push(Geometry.parse(value, options));

          return multiPoint;
        };

        MultiPoint._parseTwkb = function (value, options) {
          var multiPoint = new MultiPoint();
          multiPoint.hasZ = options.hasZ;
          multiPoint.hasM = options.hasM;

          if (options.isEmpty) return multiPoint;

          var previousPoint = new Point(0, 0, options.hasZ ? 0 : undefined, options.hasM ? 0 : undefined);
          var pointCount = value.readVarInt();

          for (var i = 0; i < pointCount; i++)
            multiPoint.points.push(Point._readTwkbPoint(value, options, previousPoint));

          return multiPoint;
        };

        MultiPoint._parseGeoJSON = function (value) {
          var multiPoint = new MultiPoint();

          if (value.coordinates.length > 0) multiPoint.hasZ = value.coordinates[0].length > 2;

          for (var i = 0; i < value.coordinates.length; i++)
            multiPoint.points.push(Point._parseGeoJSON({ coordinates: value.coordinates[i] }));

          return multiPoint;
        };

        MultiPoint.prototype.toWkt = function () {
          if (this.points.length === 0) return this._getWktType(Types.wkt.MultiPoint, true);

          var wkt = this._getWktType(Types.wkt.MultiPoint, false) + "(";

          for (var i = 0; i < this.points.length; i++) wkt += this._getWktCoordinate(this.points[i]) + ",";

          wkt = wkt.slice(0, -1);
          wkt += ")";

          return wkt;
        };

        MultiPoint.prototype.toWkb = function () {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.MultiPoint);
          wkb.writeUInt32LE(this.points.length);

          for (var i = 0; i < this.points.length; i++) wkb.writeBuffer(this.points[i].toWkb({ srid: this.srid }));

          return wkb.buffer;
        };

        MultiPoint.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.points.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.MultiPoint, precision, isEmpty);

          if (this.points.length > 0) {
            twkb.writeVarInt(this.points.length);

            var previousPoint = new Point(0, 0, 0, 0);
            for (var i = 0; i < this.points.length; i++) this.points[i]._writeTwkbPoint(twkb, precision, previousPoint);
          }

          return twkb.buffer;
        };

        MultiPoint.prototype._getWkbSize = function () {
          var coordinateSize = 16;

          if (this.hasZ) coordinateSize += 8;
          if (this.hasM) coordinateSize += 8;

          coordinateSize += 5;

          return 1 + 4 + 4 + this.points.length * coordinateSize;
        };

        MultiPoint.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.MultiPoint;
          geoJSON.coordinates = [];

          for (var i = 0; i < this.points.length; i++) geoJSON.coordinates.push(this.points[i].toGeoJSON().coordinates);

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./point": 9,
        "./types": 11,
        util: 20,
      },
    ],
    8: [
      function (require, module, exports) {
        module.exports = MultiPolygon;

        var util = require("util");

        var Types = require("./types");
        var Geometry = require("./geometry");
        var Point = require("./point");
        var Polygon = require("./polygon");
        var BinaryWriter = require("./binarywriter");

        function MultiPolygon(polygons, srid) {
          Geometry.call(this);

          this.polygons = polygons || [];
          this.srid = srid;

          if (this.polygons.length > 0) {
            this.hasZ = this.polygons[0].hasZ;
            this.hasM = this.polygons[0].hasM;
          }
        }

        util.inherits(MultiPolygon, Geometry);

        MultiPolygon.Z = function (polygons, srid) {
          var multiPolygon = new MultiPolygon(polygons, srid);
          multiPolygon.hasZ = true;
          return multiPolygon;
        };

        MultiPolygon.M = function (polygons, srid) {
          var multiPolygon = new MultiPolygon(polygons, srid);
          multiPolygon.hasM = true;
          return multiPolygon;
        };

        MultiPolygon.ZM = function (polygons, srid) {
          var multiPolygon = new MultiPolygon(polygons, srid);
          multiPolygon.hasZ = true;
          multiPolygon.hasM = true;
          return multiPolygon;
        };

        MultiPolygon._parseWkt = function (value, options) {
          var multiPolygon = new MultiPolygon();
          multiPolygon.srid = options.srid;
          multiPolygon.hasZ = options.hasZ;
          multiPolygon.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return multiPolygon;

          value.expectGroupStart();

          do {
            value.expectGroupStart();

            var exteriorRing = [];
            var interiorRings = [];

            value.expectGroupStart();
            exteriorRing.push.apply(exteriorRing, value.matchCoordinates(options));
            value.expectGroupEnd();

            while (value.isMatch([","])) {
              value.expectGroupStart();
              interiorRings.push(value.matchCoordinates(options));
              value.expectGroupEnd();
            }

            multiPolygon.polygons.push(new Polygon(exteriorRing, interiorRings));

            value.expectGroupEnd();
          } while (value.isMatch([","]));

          value.expectGroupEnd();

          return multiPolygon;
        };

        MultiPolygon._parseWkb = function (value, options) {
          var multiPolygon = new MultiPolygon();
          multiPolygon.srid = options.srid;
          multiPolygon.hasZ = options.hasZ;
          multiPolygon.hasM = options.hasM;

          var polygonCount = value.readUInt32();

          for (var i = 0; i < polygonCount; i++) multiPolygon.polygons.push(Geometry.parse(value, options));

          return multiPolygon;
        };

        MultiPolygon._parseTwkb = function (value, options) {
          var multiPolygon = new MultiPolygon();
          multiPolygon.hasZ = options.hasZ;
          multiPolygon.hasM = options.hasM;

          if (options.isEmpty) return multiPolygon;

          var previousPoint = new Point(0, 0, options.hasZ ? 0 : undefined, options.hasM ? 0 : undefined);
          var polygonCount = value.readVarInt();

          for (var i = 0; i < polygonCount; i++) {
            var polygon = new Polygon();
            polygon.hasZ = options.hasZ;
            polygon.hasM = options.hasM;

            var ringCount = value.readVarInt();
            var exteriorRingCount = value.readVarInt();

            for (var j = 0; j < exteriorRingCount; j++)
              polygon.exteriorRing.push(Point._readTwkbPoint(value, options, previousPoint));

            for (j = 1; j < ringCount; j++) {
              var interiorRing = [];

              var interiorRingCount = value.readVarInt();

              for (var k = 0; k < interiorRingCount; k++)
                interiorRing.push(Point._readTwkbPoint(value, options, previousPoint));

              polygon.interiorRings.push(interiorRing);
            }

            multiPolygon.polygons.push(polygon);
          }

          return multiPolygon;
        };

        MultiPolygon._parseGeoJSON = function (value) {
          var multiPolygon = new MultiPolygon();

          if (value.coordinates.length > 0 && value.coordinates[0].length > 0 && value.coordinates[0][0].length > 0)
            multiPolygon.hasZ = value.coordinates[0][0][0].length > 2;

          for (var i = 0; i < value.coordinates.length; i++)
            multiPolygon.polygons.push(Polygon._parseGeoJSON({ coordinates: value.coordinates[i] }));

          return multiPolygon;
        };

        MultiPolygon.prototype.toWkt = function () {
          if (this.polygons.length === 0) return this._getWktType(Types.wkt.MultiPolygon, true);

          var wkt = this._getWktType(Types.wkt.MultiPolygon, false) + "(";

          for (var i = 0; i < this.polygons.length; i++) wkt += this.polygons[i]._toInnerWkt() + ",";

          wkt = wkt.slice(0, -1);
          wkt += ")";

          return wkt;
        };

        MultiPolygon.prototype.toWkb = function () {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.MultiPolygon);
          wkb.writeUInt32LE(this.polygons.length);

          for (var i = 0; i < this.polygons.length; i++) wkb.writeBuffer(this.polygons[i].toWkb({ srid: this.srid }));

          return wkb.buffer;
        };

        MultiPolygon.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.polygons.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.MultiPolygon, precision, isEmpty);

          if (this.polygons.length > 0) {
            twkb.writeVarInt(this.polygons.length);

            var previousPoint = new Point(0, 0, 0, 0);
            for (var i = 0; i < this.polygons.length; i++) {
              twkb.writeVarInt(1 + this.polygons[i].interiorRings.length);

              twkb.writeVarInt(this.polygons[i].exteriorRing.length);

              for (var j = 0; j < this.polygons[i].exteriorRing.length; j++)
                this.polygons[i].exteriorRing[j]._writeTwkbPoint(twkb, precision, previousPoint);

              for (j = 0; j < this.polygons[i].interiorRings.length; j++) {
                twkb.writeVarInt(this.polygons[i].interiorRings[j].length);

                for (var k = 0; k < this.polygons[i].interiorRings[j].length; k++)
                  this.polygons[i].interiorRings[j][k]._writeTwkbPoint(twkb, precision, previousPoint);
              }
            }
          }

          return twkb.buffer;
        };

        MultiPolygon.prototype._getWkbSize = function () {
          var size = 1 + 4 + 4;

          for (var i = 0; i < this.polygons.length; i++) size += this.polygons[i]._getWkbSize();

          return size;
        };

        MultiPolygon.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.MultiPolygon;
          geoJSON.coordinates = [];

          for (var i = 0; i < this.polygons.length; i++)
            geoJSON.coordinates.push(this.polygons[i].toGeoJSON().coordinates);

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./point": 9,
        "./polygon": 10,
        "./types": 11,
        util: 20,
      },
    ],
    9: [
      function (require, module, exports) {
        module.exports = Point;

        var util = require("util");

        var Geometry = require("./geometry");
        var Types = require("./types");
        var BinaryWriter = require("./binarywriter");
        var ZigZag = require("./zigzag.js");

        function Point(x, y, z, m, srid) {
          Geometry.call(this);

          this.x = x;
          this.y = y;
          this.z = z;
          this.m = m;
          this.srid = srid;

          this.hasZ = typeof this.z !== "undefined";
          this.hasM = typeof this.m !== "undefined";
        }

        util.inherits(Point, Geometry);

        Point.Z = function (x, y, z, srid) {
          var point = new Point(x, y, z, undefined, srid);
          point.hasZ = true;
          return point;
        };

        Point.M = function (x, y, m, srid) {
          var point = new Point(x, y, undefined, m, srid);
          point.hasM = true;
          return point;
        };

        Point.ZM = function (x, y, z, m, srid) {
          var point = new Point(x, y, z, m, srid);
          point.hasZ = true;
          point.hasM = true;
          return point;
        };

        Point._parseWkt = function (value, options) {
          var point = new Point();
          point.srid = options.srid;
          point.hasZ = options.hasZ;
          point.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return point;

          value.expectGroupStart();

          var coordinate = value.matchCoordinate(options);

          point.x = coordinate.x;
          point.y = coordinate.y;
          point.z = coordinate.z;
          point.m = coordinate.m;

          value.expectGroupEnd();

          return point;
        };

        Point._parseWkb = function (value, options) {
          var point = Point._readWkbPoint(value, options);
          point.srid = options.srid;
          return point;
        };

        Point._readWkbPoint = function (value, options) {
          return new Point(
            value.readDouble(),
            value.readDouble(),
            options.hasZ ? value.readDouble() : undefined,
            options.hasM ? value.readDouble() : undefined
          );
        };

        Point._parseTwkb = function (value, options) {
          var point = new Point();
          point.hasZ = options.hasZ;
          point.hasM = options.hasM;

          if (options.isEmpty) return point;

          point.x = ZigZag.decode(value.readVarInt()) / options.precisionFactor;
          point.y = ZigZag.decode(value.readVarInt()) / options.precisionFactor;
          point.z = options.hasZ ? ZigZag.decode(value.readVarInt()) / options.zPrecisionFactor : undefined;
          point.m = options.hasM ? ZigZag.decode(value.readVarInt()) / options.mPrecisionFactor : undefined;

          return point;
        };

        Point._readTwkbPoint = function (value, options, previousPoint) {
          previousPoint.x += ZigZag.decode(value.readVarInt()) / options.precisionFactor;
          previousPoint.y += ZigZag.decode(value.readVarInt()) / options.precisionFactor;

          if (options.hasZ) previousPoint.z += ZigZag.decode(value.readVarInt()) / options.zPrecisionFactor;
          if (options.hasM) previousPoint.m += ZigZag.decode(value.readVarInt()) / options.mPrecisionFactor;

          return new Point(previousPoint.x, previousPoint.y, previousPoint.z, previousPoint.m);
        };

        Point._parseGeoJSON = function (value) {
          return Point._readGeoJSONPoint(value.coordinates);
        };

        Point._readGeoJSONPoint = function (coordinates) {
          if (coordinates.length === 0) return new Point();

          if (coordinates.length > 2) return new Point(coordinates[0], coordinates[1], coordinates[2]);

          return new Point(coordinates[0], coordinates[1]);
        };

        Point.prototype.toWkt = function () {
          if (
            typeof this.x === "undefined" &&
            typeof this.y === "undefined" &&
            typeof this.z === "undefined" &&
            typeof this.m === "undefined"
          )
            return this._getWktType(Types.wkt.Point, true);

          return this._getWktType(Types.wkt.Point, false) + "(" + this._getWktCoordinate(this) + ")";
        };

        Point.prototype.toWkb = function (parentOptions) {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);
          this._writeWkbType(wkb, Types.wkb.Point, parentOptions);

          if (typeof this.x === "undefined" && typeof this.y === "undefined") {
            wkb.writeDoubleLE(NaN);
            wkb.writeDoubleLE(NaN);

            if (this.hasZ) wkb.writeDoubleLE(NaN);
            if (this.hasM) wkb.writeDoubleLE(NaN);
          } else {
            this._writeWkbPoint(wkb);
          }

          return wkb.buffer;
        };

        Point.prototype._writeWkbPoint = function (wkb) {
          wkb.writeDoubleLE(this.x);
          wkb.writeDoubleLE(this.y);

          if (this.hasZ) wkb.writeDoubleLE(this.z);
          if (this.hasM) wkb.writeDoubleLE(this.m);
        };

        Point.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = typeof this.x === "undefined" && typeof this.y === "undefined";

          this._writeTwkbHeader(twkb, Types.wkb.Point, precision, isEmpty);

          if (!isEmpty) this._writeTwkbPoint(twkb, precision, new Point(0, 0, 0, 0));

          return twkb.buffer;
        };

        Point.prototype._writeTwkbPoint = function (twkb, precision, previousPoint) {
          var x = this.x * precision.xyFactor;
          var y = this.y * precision.xyFactor;
          var z = this.z * precision.zFactor;
          var m = this.m * precision.mFactor;

          twkb.writeVarInt(ZigZag.encode(x - previousPoint.x));
          twkb.writeVarInt(ZigZag.encode(y - previousPoint.y));
          if (this.hasZ) twkb.writeVarInt(ZigZag.encode(z - previousPoint.z));
          if (this.hasM) twkb.writeVarInt(ZigZag.encode(m - previousPoint.m));

          previousPoint.x = x;
          previousPoint.y = y;
          previousPoint.z = z;
          previousPoint.m = m;
        };

        Point.prototype._getWkbSize = function () {
          var size = 1 + 4 + 8 + 8;

          if (this.hasZ) size += 8;
          if (this.hasM) size += 8;

          return size;
        };

        Point.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.Point;

          if (typeof this.x === "undefined" && typeof this.y === "undefined") geoJSON.coordinates = [];
          else if (typeof this.z !== "undefined") geoJSON.coordinates = [this.x, this.y, this.z];
          else geoJSON.coordinates = [this.x, this.y];

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./types": 11,
        "./zigzag.js": 13,
        util: 20,
      },
    ],
    10: [
      function (require, module, exports) {
        module.exports = Polygon;

        var util = require("util");

        var Geometry = require("./geometry");
        var Types = require("./types");
        var Point = require("./point");
        var BinaryWriter = require("./binarywriter");

        function Polygon(exteriorRing, interiorRings, srid) {
          Geometry.call(this);

          this.exteriorRing = exteriorRing || [];
          this.interiorRings = interiorRings || [];
          this.srid = srid;

          if (this.exteriorRing.length > 0) {
            this.hasZ = this.exteriorRing[0].hasZ;
            this.hasM = this.exteriorRing[0].hasM;
          }
        }

        util.inherits(Polygon, Geometry);

        Polygon.Z = function (exteriorRing, interiorRings, srid) {
          var polygon = new Polygon(exteriorRing, interiorRings, srid);
          polygon.hasZ = true;
          return polygon;
        };

        Polygon.M = function (exteriorRing, interiorRings, srid) {
          var polygon = new Polygon(exteriorRing, interiorRings, srid);
          polygon.hasM = true;
          return polygon;
        };

        Polygon.ZM = function (exteriorRing, interiorRings, srid) {
          var polygon = new Polygon(exteriorRing, interiorRings, srid);
          polygon.hasZ = true;
          polygon.hasM = true;
          return polygon;
        };

        Polygon._parseWkt = function (value, options) {
          var polygon = new Polygon();
          polygon.srid = options.srid;
          polygon.hasZ = options.hasZ;
          polygon.hasM = options.hasM;

          if (value.isMatch(["EMPTY"])) return polygon;

          value.expectGroupStart();

          value.expectGroupStart();
          polygon.exteriorRing.push.apply(polygon.exteriorRing, value.matchCoordinates(options));
          value.expectGroupEnd();

          while (value.isMatch([","])) {
            value.expectGroupStart();
            polygon.interiorRings.push(value.matchCoordinates(options));
            value.expectGroupEnd();
          }

          value.expectGroupEnd();

          return polygon;
        };

        Polygon._parseWkb = function (value, options) {
          var polygon = new Polygon();
          polygon.srid = options.srid;
          polygon.hasZ = options.hasZ;
          polygon.hasM = options.hasM;

          var ringCount = value.readUInt32();

          if (ringCount > 0) {
            var exteriorRingCount = value.readUInt32();

            for (var i = 0; i < exteriorRingCount; i++) polygon.exteriorRing.push(Point._readWkbPoint(value, options));

            for (i = 1; i < ringCount; i++) {
              var interiorRing = [];

              var interiorRingCount = value.readUInt32();

              for (var j = 0; j < interiorRingCount; j++) interiorRing.push(Point._readWkbPoint(value, options));

              polygon.interiorRings.push(interiorRing);
            }
          }

          return polygon;
        };

        Polygon._parseTwkb = function (value, options) {
          var polygon = new Polygon();
          polygon.hasZ = options.hasZ;
          polygon.hasM = options.hasM;

          if (options.isEmpty) return polygon;

          var previousPoint = new Point(0, 0, options.hasZ ? 0 : undefined, options.hasM ? 0 : undefined);
          var ringCount = value.readVarInt();
          var exteriorRingCount = value.readVarInt();

          for (var i = 0; i < exteriorRingCount; i++)
            polygon.exteriorRing.push(Point._readTwkbPoint(value, options, previousPoint));

          for (i = 1; i < ringCount; i++) {
            var interiorRing = [];

            var interiorRingCount = value.readVarInt();

            for (var j = 0; j < interiorRingCount; j++)
              interiorRing.push(Point._readTwkbPoint(value, options, previousPoint));

            polygon.interiorRings.push(interiorRing);
          }

          return polygon;
        };

        Polygon._parseGeoJSON = function (value) {
          var polygon = new Polygon();

          if (value.coordinates.length > 0 && value.coordinates[0].length > 0)
            polygon.hasZ = value.coordinates[0][0].length > 2;

          for (var i = 0; i < value.coordinates.length; i++) {
            if (i > 0) polygon.interiorRings.push([]);

            for (var j = 0; j < value.coordinates[i].length; j++) {
              if (i === 0) polygon.exteriorRing.push(Point._readGeoJSONPoint(value.coordinates[i][j]));
              else polygon.interiorRings[i - 1].push(Point._readGeoJSONPoint(value.coordinates[i][j]));
            }
          }

          return polygon;
        };

        Polygon.prototype.toWkt = function () {
          if (this.exteriorRing.length === 0) return this._getWktType(Types.wkt.Polygon, true);

          return this._getWktType(Types.wkt.Polygon, false) + this._toInnerWkt();
        };

        Polygon.prototype._toInnerWkt = function () {
          var innerWkt = "((";

          for (var i = 0; i < this.exteriorRing.length; i++)
            innerWkt += this._getWktCoordinate(this.exteriorRing[i]) + ",";

          innerWkt = innerWkt.slice(0, -1);
          innerWkt += ")";

          for (i = 0; i < this.interiorRings.length; i++) {
            innerWkt += ",(";

            for (var j = 0; j < this.interiorRings[i].length; j++) {
              innerWkt += this._getWktCoordinate(this.interiorRings[i][j]) + ",";
            }

            innerWkt = innerWkt.slice(0, -1);
            innerWkt += ")";
          }

          innerWkt += ")";

          return innerWkt;
        };

        Polygon.prototype.toWkb = function (parentOptions) {
          var wkb = new BinaryWriter(this._getWkbSize());

          wkb.writeInt8(1);

          this._writeWkbType(wkb, Types.wkb.Polygon, parentOptions);

          if (this.exteriorRing.length > 0) {
            wkb.writeUInt32LE(1 + this.interiorRings.length);
            wkb.writeUInt32LE(this.exteriorRing.length);
          } else {
            wkb.writeUInt32LE(0);
          }

          for (var i = 0; i < this.exteriorRing.length; i++) this.exteriorRing[i]._writeWkbPoint(wkb);

          for (i = 0; i < this.interiorRings.length; i++) {
            wkb.writeUInt32LE(this.interiorRings[i].length);

            for (var j = 0; j < this.interiorRings[i].length; j++) this.interiorRings[i][j]._writeWkbPoint(wkb);
          }

          return wkb.buffer;
        };

        Polygon.prototype.toTwkb = function () {
          var twkb = new BinaryWriter(0, true);

          var precision = Geometry.getTwkbPrecision(5, 0, 0);
          var isEmpty = this.exteriorRing.length === 0;

          this._writeTwkbHeader(twkb, Types.wkb.Polygon, precision, isEmpty);

          if (this.exteriorRing.length > 0) {
            twkb.writeVarInt(1 + this.interiorRings.length);

            twkb.writeVarInt(this.exteriorRing.length);

            var previousPoint = new Point(0, 0, 0, 0);
            for (var i = 0; i < this.exteriorRing.length; i++)
              this.exteriorRing[i]._writeTwkbPoint(twkb, precision, previousPoint);

            for (i = 0; i < this.interiorRings.length; i++) {
              twkb.writeVarInt(this.interiorRings[i].length);

              for (var j = 0; j < this.interiorRings[i].length; j++)
                this.interiorRings[i][j]._writeTwkbPoint(twkb, precision, previousPoint);
            }
          }

          return twkb.buffer;
        };

        Polygon.prototype._getWkbSize = function () {
          var coordinateSize = 16;

          if (this.hasZ) coordinateSize += 8;
          if (this.hasM) coordinateSize += 8;

          var size = 1 + 4 + 4;

          if (this.exteriorRing.length > 0) size += 4 + this.exteriorRing.length * coordinateSize;

          for (var i = 0; i < this.interiorRings.length; i++) size += 4 + this.interiorRings[i].length * coordinateSize;

          return size;
        };

        Polygon.prototype.toGeoJSON = function (options) {
          var geoJSON = Geometry.prototype.toGeoJSON.call(this, options);
          geoJSON.type = Types.geoJSON.Polygon;
          geoJSON.coordinates = [];

          if (this.exteriorRing.length > 0) {
            var exteriorRing = [];

            for (var i = 0; i < this.exteriorRing.length; i++) {
              if (this.hasZ)
                exteriorRing.push([this.exteriorRing[i].x, this.exteriorRing[i].y, this.exteriorRing[i].z]);
              else exteriorRing.push([this.exteriorRing[i].x, this.exteriorRing[i].y]);
            }

            geoJSON.coordinates.push(exteriorRing);
          }

          for (var j = 0; j < this.interiorRings.length; j++) {
            var interiorRing = [];

            for (var k = 0; k < this.interiorRings[j].length; k++) {
              if (this.hasZ)
                interiorRing.push([this.interiorRings[j][k].x, this.interiorRings[j][k].y, this.interiorRings[j][k].z]);
              else interiorRing.push([this.interiorRings[j][k].x, this.interiorRings[j][k].y]);
            }

            geoJSON.coordinates.push(interiorRing);
          }

          return geoJSON;
        };
      },
      {
        "./binarywriter": 2,
        "./geometry": 3,
        "./point": 9,
        "./types": 11,
        util: 20,
      },
    ],
    11: [
      function (require, module, exports) {
        module.exports = {
          wkt: {
            Point: "POINT",
            LineString: "LINESTRING",
            Polygon: "POLYGON",
            MultiPoint: "MULTIPOINT",
            MultiLineString: "MULTILINESTRING",
            MultiPolygon: "MULTIPOLYGON",
            GeometryCollection: "GEOMETRYCOLLECTION",
          },
          wkb: {
            Point: 1,
            LineString: 2,
            Polygon: 3,
            MultiPoint: 4,
            MultiLineString: 5,
            MultiPolygon: 6,
            GeometryCollection: 7,
          },
          geoJSON: {
            Point: "Point",
            LineString: "LineString",
            Polygon: "Polygon",
            MultiPoint: "MultiPoint",
            MultiLineString: "MultiLineString",
            MultiPolygon: "MultiPolygon",
            GeometryCollection: "GeometryCollection",
          },
        };
      },
      {},
    ],
    12: [
      function (require, module, exports) {
        module.exports = WktParser;

        var Types = require("./types");
        var Point = require("./point");

        function WktParser(value) {
          this.value = value;
          this.position = 0;
        }

        WktParser.prototype.match = function (tokens) {
          this.skipWhitespaces();

          for (var i = 0; i < tokens.length; i++) {
            if (this.value.substring(this.position).indexOf(tokens[i]) === 0) {
              this.position += tokens[i].length;
              return tokens[i];
            }
          }

          return null;
        };

        WktParser.prototype.matchRegex = function (tokens) {
          this.skipWhitespaces();

          for (var i = 0; i < tokens.length; i++) {
            var match = this.value.substring(this.position).match(tokens[i]);

            if (match) {
              this.position += match[0].length;
              return match;
            }
          }

          return null;
        };

        WktParser.prototype.isMatch = function (tokens) {
          this.skipWhitespaces();

          for (var i = 0; i < tokens.length; i++) {
            if (this.value.substring(this.position).indexOf(tokens[i]) === 0) {
              this.position += tokens[i].length;
              return true;
            }
          }

          return false;
        };

        WktParser.prototype.matchType = function () {
          var geometryType = this.match([
            Types.wkt.Point,
            Types.wkt.LineString,
            Types.wkt.Polygon,
            Types.wkt.MultiPoint,
            Types.wkt.MultiLineString,
            Types.wkt.MultiPolygon,
            Types.wkt.GeometryCollection,
          ]);

          if (!geometryType) throw new Error("Expected geometry type");

          return geometryType;
        };

        WktParser.prototype.matchDimension = function () {
          var dimension = this.match(["ZM", "Z", "M"]);

          switch (dimension) {
            case "ZM":
              return { hasZ: true, hasM: true };
            case "Z":
              return { hasZ: true, hasM: false };
            case "M":
              return { hasZ: false, hasM: true };
            default:
              return { hasZ: false, hasM: false };
          }
        };

        WktParser.prototype.expectGroupStart = function () {
          if (!this.isMatch(["("])) throw new Error("Expected group start");
        };

        WktParser.prototype.expectGroupEnd = function () {
          if (!this.isMatch([")"])) throw new Error("Expected group end");
        };

        WktParser.prototype.matchCoordinate = function (options) {
          var match;

          if (options.hasZ && options.hasM) match = this.matchRegex([/^(\S*)\s+(\S*)\s+(\S*)\s+([^\s,)]*)/]);
          else if (options.hasZ || options.hasM) match = this.matchRegex([/^(\S*)\s+(\S*)\s+([^\s,)]*)/]);
          else match = this.matchRegex([/^(\S*)\s+([^\s,)]*)/]);

          if (!match) throw new Error("Expected coordinates");

          if (options.hasZ && options.hasM)
            return new Point(parseFloat(match[1]), parseFloat(match[2]), parseFloat(match[3]), parseFloat(match[4]));
          else if (options.hasZ) return new Point(parseFloat(match[1]), parseFloat(match[2]), parseFloat(match[3]));
          else if (options.hasM)
            return new Point(parseFloat(match[1]), parseFloat(match[2]), undefined, parseFloat(match[3]));
          else return new Point(parseFloat(match[1]), parseFloat(match[2]));
        };

        WktParser.prototype.matchCoordinates = function (options) {
          var coordinates = [];

          do {
            var startsWithBracket = this.isMatch(["("]);

            coordinates.push(this.matchCoordinate(options));

            if (startsWithBracket) this.expectGroupEnd();
          } while (this.isMatch([","]));

          return coordinates;
        };

        WktParser.prototype.skipWhitespaces = function () {
          while (this.position < this.value.length && this.value[this.position] === " ") this.position++;
        };
      },
      { "./point": 9, "./types": 11 },
    ],
    13: [
      function (require, module, exports) {
        module.exports = {
          encode: function (value) {
            return (value << 1) ^ (value >> 31);
          },
          decode: function (value) {
            return (value >> 1) ^ -(value & 1);
          },
        };
      },
      {},
    ],
    14: [
      function (require, module, exports) {
        "use strict";

        exports.byteLength = byteLength;
        exports.toByteArray = toByteArray;
        exports.fromByteArray = fromByteArray;

        var lookup = [];
        var revLookup = [];
        var Arr = typeof Uint8Array !== "undefined" ? Uint8Array : Array;

        var code = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (var i = 0, len = code.length; i < len; ++i) {
          lookup[i] = code[i];
          revLookup[code.charCodeAt(i)] = i;
        }

        // Support decoding URL-safe base64 strings, as Node.js does.
        // See: https://en.wikipedia.org/wiki/Base64#URL_applications
        revLookup["-".charCodeAt(0)] = 62;
        revLookup["_".charCodeAt(0)] = 63;

        function getLens(b64) {
          var len = b64.length;

          if (len % 4 > 0) {
            throw new Error("Invalid string. Length must be a multiple of 4");
          }

          // Trim off extra bytes after placeholder bytes are found
          // See: https://github.com/beatgammit/base64-js/issues/42
          var validLen = b64.indexOf("=");
          if (validLen === -1) validLen = len;

          var placeHoldersLen = validLen === len ? 0 : 4 - (validLen % 4);

          return [validLen, placeHoldersLen];
        }

        // base64 is 4/3 + up to two characters of the original data
        function byteLength(b64) {
          var lens = getLens(b64);
          var validLen = lens[0];
          var placeHoldersLen = lens[1];
          return ((validLen + placeHoldersLen) * 3) / 4 - placeHoldersLen;
        }

        function _byteLength(b64, validLen, placeHoldersLen) {
          return ((validLen + placeHoldersLen) * 3) / 4 - placeHoldersLen;
        }

        function toByteArray(b64) {
          var tmp;
          var lens = getLens(b64);
          var validLen = lens[0];
          var placeHoldersLen = lens[1];

          var arr = new Arr(_byteLength(b64, validLen, placeHoldersLen));

          var curByte = 0;

          // if there are placeholders, only get up to the last complete 4 chars
          var len = placeHoldersLen > 0 ? validLen - 4 : validLen;

          var i;
          for (i = 0; i < len; i += 4) {
            tmp =
              (revLookup[b64.charCodeAt(i)] << 18) |
              (revLookup[b64.charCodeAt(i + 1)] << 12) |
              (revLookup[b64.charCodeAt(i + 2)] << 6) |
              revLookup[b64.charCodeAt(i + 3)];
            arr[curByte++] = (tmp >> 16) & 0xff;
            arr[curByte++] = (tmp >> 8) & 0xff;
            arr[curByte++] = tmp & 0xff;
          }

          if (placeHoldersLen === 2) {
            tmp = (revLookup[b64.charCodeAt(i)] << 2) | (revLookup[b64.charCodeAt(i + 1)] >> 4);
            arr[curByte++] = tmp & 0xff;
          }

          if (placeHoldersLen === 1) {
            tmp =
              (revLookup[b64.charCodeAt(i)] << 10) |
              (revLookup[b64.charCodeAt(i + 1)] << 4) |
              (revLookup[b64.charCodeAt(i + 2)] >> 2);
            arr[curByte++] = (tmp >> 8) & 0xff;
            arr[curByte++] = tmp & 0xff;
          }

          return arr;
        }

        function tripletToBase64(num) {
          return (
            lookup[(num >> 18) & 0x3f] + lookup[(num >> 12) & 0x3f] + lookup[(num >> 6) & 0x3f] + lookup[num & 0x3f]
          );
        }

        function encodeChunk(uint8, start, end) {
          var tmp;
          var output = [];
          for (var i = start; i < end; i += 3) {
            tmp = ((uint8[i] << 16) & 0xff0000) + ((uint8[i + 1] << 8) & 0xff00) + (uint8[i + 2] & 0xff);
            output.push(tripletToBase64(tmp));
          }
          return output.join("");
        }

        function fromByteArray(uint8) {
          var tmp;
          var len = uint8.length;
          var extraBytes = len % 3; // if we have 1 byte left, pad 2 bytes
          var parts = [];
          var maxChunkLength = 16383; // must be multiple of 3

          // go through the array every three bytes, we'll deal with trailing stuff later
          for (var i = 0, len2 = len - extraBytes; i < len2; i += maxChunkLength) {
            parts.push(encodeChunk(uint8, i, i + maxChunkLength > len2 ? len2 : i + maxChunkLength));
          }

          // pad the end with zeros, but make sure to not forget the extra bytes
          if (extraBytes === 1) {
            tmp = uint8[len - 1];
            parts.push(lookup[tmp >> 2] + lookup[(tmp << 4) & 0x3f] + "==");
          } else if (extraBytes === 2) {
            tmp = (uint8[len - 2] << 8) + uint8[len - 1];
            parts.push(lookup[tmp >> 10] + lookup[(tmp >> 4) & 0x3f] + lookup[(tmp << 2) & 0x3f] + "=");
          }

          return parts.join("");
        }
      },
      {},
    ],
    15: [
      function (require, module, exports) {
        exports.read = function (buffer, offset, isLE, mLen, nBytes) {
          var e, m;
          var eLen = nBytes * 8 - mLen - 1;
          var eMax = (1 << eLen) - 1;
          var eBias = eMax >> 1;
          var nBits = -7;
          var i = isLE ? nBytes - 1 : 0;
          var d = isLE ? -1 : 1;
          var s = buffer[offset + i];

          i += d;

          e = s & ((1 << -nBits) - 1);
          s >>= -nBits;
          nBits += eLen;
          for (; nBits > 0; e = e * 256 + buffer[offset + i], i += d, nBits -= 8) {}

          m = e & ((1 << -nBits) - 1);
          e >>= -nBits;
          nBits += mLen;
          for (; nBits > 0; m = m * 256 + buffer[offset + i], i += d, nBits -= 8) {}

          if (e === 0) {
            e = 1 - eBias;
          } else if (e === eMax) {
            return m ? NaN : (s ? -1 : 1) * Infinity;
          } else {
            m = m + Math.pow(2, mLen);
            e = e - eBias;
          }
          return (s ? -1 : 1) * m * Math.pow(2, e - mLen);
        };

        exports.write = function (buffer, value, offset, isLE, mLen, nBytes) {
          var e, m, c;
          var eLen = nBytes * 8 - mLen - 1;
          var eMax = (1 << eLen) - 1;
          var eBias = eMax >> 1;
          var rt = mLen === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0;
          var i = isLE ? 0 : nBytes - 1;
          var d = isLE ? 1 : -1;
          var s = value < 0 || (value === 0 && 1 / value < 0) ? 1 : 0;

          value = Math.abs(value);

          if (isNaN(value) || value === Infinity) {
            m = isNaN(value) ? 1 : 0;
            e = eMax;
          } else {
            e = Math.floor(Math.log(value) / Math.LN2);
            if (value * (c = Math.pow(2, -e)) < 1) {
              e--;
              c *= 2;
            }
            if (e + eBias >= 1) {
              value += rt / c;
            } else {
              value += rt * Math.pow(2, 1 - eBias);
            }
            if (value * c >= 2) {
              e++;
              c /= 2;
            }

            if (e + eBias >= eMax) {
              m = 0;
              e = eMax;
            } else if (e + eBias >= 1) {
              m = (value * c - 1) * Math.pow(2, mLen);
              e = e + eBias;
            } else {
              m = value * Math.pow(2, eBias - 1) * Math.pow(2, mLen);
              e = 0;
            }
          }

          for (; mLen >= 8; buffer[offset + i] = m & 0xff, i += d, m /= 256, mLen -= 8) {}

          e = (e << mLen) | m;
          eLen += mLen;
          for (; eLen > 0; buffer[offset + i] = e & 0xff, i += d, e /= 256, eLen -= 8) {}

          buffer[offset + i - d] |= s * 128;
        };
      },
      {},
    ],
    16: [
      function (require, module, exports) {
        if (typeof Object.create === "function") {
          // implementation from standard node.js 'util' module
          module.exports = function inherits(ctor, superCtor) {
            ctor.super_ = superCtor;
            ctor.prototype = Object.create(superCtor.prototype, {
              constructor: {
                value: ctor,
                enumerable: false,
                writable: true,
                configurable: true,
              },
            });
          };
        } else {
          // old school shim for old browsers
          module.exports = function inherits(ctor, superCtor) {
            ctor.super_ = superCtor;
            var TempCtor = function () {};
            TempCtor.prototype = superCtor.prototype;
            ctor.prototype = new TempCtor();
            ctor.prototype.constructor = ctor;
          };
        }
      },
      {},
    ],
    17: [
      function (require, module, exports) {
        /*!
         * Determine if an object is a Buffer
         *
         * @author   Feross Aboukhadijeh <https://feross.org>
         * @license  MIT
         */

        // The _isBuffer check is for Safari 5-7 support, because it's missing
        // Object.prototype.constructor. Remove this eventually
        module.exports = function (obj) {
          return obj != null && (isBuffer(obj) || isSlowBuffer(obj) || !!obj._isBuffer);
        };

        function isBuffer(obj) {
          return !!obj.constructor && typeof obj.constructor.isBuffer === "function" && obj.constructor.isBuffer(obj);
        }

        // For Node v0.10 support. Remove this eventually.
        function isSlowBuffer(obj) {
          return typeof obj.readFloatLE === "function" && typeof obj.slice === "function" && isBuffer(obj.slice(0, 0));
        }
      },
      {},
    ],
    18: [
      function (require, module, exports) {
        // shim for using process in browser
        var process = (module.exports = {});

        // cached from whatever global is present so that test runners that stub it
        // don't break things.  But we need to wrap it in a try catch in case it is
        // wrapped in strict mode code which doesn't define any globals.  It's inside a
        // function because try/catches deoptimize in certain engines.

        var cachedSetTimeout;
        var cachedClearTimeout;

        function defaultSetTimout() {
          throw new Error("setTimeout has not been defined");
        }
        function defaultClearTimeout() {
          throw new Error("clearTimeout has not been defined");
        }
        (function () {
          try {
            if (typeof setTimeout === "function") {
              cachedSetTimeout = setTimeout;
            } else {
              cachedSetTimeout = defaultSetTimout;
            }
          } catch (e) {
            cachedSetTimeout = defaultSetTimout;
          }
          try {
            if (typeof clearTimeout === "function") {
              cachedClearTimeout = clearTimeout;
            } else {
              cachedClearTimeout = defaultClearTimeout;
            }
          } catch (e) {
            cachedClearTimeout = defaultClearTimeout;
          }
        })();
        function runTimeout(fun) {
          if (cachedSetTimeout === setTimeout) {
            //normal enviroments in sane situations
            return setTimeout(fun, 0);
          }
          // if setTimeout wasn't available but was latter defined
          if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
            cachedSetTimeout = setTimeout;
            return setTimeout(fun, 0);
          }
          try {
            // when when somebody has screwed with setTimeout but no I.E. maddness
            return cachedSetTimeout(fun, 0);
          } catch (e) {
            try {
              // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
              return cachedSetTimeout.call(null, fun, 0);
            } catch (e) {
              // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
              return cachedSetTimeout.call(this, fun, 0);
            }
          }
        }
        function runClearTimeout(marker) {
          if (cachedClearTimeout === clearTimeout) {
            //normal enviroments in sane situations
            return clearTimeout(marker);
          }
          // if clearTimeout wasn't available but was latter defined
          if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
            cachedClearTimeout = clearTimeout;
            return clearTimeout(marker);
          }
          try {
            // when when somebody has screwed with setTimeout but no I.E. maddness
            return cachedClearTimeout(marker);
          } catch (e) {
            try {
              // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
              return cachedClearTimeout.call(null, marker);
            } catch (e) {
              // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
              // Some versions of I.E. have different rules for clearTimeout vs setTimeout
              return cachedClearTimeout.call(this, marker);
            }
          }
        }
        var queue = [];
        var draining = false;
        var currentQueue;
        var queueIndex = -1;

        function cleanUpNextTick() {
          if (!draining || !currentQueue) {
            return;
          }
          draining = false;
          if (currentQueue.length) {
            queue = currentQueue.concat(queue);
          } else {
            queueIndex = -1;
          }
          if (queue.length) {
            drainQueue();
          }
        }

        function drainQueue() {
          if (draining) {
            return;
          }
          var timeout = runTimeout(cleanUpNextTick);
          draining = true;

          var len = queue.length;
          while (len) {
            currentQueue = queue;
            queue = [];
            while (++queueIndex < len) {
              if (currentQueue) {
                currentQueue[queueIndex].run();
              }
            }
            queueIndex = -1;
            len = queue.length;
          }
          currentQueue = null;
          draining = false;
          runClearTimeout(timeout);
        }

        process.nextTick = function (fun) {
          var args = new Array(arguments.length - 1);
          if (arguments.length > 1) {
            for (var i = 1; i < arguments.length; i++) {
              args[i - 1] = arguments[i];
            }
          }
          queue.push(new Item(fun, args));
          if (queue.length === 1 && !draining) {
            runTimeout(drainQueue);
          }
        };

        // v8 likes predictible objects
        function Item(fun, array) {
          this.fun = fun;
          this.array = array;
        }
        Item.prototype.run = function () {
          this.fun.apply(null, this.array);
        };
        process.title = "browser";
        process.browser = true;
        process.env = {};
        process.argv = [];
        process.version = ""; // empty string to avoid regexp issues
        process.versions = {};

        function noop() {}

        process.on = noop;
        process.addListener = noop;
        process.once = noop;
        process.off = noop;
        process.removeListener = noop;
        process.removeAllListeners = noop;
        process.emit = noop;
        process.prependListener = noop;
        process.prependOnceListener = noop;

        process.listeners = function (name) {
          return [];
        };

        process.binding = function (name) {
          throw new Error("process.binding is not supported");
        };

        process.cwd = function () {
          return "/";
        };
        process.chdir = function (dir) {
          throw new Error("process.chdir is not supported");
        };
        process.umask = function () {
          return 0;
        };
      },
      {},
    ],
    19: [
      function (require, module, exports) {
        module.exports = function isBuffer(arg) {
          return (
            arg &&
            typeof arg === "object" &&
            typeof arg.copy === "function" &&
            typeof arg.fill === "function" &&
            typeof arg.readUInt8 === "function"
          );
        };
      },
      {},
    ],
    20: [
      function (require, module, exports) {
        (function (process, global) {
          // Copyright Joyent, Inc. and other Node contributors.
          //
          // Permission is hereby granted, free of charge, to any person obtaining a
          // copy of this software and associated documentation files (the
          // "Software"), to deal in the Software without restriction, including
          // without limitation the rights to use, copy, modify, merge, publish,
          // distribute, sublicense, and/or sell copies of the Software, and to permit
          // persons to whom the Software is furnished to do so, subject to the
          // following conditions:
          //
          // The above copyright notice and this permission notice shall be included
          // in all copies or substantial portions of the Software.
          //
          // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
          // OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
          // MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
          // NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
          // DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
          // OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
          // USE OR OTHER DEALINGS IN THE SOFTWARE.

          var formatRegExp = /%[sdj%]/g;
          exports.format = function (f) {
            if (!isString(f)) {
              var objects = [];
              for (var i = 0; i < arguments.length; i++) {
                objects.push(inspect(arguments[i]));
              }
              return objects.join(" ");
            }

            var i = 1;
            var args = arguments;
            var len = args.length;
            var str = String(f).replace(formatRegExp, function (x) {
              if (x === "%%") return "%";
              if (i >= len) return x;
              switch (x) {
                case "%s":
                  return String(args[i++]);
                case "%d":
                  return Number(args[i++]);
                case "%j":
                  try {
                    return JSON.stringify(args[i++]);
                  } catch (_) {
                    return "[Circular]";
                  }
                default:
                  return x;
              }
            });
            for (var x = args[i]; i < len; x = args[++i]) {
              if (isNull(x) || !isObject(x)) {
                str += " " + x;
              } else {
                str += " " + inspect(x);
              }
            }
            return str;
          };

          // Mark that a method should not be used.
          // Returns a modified function which warns once by default.
          // If --no-deprecation is set, then it is a no-op.
          exports.deprecate = function (fn, msg) {
            // Allow for deprecating things in the process of starting up.
            if (isUndefined(global.process)) {
              return function () {
                return exports.deprecate(fn, msg).apply(this, arguments);
              };
            }

            if (process.noDeprecation === true) {
              return fn;
            }

            var warned = false;
            function deprecated() {
              if (!warned) {
                if (process.throwDeprecation) {
                  throw new Error(msg);
                } else if (process.traceDeprecation) {
                  console.trace(msg);
                } else {
                  console.error(msg);
                }
                warned = true;
              }
              return fn.apply(this, arguments);
            }

            return deprecated;
          };

          var debugs = {};
          var debugEnviron;
          exports.debuglog = function (set) {
            if (isUndefined(debugEnviron)) debugEnviron = process.env.NODE_DEBUG || "";
            set = set.toUpperCase();
            if (!debugs[set]) {
              if (new RegExp("\\b" + set + "\\b", "i").test(debugEnviron)) {
                var pid = process.pid;
                debugs[set] = function () {
                  var msg = exports.format.apply(exports, arguments);
                  console.error("%s %d: %s", set, pid, msg);
                };
              } else {
                debugs[set] = function () {};
              }
            }
            return debugs[set];
          };

          /**
           * Echos the value of a value. Trys to print the value out
           * in the best way possible given the different types.
           *
           * @param {Object} obj The object to print out.
           * @param {Object} opts Optional options object that alters the output.
           */
          /* legacy: obj, showHidden, depth, colors*/
          function inspect(obj, opts) {
            // default options
            var ctx = {
              seen: [],
              stylize: stylizeNoColor,
            };
            // legacy...
            if (arguments.length >= 3) ctx.depth = arguments[2];
            if (arguments.length >= 4) ctx.colors = arguments[3];
            if (isBoolean(opts)) {
              // legacy...
              ctx.showHidden = opts;
            } else if (opts) {
              // got an "options" object
              exports._extend(ctx, opts);
            }
            // set default options
            if (isUndefined(ctx.showHidden)) ctx.showHidden = false;
            if (isUndefined(ctx.depth)) ctx.depth = 2;
            if (isUndefined(ctx.colors)) ctx.colors = false;
            if (isUndefined(ctx.customInspect)) ctx.customInspect = true;
            if (ctx.colors) ctx.stylize = stylizeWithColor;
            return formatValue(ctx, obj, ctx.depth);
          }
          exports.inspect = inspect;

          // http://en.wikipedia.org/wiki/ANSI_escape_code#graphics
          inspect.colors = {
            bold: [1, 22],
            italic: [3, 23],
            underline: [4, 24],
            inverse: [7, 27],
            white: [37, 39],
            grey: [90, 39],
            black: [30, 39],
            blue: [34, 39],
            cyan: [36, 39],
            green: [32, 39],
            magenta: [35, 39],
            red: [31, 39],
            yellow: [33, 39],
          };

          // Don't use 'blue' not visible on cmd.exe
          inspect.styles = {
            special: "cyan",
            number: "yellow",
            boolean: "yellow",
            undefined: "grey",
            null: "bold",
            string: "green",
            date: "magenta",
            // "name": intentionally not styling
            regexp: "red",
          };

          function stylizeWithColor(str, styleType) {
            var style = inspect.styles[styleType];

            if (style) {
              return "\u001b[" + inspect.colors[style][0] + "m" + str + "\u001b[" + inspect.colors[style][1] + "m";
            } else {
              return str;
            }
          }

          function stylizeNoColor(str, styleType) {
            return str;
          }

          function arrayToHash(array) {
            var hash = {};

            array.forEach(function (val, idx) {
              hash[val] = true;
            });

            return hash;
          }

          function formatValue(ctx, value, recurseTimes) {
            // Provide a hook for user-specified inspect functions.
            // Check that value is an object with an inspect function on it
            if (
              ctx.customInspect &&
              value &&
              isFunction(value.inspect) &&
              // Filter out the util module, it's inspect function is special
              value.inspect !== exports.inspect &&
              // Also filter out any prototype objects using the circular check.
              !(value.constructor && value.constructor.prototype === value)
            ) {
              var ret = value.inspect(recurseTimes, ctx);
              if (!isString(ret)) {
                ret = formatValue(ctx, ret, recurseTimes);
              }
              return ret;
            }

            // Primitive types cannot have properties
            var primitive = formatPrimitive(ctx, value);
            if (primitive) {
              return primitive;
            }

            // Look up the keys of the object.
            var keys = Object.keys(value);
            var visibleKeys = arrayToHash(keys);

            if (ctx.showHidden) {
              keys = Object.getOwnPropertyNames(value);
            }

            // IE doesn't make error fields non-enumerable
            // http://msdn.microsoft.com/en-us/library/ie/dww52sbt(v=vs.94).aspx
            if (isError(value) && (keys.indexOf("message") >= 0 || keys.indexOf("description") >= 0)) {
              return formatError(value);
            }

            // Some type of object without properties can be shortcutted.
            if (keys.length === 0) {
              if (isFunction(value)) {
                var name = value.name ? ": " + value.name : "";
                return ctx.stylize("[Function" + name + "]", "special");
              }
              if (isRegExp(value)) {
                return ctx.stylize(RegExp.prototype.toString.call(value), "regexp");
              }
              if (isDate(value)) {
                return ctx.stylize(Date.prototype.toString.call(value), "date");
              }
              if (isError(value)) {
                return formatError(value);
              }
            }

            var base = "",
              array = false,
              braces = ["{", "}"];

            // Make Array say that they are Array
            if (isArray(value)) {
              array = true;
              braces = ["[", "]"];
            }

            // Make functions say that they are functions
            if (isFunction(value)) {
              var n = value.name ? ": " + value.name : "";
              base = " [Function" + n + "]";
            }

            // Make RegExps say that they are RegExps
            if (isRegExp(value)) {
              base = " " + RegExp.prototype.toString.call(value);
            }

            // Make dates with properties first say the date
            if (isDate(value)) {
              base = " " + Date.prototype.toUTCString.call(value);
            }

            // Make error with message first say the error
            if (isError(value)) {
              base = " " + formatError(value);
            }

            if (keys.length === 0 && (!array || value.length == 0)) {
              return braces[0] + base + braces[1];
            }

            if (recurseTimes < 0) {
              if (isRegExp(value)) {
                return ctx.stylize(RegExp.prototype.toString.call(value), "regexp");
              } else {
                return ctx.stylize("[Object]", "special");
              }
            }

            ctx.seen.push(value);

            var output;
            if (array) {
              output = formatArray(ctx, value, recurseTimes, visibleKeys, keys);
            } else {
              output = keys.map(function (key) {
                return formatProperty(ctx, value, recurseTimes, visibleKeys, key, array);
              });
            }

            ctx.seen.pop();

            return reduceToSingleString(output, base, braces);
          }

          function formatPrimitive(ctx, value) {
            if (isUndefined(value)) return ctx.stylize("undefined", "undefined");
            if (isString(value)) {
              var simple =
                "'" + JSON.stringify(value).replace(/^"|"$/g, "").replace(/'/g, "\\'").replace(/\\"/g, '"') + "'";
              return ctx.stylize(simple, "string");
            }
            if (isNumber(value)) return ctx.stylize("" + value, "number");
            if (isBoolean(value)) return ctx.stylize("" + value, "boolean");
            // For some reason typeof null is "object", so special case here.
            if (isNull(value)) return ctx.stylize("null", "null");
          }

          function formatError(value) {
            return "[" + Error.prototype.toString.call(value) + "]";
          }

          function formatArray(ctx, value, recurseTimes, visibleKeys, keys) {
            var output = [];
            for (var i = 0, l = value.length; i < l; ++i) {
              if (hasOwnProperty(value, String(i))) {
                output.push(formatProperty(ctx, value, recurseTimes, visibleKeys, String(i), true));
              } else {
                output.push("");
              }
            }
            keys.forEach(function (key) {
              if (!key.match(/^\d+$/)) {
                output.push(formatProperty(ctx, value, recurseTimes, visibleKeys, key, true));
              }
            });
            return output;
          }

          function formatProperty(ctx, value, recurseTimes, visibleKeys, key, array) {
            var name, str, desc;
            desc = Object.getOwnPropertyDescriptor(value, key) || {
              value: value[key],
            };
            if (desc.get) {
              if (desc.set) {
                str = ctx.stylize("[Getter/Setter]", "special");
              } else {
                str = ctx.stylize("[Getter]", "special");
              }
            } else {
              if (desc.set) {
                str = ctx.stylize("[Setter]", "special");
              }
            }
            if (!hasOwnProperty(visibleKeys, key)) {
              name = "[" + key + "]";
            }
            if (!str) {
              if (ctx.seen.indexOf(desc.value) < 0) {
                if (isNull(recurseTimes)) {
                  str = formatValue(ctx, desc.value, null);
                } else {
                  str = formatValue(ctx, desc.value, recurseTimes - 1);
                }
                if (str.indexOf("\n") > -1) {
                  if (array) {
                    str = str
                      .split("\n")
                      .map(function (line) {
                        return "  " + line;
                      })
                      .join("\n")
                      .substr(2);
                  } else {
                    str =
                      "\n" +
                      str
                        .split("\n")
                        .map(function (line) {
                          return "   " + line;
                        })
                        .join("\n");
                  }
                }
              } else {
                str = ctx.stylize("[Circular]", "special");
              }
            }
            if (isUndefined(name)) {
              if (array && key.match(/^\d+$/)) {
                return str;
              }
              name = JSON.stringify("" + key);
              if (name.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/)) {
                name = name.substr(1, name.length - 2);
                name = ctx.stylize(name, "name");
              } else {
                name = name
                  .replace(/'/g, "\\'")
                  .replace(/\\"/g, '"')
                  .replace(/(^"|"$)/g, "'");
                name = ctx.stylize(name, "string");
              }
            }

            return name + ": " + str;
          }

          function reduceToSingleString(output, base, braces) {
            var numLinesEst = 0;
            var length = output.reduce(function (prev, cur) {
              numLinesEst++;
              if (cur.indexOf("\n") >= 0) numLinesEst++;
              return prev + cur.replace(/\u001b\[\d\d?m/g, "").length + 1;
            }, 0);

            if (length > 60) {
              return braces[0] + (base === "" ? "" : base + "\n ") + " " + output.join(",\n  ") + " " + braces[1];
            }

            return braces[0] + base + " " + output.join(", ") + " " + braces[1];
          }

          // NOTE: These type checking functions intentionally don't use `instanceof`
          // because it is fragile and can be easily faked with `Object.create()`.
          function isArray(ar) {
            return Array.isArray(ar);
          }
          exports.isArray = isArray;

          function isBoolean(arg) {
            return typeof arg === "boolean";
          }
          exports.isBoolean = isBoolean;

          function isNull(arg) {
            return arg === null;
          }
          exports.isNull = isNull;

          function isNullOrUndefined(arg) {
            return arg == null;
          }
          exports.isNullOrUndefined = isNullOrUndefined;

          function isNumber(arg) {
            return typeof arg === "number";
          }
          exports.isNumber = isNumber;

          function isString(arg) {
            return typeof arg === "string";
          }
          exports.isString = isString;

          function isSymbol(arg) {
            return typeof arg === "symbol";
          }
          exports.isSymbol = isSymbol;

          function isUndefined(arg) {
            return arg === void 0;
          }
          exports.isUndefined = isUndefined;

          function isRegExp(re) {
            return isObject(re) && objectToString(re) === "[object RegExp]";
          }
          exports.isRegExp = isRegExp;

          function isObject(arg) {
            return typeof arg === "object" && arg !== null;
          }
          exports.isObject = isObject;

          function isDate(d) {
            return isObject(d) && objectToString(d) === "[object Date]";
          }
          exports.isDate = isDate;

          function isError(e) {
            return isObject(e) && (objectToString(e) === "[object Error]" || e instanceof Error);
          }
          exports.isError = isError;

          function isFunction(arg) {
            return typeof arg === "function";
          }
          exports.isFunction = isFunction;

          function isPrimitive(arg) {
            return (
              arg === null ||
              typeof arg === "boolean" ||
              typeof arg === "number" ||
              typeof arg === "string" ||
              typeof arg === "symbol" || // ES6 symbol
              typeof arg === "undefined"
            );
          }
          exports.isPrimitive = isPrimitive;

          exports.isBuffer = require("./support/isBuffer");

          function objectToString(o) {
            return Object.prototype.toString.call(o);
          }

          function pad(n) {
            return n < 10 ? "0" + n.toString(10) : n.toString(10);
          }

          var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

          // 26 Feb 16:19:34
          function timestamp() {
            var d = new Date();
            var time = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(":");
            return [d.getDate(), months[d.getMonth()], time].join(" ");
          }

          // log is just a thin wrapper to console.log that prepends a timestamp
          exports.log = function () {
            console.log("%s - %s", timestamp(), exports.format.apply(exports, arguments));
          };

          /**
           * Inherit the prototype methods from one constructor into another.
           *
           * The Function.prototype.inherits from lang.js rewritten as a standalone
           * function (not on Function.prototype). NOTE: If this file is to be loaded
           * during bootstrapping this function needs to be rewritten using some native
           * functions as prototype setup using normal JavaScript does not work as
           * expected during bootstrapping (see mirror.js in r114903).
           *
           * @param {function} ctor Constructor function which needs to inherit the
           *     prototype.
           * @param {function} superCtor Constructor function to inherit prototype from.
           */
          exports.inherits = require("inherits");

          exports._extend = function (origin, add) {
            // Don't do anything if add isn't an object
            if (!add || !isObject(add)) return origin;

            var keys = Object.keys(add);
            var i = keys.length;
            while (i--) {
              origin[keys[i]] = add[keys[i]];
            }
            return origin;
          };

          function hasOwnProperty(obj, prop) {
            return Object.prototype.hasOwnProperty.call(obj, prop);
          }
        }).call(
          this,
          require("_process"),
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        );
      },
      { "./support/isBuffer": 19, _process: 18, inherits: 16 },
    ],
    buffer: [
      function (require, module, exports) {
        (function (Buffer) {
          /*!
           * The buffer module from node.js, for the browser.
           *
           * @author   Feross Aboukhadijeh <https://feross.org>
           * @license  MIT
           */
          /* eslint-disable no-proto */

          "use strict";

          var base64 = require("base64-js");
          var ieee754 = require("ieee754");
          var customInspectSymbol =
            typeof Symbol === "function" && typeof Symbol.for === "function"
              ? Symbol.for("nodejs.util.inspect.custom")
              : null;

          exports.Buffer = Buffer;
          exports.SlowBuffer = SlowBuffer;
          exports.INSPECT_MAX_BYTES = 50;

          var K_MAX_LENGTH = 0x7fffffff;
          exports.kMaxLength = K_MAX_LENGTH;

          /**
           * If `Buffer.TYPED_ARRAY_SUPPORT`:
           *   === true    Use Uint8Array implementation (fastest)
           *   === false   Print warning and recommend using `buffer` v4.x which has an Object
           *               implementation (most compatible, even IE6)
           *
           * Browsers that support typed arrays are IE 10+, Firefox 4+, Chrome 7+, Safari 5.1+,
           * Opera 11.6+, iOS 4.2+.
           *
           * We report that the browser does not support typed arrays if the are not subclassable
           * using __proto__. Firefox 4-29 lacks support for adding new properties to `Uint8Array`
           * (See: https://bugzilla.mozilla.org/show_bug.cgi?id=695438). IE 10 lacks support
           * for __proto__ and has a buggy typed array implementation.
           */
          Buffer.TYPED_ARRAY_SUPPORT = typedArraySupport();

          if (!Buffer.TYPED_ARRAY_SUPPORT && typeof console !== "undefined" && typeof console.error === "function") {
            console.error(
              "This browser lacks typed array (Uint8Array) support which is required by " +
                "`buffer` v5.x. Use `buffer` v4.x if you require old browser support."
            );
          }

          function typedArraySupport() {
            // Can typed array instances can be augmented?
            try {
              var arr = new Uint8Array(1);
              var proto = {
                foo: function () {
                  return 42;
                },
              };
              Object.setPrototypeOf(proto, Uint8Array.prototype);
              Object.setPrototypeOf(arr, proto);
              return arr.foo() === 42;
            } catch (e) {
              return false;
            }
          }

          Object.defineProperty(Buffer.prototype, "parent", {
            enumerable: true,
            get: function () {
              if (!Buffer.isBuffer(this)) return undefined;
              return this.buffer;
            },
          });

          Object.defineProperty(Buffer.prototype, "offset", {
            enumerable: true,
            get: function () {
              if (!Buffer.isBuffer(this)) return undefined;
              return this.byteOffset;
            },
          });

          function createBuffer(length) {
            if (length > K_MAX_LENGTH) {
              throw new RangeError('The value "' + length + '" is invalid for option "size"');
            }
            // Return an augmented `Uint8Array` instance
            var buf = new Uint8Array(length);
            Object.setPrototypeOf(buf, Buffer.prototype);
            return buf;
          }

          /**
           * The Buffer constructor returns instances of `Uint8Array` that have their
           * prototype changed to `Buffer.prototype`. Furthermore, `Buffer` is a subclass of
           * `Uint8Array`, so the returned instances will have all the node `Buffer` methods
           * and the `Uint8Array` methods. Square bracket notation works as expected -- it
           * returns a single octet.
           *
           * The `Uint8Array` prototype remains unmodified.
           */

          function Buffer(arg, encodingOrOffset, length) {
            // Common case.
            if (typeof arg === "number") {
              if (typeof encodingOrOffset === "string") {
                throw new TypeError('The "string" argument must be of type string. Received type number');
              }
              return allocUnsafe(arg);
            }
            return from(arg, encodingOrOffset, length);
          }

          // Fix subarray() in ES2016. See: https://github.com/feross/buffer/pull/97
          if (typeof Symbol !== "undefined" && Symbol.species != null && Buffer[Symbol.species] === Buffer) {
            Object.defineProperty(Buffer, Symbol.species, {
              value: null,
              configurable: true,
              enumerable: false,
              writable: false,
            });
          }

          Buffer.poolSize = 8192; // not used by this implementation

          function from(value, encodingOrOffset, length) {
            if (typeof value === "string") {
              return fromString(value, encodingOrOffset);
            }

            if (ArrayBuffer.isView(value)) {
              return fromArrayLike(value);
            }

            if (value == null) {
              throw new TypeError(
                "The first argument must be one of type string, Buffer, ArrayBuffer, Array, " +
                  "or Array-like Object. Received type " +
                  typeof value
              );
            }

            if (isInstance(value, ArrayBuffer) || (value && isInstance(value.buffer, ArrayBuffer))) {
              return fromArrayBuffer(value, encodingOrOffset, length);
            }

            if (
              typeof SharedArrayBuffer !== "undefined" &&
              (isInstance(value, SharedArrayBuffer) || (value && isInstance(value.buffer, SharedArrayBuffer)))
            ) {
              return fromArrayBuffer(value, encodingOrOffset, length);
            }

            if (typeof value === "number") {
              throw new TypeError('The "value" argument must not be of type number. Received type number');
            }

            var valueOf = value.valueOf && value.valueOf();
            if (valueOf != null && valueOf !== value) {
              return Buffer.from(valueOf, encodingOrOffset, length);
            }

            var b = fromObject(value);
            if (b) return b;

            if (
              typeof Symbol !== "undefined" &&
              Symbol.toPrimitive != null &&
              typeof value[Symbol.toPrimitive] === "function"
            ) {
              return Buffer.from(value[Symbol.toPrimitive]("string"), encodingOrOffset, length);
            }

            throw new TypeError(
              "The first argument must be one of type string, Buffer, ArrayBuffer, Array, " +
                "or Array-like Object. Received type " +
                typeof value
            );
          }

          /**
           * Functionally equivalent to Buffer(arg, encoding) but throws a TypeError
           * if value is a number.
           * Buffer.from(str[, encoding])
           * Buffer.from(array)
           * Buffer.from(buffer)
           * Buffer.from(arrayBuffer[, byteOffset[, length]])
           **/
          Buffer.from = function (value, encodingOrOffset, length) {
            return from(value, encodingOrOffset, length);
          };

          // Note: Change prototype *after* Buffer.from is defined to workaround Chrome bug:
          // https://github.com/feross/buffer/pull/148
          Object.setPrototypeOf(Buffer.prototype, Uint8Array.prototype);
          Object.setPrototypeOf(Buffer, Uint8Array);

          function assertSize(size) {
            if (typeof size !== "number") {
              throw new TypeError('"size" argument must be of type number');
            } else if (size < 0) {
              throw new RangeError('The value "' + size + '" is invalid for option "size"');
            }
          }

          function alloc(size, fill, encoding) {
            assertSize(size);
            if (size <= 0) {
              return createBuffer(size);
            }
            if (fill !== undefined) {
              // Only pay attention to encoding if it's a string. This
              // prevents accidentally sending in a number that would
              // be interpretted as a start offset.
              return typeof encoding === "string"
                ? createBuffer(size).fill(fill, encoding)
                : createBuffer(size).fill(fill);
            }
            return createBuffer(size);
          }

          /**
           * Creates a new filled Buffer instance.
           * alloc(size[, fill[, encoding]])
           **/
          Buffer.alloc = function (size, fill, encoding) {
            return alloc(size, fill, encoding);
          };

          function allocUnsafe(size) {
            assertSize(size);
            return createBuffer(size < 0 ? 0 : checked(size) | 0);
          }

          /**
           * Equivalent to Buffer(num), by default creates a non-zero-filled Buffer instance.
           * */
          Buffer.allocUnsafe = function (size) {
            return allocUnsafe(size);
          };
          /**
           * Equivalent to SlowBuffer(num), by default creates a non-zero-filled Buffer instance.
           */
          Buffer.allocUnsafeSlow = function (size) {
            return allocUnsafe(size);
          };

          function fromString(string, encoding) {
            if (typeof encoding !== "string" || encoding === "") {
              encoding = "utf8";
            }

            if (!Buffer.isEncoding(encoding)) {
              throw new TypeError("Unknown encoding: " + encoding);
            }

            var length = byteLength(string, encoding) | 0;
            var buf = createBuffer(length);

            var actual = buf.write(string, encoding);

            if (actual !== length) {
              // Writing a hex string, for example, that contains invalid characters will
              // cause everything after the first invalid character to be ignored. (e.g.
              // 'abxxcd' will be treated as 'ab')
              buf = buf.slice(0, actual);
            }

            return buf;
          }

          function fromArrayLike(array) {
            var length = array.length < 0 ? 0 : checked(array.length) | 0;
            var buf = createBuffer(length);
            for (var i = 0; i < length; i += 1) {
              buf[i] = array[i] & 255;
            }
            return buf;
          }

          function fromArrayBuffer(array, byteOffset, length) {
            if (byteOffset < 0 || array.byteLength < byteOffset) {
              throw new RangeError('"offset" is outside of buffer bounds');
            }

            if (array.byteLength < byteOffset + (length || 0)) {
              throw new RangeError('"length" is outside of buffer bounds');
            }

            var buf;
            if (byteOffset === undefined && length === undefined) {
              buf = new Uint8Array(array);
            } else if (length === undefined) {
              buf = new Uint8Array(array, byteOffset);
            } else {
              buf = new Uint8Array(array, byteOffset, length);
            }

            // Return an augmented `Uint8Array` instance
            Object.setPrototypeOf(buf, Buffer.prototype);

            return buf;
          }

          function fromObject(obj) {
            if (Buffer.isBuffer(obj)) {
              var len = checked(obj.length) | 0;
              var buf = createBuffer(len);

              if (buf.length === 0) {
                return buf;
              }

              obj.copy(buf, 0, 0, len);
              return buf;
            }

            if (obj.length !== undefined) {
              if (typeof obj.length !== "number" || numberIsNaN(obj.length)) {
                return createBuffer(0);
              }
              return fromArrayLike(obj);
            }

            if (obj.type === "Buffer" && Array.isArray(obj.data)) {
              return fromArrayLike(obj.data);
            }
          }

          function checked(length) {
            // Note: cannot use `length < K_MAX_LENGTH` here because that fails when
            // length is NaN (which is otherwise coerced to zero.)
            if (length >= K_MAX_LENGTH) {
              throw new RangeError(
                "Attempt to allocate Buffer larger than maximum " + "size: 0x" + K_MAX_LENGTH.toString(16) + " bytes"
              );
            }
            return length | 0;
          }

          function SlowBuffer(length) {
            if (+length != length) {
              // eslint-disable-line eqeqeq
              length = 0;
            }
            return Buffer.alloc(+length);
          }

          Buffer.isBuffer = function isBuffer(b) {
            return b != null && b._isBuffer === true && b !== Buffer.prototype; // so Buffer.isBuffer(Buffer.prototype) will be false
          };

          Buffer.compare = function compare(a, b) {
            if (isInstance(a, Uint8Array)) a = Buffer.from(a, a.offset, a.byteLength);
            if (isInstance(b, Uint8Array)) b = Buffer.from(b, b.offset, b.byteLength);
            if (!Buffer.isBuffer(a) || !Buffer.isBuffer(b)) {
              throw new TypeError('The "buf1", "buf2" arguments must be one of type Buffer or Uint8Array');
            }

            if (a === b) return 0;

            var x = a.length;
            var y = b.length;

            for (var i = 0, len = Math.min(x, y); i < len; ++i) {
              if (a[i] !== b[i]) {
                x = a[i];
                y = b[i];
                break;
              }
            }

            if (x < y) return -1;
            if (y < x) return 1;
            return 0;
          };

          Buffer.isEncoding = function isEncoding(encoding) {
            switch (String(encoding).toLowerCase()) {
              case "hex":
              case "utf8":
              case "utf-8":
              case "ascii":
              case "latin1":
              case "binary":
              case "base64":
              case "ucs2":
              case "ucs-2":
              case "utf16le":
              case "utf-16le":
                return true;
              default:
                return false;
            }
          };

          Buffer.concat = function concat(list, length) {
            if (!Array.isArray(list)) {
              throw new TypeError('"list" argument must be an Array of Buffers');
            }

            if (list.length === 0) {
              return Buffer.alloc(0);
            }

            var i;
            if (length === undefined) {
              length = 0;
              for (i = 0; i < list.length; ++i) {
                length += list[i].length;
              }
            }

            var buffer = Buffer.allocUnsafe(length);
            var pos = 0;
            for (i = 0; i < list.length; ++i) {
              var buf = list[i];
              if (isInstance(buf, Uint8Array)) {
                buf = Buffer.from(buf);
              }
              if (!Buffer.isBuffer(buf)) {
                throw new TypeError('"list" argument must be an Array of Buffers');
              }
              buf.copy(buffer, pos);
              pos += buf.length;
            }
            return buffer;
          };

          function byteLength(string, encoding) {
            if (Buffer.isBuffer(string)) {
              return string.length;
            }
            if (ArrayBuffer.isView(string) || isInstance(string, ArrayBuffer)) {
              return string.byteLength;
            }
            if (typeof string !== "string") {
              throw new TypeError(
                'The "string" argument must be one of type string, Buffer, or ArrayBuffer. ' +
                  "Received type " +
                  typeof string
              );
            }

            var len = string.length;
            var mustMatch = arguments.length > 2 && arguments[2] === true;
            if (!mustMatch && len === 0) return 0;

            // Use a for loop to avoid recursion
            var loweredCase = false;
            for (;;) {
              switch (encoding) {
                case "ascii":
                case "latin1":
                case "binary":
                  return len;
                case "utf8":
                case "utf-8":
                  return utf8ToBytes(string).length;
                case "ucs2":
                case "ucs-2":
                case "utf16le":
                case "utf-16le":
                  return len * 2;
                case "hex":
                  return len >>> 1;
                case "base64":
                  return base64ToBytes(string).length;
                default:
                  if (loweredCase) {
                    return mustMatch ? -1 : utf8ToBytes(string).length; // assume utf8
                  }
                  encoding = ("" + encoding).toLowerCase();
                  loweredCase = true;
              }
            }
          }
          Buffer.byteLength = byteLength;

          function slowToString(encoding, start, end) {
            var loweredCase = false;

            // No need to verify that "this.length <= MAX_UINT32" since it's a read-only
            // property of a typed array.

            // This behaves neither like String nor Uint8Array in that we set start/end
            // to their upper/lower bounds if the value passed is out of range.
            // undefined is handled specially as per ECMA-262 6th Edition,
            // Section 13.3.3.7 Runtime Semantics: KeyedBindingInitialization.
            if (start === undefined || start < 0) {
              start = 0;
            }
            // Return early if start > this.length. Done here to prevent potential uint32
            // coercion fail below.
            if (start > this.length) {
              return "";
            }

            if (end === undefined || end > this.length) {
              end = this.length;
            }

            if (end <= 0) {
              return "";
            }

            // Force coersion to uint32. This will also coerce falsey/NaN values to 0.
            end >>>= 0;
            start >>>= 0;

            if (end <= start) {
              return "";
            }

            if (!encoding) encoding = "utf8";

            while (true) {
              switch (encoding) {
                case "hex":
                  return hexSlice(this, start, end);

                case "utf8":
                case "utf-8":
                  return utf8Slice(this, start, end);

                case "ascii":
                  return asciiSlice(this, start, end);

                case "latin1":
                case "binary":
                  return latin1Slice(this, start, end);

                case "base64":
                  return base64Slice(this, start, end);

                case "ucs2":
                case "ucs-2":
                case "utf16le":
                case "utf-16le":
                  return utf16leSlice(this, start, end);

                default:
                  if (loweredCase) throw new TypeError("Unknown encoding: " + encoding);
                  encoding = (encoding + "").toLowerCase();
                  loweredCase = true;
              }
            }
          }

          // This property is used by `Buffer.isBuffer` (and the `is-buffer` npm package)
          // to detect a Buffer instance. It's not possible to use `instanceof Buffer`
          // reliably in a browserify context because there could be multiple different
          // copies of the 'buffer' package in use. This method works even for Buffer
          // instances that were created from another copy of the `buffer` package.
          // See: https://github.com/feross/buffer/issues/154
          Buffer.prototype._isBuffer = true;

          function swap(b, n, m) {
            var i = b[n];
            b[n] = b[m];
            b[m] = i;
          }

          Buffer.prototype.swap16 = function swap16() {
            var len = this.length;
            if (len % 2 !== 0) {
              throw new RangeError("Buffer size must be a multiple of 16-bits");
            }
            for (var i = 0; i < len; i += 2) {
              swap(this, i, i + 1);
            }
            return this;
          };

          Buffer.prototype.swap32 = function swap32() {
            var len = this.length;
            if (len % 4 !== 0) {
              throw new RangeError("Buffer size must be a multiple of 32-bits");
            }
            for (var i = 0; i < len; i += 4) {
              swap(this, i, i + 3);
              swap(this, i + 1, i + 2);
            }
            return this;
          };

          Buffer.prototype.swap64 = function swap64() {
            var len = this.length;
            if (len % 8 !== 0) {
              throw new RangeError("Buffer size must be a multiple of 64-bits");
            }
            for (var i = 0; i < len; i += 8) {
              swap(this, i, i + 7);
              swap(this, i + 1, i + 6);
              swap(this, i + 2, i + 5);
              swap(this, i + 3, i + 4);
            }
            return this;
          };

          Buffer.prototype.toString = function toString() {
            var length = this.length;
            if (length === 0) return "";
            if (arguments.length === 0) return utf8Slice(this, 0, length);
            return slowToString.apply(this, arguments);
          };

          Buffer.prototype.toLocaleString = Buffer.prototype.toString;

          Buffer.prototype.equals = function equals(b) {
            if (!Buffer.isBuffer(b)) throw new TypeError("Argument must be a Buffer");
            if (this === b) return true;
            return Buffer.compare(this, b) === 0;
          };

          Buffer.prototype.inspect = function inspect() {
            var str = "";
            var max = exports.INSPECT_MAX_BYTES;
            str = this.toString("hex", 0, max)
              .replace(/(.{2})/g, "$1 ")
              .trim();
            if (this.length > max) str += " ... ";
            return "<Buffer " + str + ">";
          };
          if (customInspectSymbol) {
            Buffer.prototype[customInspectSymbol] = Buffer.prototype.inspect;
          }

          Buffer.prototype.compare = function compare(target, start, end, thisStart, thisEnd) {
            if (isInstance(target, Uint8Array)) {
              target = Buffer.from(target, target.offset, target.byteLength);
            }
            if (!Buffer.isBuffer(target)) {
              throw new TypeError(
                'The "target" argument must be one of type Buffer or Uint8Array. ' + "Received type " + typeof target
              );
            }

            if (start === undefined) {
              start = 0;
            }
            if (end === undefined) {
              end = target ? target.length : 0;
            }
            if (thisStart === undefined) {
              thisStart = 0;
            }
            if (thisEnd === undefined) {
              thisEnd = this.length;
            }

            if (start < 0 || end > target.length || thisStart < 0 || thisEnd > this.length) {
              throw new RangeError("out of range index");
            }

            if (thisStart >= thisEnd && start >= end) {
              return 0;
            }
            if (thisStart >= thisEnd) {
              return -1;
            }
            if (start >= end) {
              return 1;
            }

            start >>>= 0;
            end >>>= 0;
            thisStart >>>= 0;
            thisEnd >>>= 0;

            if (this === target) return 0;

            var x = thisEnd - thisStart;
            var y = end - start;
            var len = Math.min(x, y);

            var thisCopy = this.slice(thisStart, thisEnd);
            var targetCopy = target.slice(start, end);

            for (var i = 0; i < len; ++i) {
              if (thisCopy[i] !== targetCopy[i]) {
                x = thisCopy[i];
                y = targetCopy[i];
                break;
              }
            }

            if (x < y) return -1;
            if (y < x) return 1;
            return 0;
          };

          // Finds either the first index of `val` in `buffer` at offset >= `byteOffset`,
          // OR the last index of `val` in `buffer` at offset <= `byteOffset`.
          //
          // Arguments:
          // - buffer - a Buffer to search
          // - val - a string, Buffer, or number
          // - byteOffset - an index into `buffer`; will be clamped to an int32
          // - encoding - an optional encoding, relevant is val is a string
          // - dir - true for indexOf, false for lastIndexOf
          function bidirectionalIndexOf(buffer, val, byteOffset, encoding, dir) {
            // Empty buffer means no match
            if (buffer.length === 0) return -1;

            // Normalize byteOffset
            if (typeof byteOffset === "string") {
              encoding = byteOffset;
              byteOffset = 0;
            } else if (byteOffset > 0x7fffffff) {
              byteOffset = 0x7fffffff;
            } else if (byteOffset < -0x80000000) {
              byteOffset = -0x80000000;
            }
            byteOffset = +byteOffset; // Coerce to Number.
            if (numberIsNaN(byteOffset)) {
              // byteOffset: it it's undefined, null, NaN, "foo", etc, search whole buffer
              byteOffset = dir ? 0 : buffer.length - 1;
            }

            // Normalize byteOffset: negative offsets start from the end of the buffer
            if (byteOffset < 0) byteOffset = buffer.length + byteOffset;
            if (byteOffset >= buffer.length) {
              if (dir) return -1;
              else byteOffset = buffer.length - 1;
            } else if (byteOffset < 0) {
              if (dir) byteOffset = 0;
              else return -1;
            }

            // Normalize val
            if (typeof val === "string") {
              val = Buffer.from(val, encoding);
            }

            // Finally, search either indexOf (if dir is true) or lastIndexOf
            if (Buffer.isBuffer(val)) {
              // Special case: looking for empty string/buffer always fails
              if (val.length === 0) {
                return -1;
              }
              return arrayIndexOf(buffer, val, byteOffset, encoding, dir);
            } else if (typeof val === "number") {
              val = val & 0xff; // Search for a byte value [0-255]
              if (typeof Uint8Array.prototype.indexOf === "function") {
                if (dir) {
                  return Uint8Array.prototype.indexOf.call(buffer, val, byteOffset);
                } else {
                  return Uint8Array.prototype.lastIndexOf.call(buffer, val, byteOffset);
                }
              }
              return arrayIndexOf(buffer, [val], byteOffset, encoding, dir);
            }

            throw new TypeError("val must be string, number or Buffer");
          }

          function arrayIndexOf(arr, val, byteOffset, encoding, dir) {
            var indexSize = 1;
            var arrLength = arr.length;
            var valLength = val.length;

            if (encoding !== undefined) {
              encoding = String(encoding).toLowerCase();
              if (encoding === "ucs2" || encoding === "ucs-2" || encoding === "utf16le" || encoding === "utf-16le") {
                if (arr.length < 2 || val.length < 2) {
                  return -1;
                }
                indexSize = 2;
                arrLength /= 2;
                valLength /= 2;
                byteOffset /= 2;
              }
            }

            function read(buf, i) {
              if (indexSize === 1) {
                return buf[i];
              } else {
                return buf.readUInt16BE(i * indexSize);
              }
            }

            var i;
            if (dir) {
              var foundIndex = -1;
              for (i = byteOffset; i < arrLength; i++) {
                if (read(arr, i) === read(val, foundIndex === -1 ? 0 : i - foundIndex)) {
                  if (foundIndex === -1) foundIndex = i;
                  if (i - foundIndex + 1 === valLength) return foundIndex * indexSize;
                } else {
                  if (foundIndex !== -1) i -= i - foundIndex;
                  foundIndex = -1;
                }
              }
            } else {
              if (byteOffset + valLength > arrLength) byteOffset = arrLength - valLength;
              for (i = byteOffset; i >= 0; i--) {
                var found = true;
                for (var j = 0; j < valLength; j++) {
                  if (read(arr, i + j) !== read(val, j)) {
                    found = false;
                    break;
                  }
                }
                if (found) return i;
              }
            }

            return -1;
          }

          Buffer.prototype.includes = function includes(val, byteOffset, encoding) {
            return this.indexOf(val, byteOffset, encoding) !== -1;
          };

          Buffer.prototype.indexOf = function indexOf(val, byteOffset, encoding) {
            return bidirectionalIndexOf(this, val, byteOffset, encoding, true);
          };

          Buffer.prototype.lastIndexOf = function lastIndexOf(val, byteOffset, encoding) {
            return bidirectionalIndexOf(this, val, byteOffset, encoding, false);
          };

          function hexWrite(buf, string, offset, length) {
            offset = Number(offset) || 0;
            var remaining = buf.length - offset;
            if (!length) {
              length = remaining;
            } else {
              length = Number(length);
              if (length > remaining) {
                length = remaining;
              }
            }

            var strLen = string.length;

            if (length > strLen / 2) {
              length = strLen / 2;
            }
            for (var i = 0; i < length; ++i) {
              var parsed = parseInt(string.substr(i * 2, 2), 16);
              if (numberIsNaN(parsed)) return i;
              buf[offset + i] = parsed;
            }
            return i;
          }

          function utf8Write(buf, string, offset, length) {
            return blitBuffer(utf8ToBytes(string, buf.length - offset), buf, offset, length);
          }

          function asciiWrite(buf, string, offset, length) {
            return blitBuffer(asciiToBytes(string), buf, offset, length);
          }

          function latin1Write(buf, string, offset, length) {
            return asciiWrite(buf, string, offset, length);
          }

          function base64Write(buf, string, offset, length) {
            return blitBuffer(base64ToBytes(string), buf, offset, length);
          }

          function ucs2Write(buf, string, offset, length) {
            return blitBuffer(utf16leToBytes(string, buf.length - offset), buf, offset, length);
          }

          Buffer.prototype.write = function write(string, offset, length, encoding) {
            // Buffer#write(string)
            if (offset === undefined) {
              encoding = "utf8";
              length = this.length;
              offset = 0;
              // Buffer#write(string, encoding)
            } else if (length === undefined && typeof offset === "string") {
              encoding = offset;
              length = this.length;
              offset = 0;
              // Buffer#write(string, offset[, length][, encoding])
            } else if (isFinite(offset)) {
              offset = offset >>> 0;
              if (isFinite(length)) {
                length = length >>> 0;
                if (encoding === undefined) encoding = "utf8";
              } else {
                encoding = length;
                length = undefined;
              }
            } else {
              throw new Error("Buffer.write(string, encoding, offset[, length]) is no longer supported");
            }

            var remaining = this.length - offset;
            if (length === undefined || length > remaining) length = remaining;

            if ((string.length > 0 && (length < 0 || offset < 0)) || offset > this.length) {
              throw new RangeError("Attempt to write outside buffer bounds");
            }

            if (!encoding) encoding = "utf8";

            var loweredCase = false;
            for (;;) {
              switch (encoding) {
                case "hex":
                  return hexWrite(this, string, offset, length);

                case "utf8":
                case "utf-8":
                  return utf8Write(this, string, offset, length);

                case "ascii":
                  return asciiWrite(this, string, offset, length);

                case "latin1":
                case "binary":
                  return latin1Write(this, string, offset, length);

                case "base64":
                  // Warning: maxLength not taken into account in base64Write
                  return base64Write(this, string, offset, length);

                case "ucs2":
                case "ucs-2":
                case "utf16le":
                case "utf-16le":
                  return ucs2Write(this, string, offset, length);

                default:
                  if (loweredCase) throw new TypeError("Unknown encoding: " + encoding);
                  encoding = ("" + encoding).toLowerCase();
                  loweredCase = true;
              }
            }
          };

          Buffer.prototype.toJSON = function toJSON() {
            return {
              type: "Buffer",
              data: Array.prototype.slice.call(this._arr || this, 0),
            };
          };

          function base64Slice(buf, start, end) {
            if (start === 0 && end === buf.length) {
              return base64.fromByteArray(buf);
            } else {
              return base64.fromByteArray(buf.slice(start, end));
            }
          }

          function utf8Slice(buf, start, end) {
            end = Math.min(buf.length, end);
            var res = [];

            var i = start;
            while (i < end) {
              var firstByte = buf[i];
              var codePoint = null;
              var bytesPerSequence = firstByte > 0xef ? 4 : firstByte > 0xdf ? 3 : firstByte > 0xbf ? 2 : 1;

              if (i + bytesPerSequence <= end) {
                var secondByte, thirdByte, fourthByte, tempCodePoint;

                switch (bytesPerSequence) {
                  case 1:
                    if (firstByte < 0x80) {
                      codePoint = firstByte;
                    }
                    break;
                  case 2:
                    secondByte = buf[i + 1];
                    if ((secondByte & 0xc0) === 0x80) {
                      tempCodePoint = ((firstByte & 0x1f) << 0x6) | (secondByte & 0x3f);
                      if (tempCodePoint > 0x7f) {
                        codePoint = tempCodePoint;
                      }
                    }
                    break;
                  case 3:
                    secondByte = buf[i + 1];
                    thirdByte = buf[i + 2];
                    if ((secondByte & 0xc0) === 0x80 && (thirdByte & 0xc0) === 0x80) {
                      tempCodePoint = ((firstByte & 0xf) << 0xc) | ((secondByte & 0x3f) << 0x6) | (thirdByte & 0x3f);
                      if (tempCodePoint > 0x7ff && (tempCodePoint < 0xd800 || tempCodePoint > 0xdfff)) {
                        codePoint = tempCodePoint;
                      }
                    }
                    break;
                  case 4:
                    secondByte = buf[i + 1];
                    thirdByte = buf[i + 2];
                    fourthByte = buf[i + 3];
                    if ((secondByte & 0xc0) === 0x80 && (thirdByte & 0xc0) === 0x80 && (fourthByte & 0xc0) === 0x80) {
                      tempCodePoint =
                        ((firstByte & 0xf) << 0x12) |
                        ((secondByte & 0x3f) << 0xc) |
                        ((thirdByte & 0x3f) << 0x6) |
                        (fourthByte & 0x3f);
                      if (tempCodePoint > 0xffff && tempCodePoint < 0x110000) {
                        codePoint = tempCodePoint;
                      }
                    }
                }
              }

              if (codePoint === null) {
                // we did not generate a valid codePoint so insert a
                // replacement char (U+FFFD) and advance only 1 byte
                codePoint = 0xfffd;
                bytesPerSequence = 1;
              } else if (codePoint > 0xffff) {
                // encode to utf16 (surrogate pair dance)
                codePoint -= 0x10000;
                res.push(((codePoint >>> 10) & 0x3ff) | 0xd800);
                codePoint = 0xdc00 | (codePoint & 0x3ff);
              }

              res.push(codePoint);
              i += bytesPerSequence;
            }

            return decodeCodePointsArray(res);
          }

          // Based on http://stackoverflow.com/a/22747272/680742, the browser with
          // the lowest limit is Chrome, with 0x10000 args.
          // We go 1 magnitude less, for safety
          var MAX_ARGUMENTS_LENGTH = 0x1000;

          function decodeCodePointsArray(codePoints) {
            var len = codePoints.length;
            if (len <= MAX_ARGUMENTS_LENGTH) {
              return String.fromCharCode.apply(String, codePoints); // avoid extra slice()
            }

            // Decode in chunks to avoid "call stack size exceeded".
            var res = "";
            var i = 0;
            while (i < len) {
              res += String.fromCharCode.apply(String, codePoints.slice(i, (i += MAX_ARGUMENTS_LENGTH)));
            }
            return res;
          }

          function asciiSlice(buf, start, end) {
            var ret = "";
            end = Math.min(buf.length, end);

            for (var i = start; i < end; ++i) {
              ret += String.fromCharCode(buf[i] & 0x7f);
            }
            return ret;
          }

          function latin1Slice(buf, start, end) {
            var ret = "";
            end = Math.min(buf.length, end);

            for (var i = start; i < end; ++i) {
              ret += String.fromCharCode(buf[i]);
            }
            return ret;
          }

          function hexSlice(buf, start, end) {
            var len = buf.length;

            if (!start || start < 0) start = 0;
            if (!end || end < 0 || end > len) end = len;

            var out = "";
            for (var i = start; i < end; ++i) {
              out += hexSliceLookupTable[buf[i]];
            }
            return out;
          }

          function utf16leSlice(buf, start, end) {
            var bytes = buf.slice(start, end);
            var res = "";
            for (var i = 0; i < bytes.length; i += 2) {
              res += String.fromCharCode(bytes[i] + bytes[i + 1] * 256);
            }
            return res;
          }

          Buffer.prototype.slice = function slice(start, end) {
            var len = this.length;
            start = ~~start;
            end = end === undefined ? len : ~~end;

            if (start < 0) {
              start += len;
              if (start < 0) start = 0;
            } else if (start > len) {
              start = len;
            }

            if (end < 0) {
              end += len;
              if (end < 0) end = 0;
            } else if (end > len) {
              end = len;
            }

            if (end < start) end = start;

            var newBuf = this.subarray(start, end);
            // Return an augmented `Uint8Array` instance
            Object.setPrototypeOf(newBuf, Buffer.prototype);

            return newBuf;
          };

          /*
           * Need to make sure that buffer isn't trying to write out of bounds.
           */
          function checkOffset(offset, ext, length) {
            if (offset % 1 !== 0 || offset < 0) throw new RangeError("offset is not uint");
            if (offset + ext > length) throw new RangeError("Trying to access beyond buffer length");
          }

          Buffer.prototype.readUIntLE = function readUIntLE(offset, byteLength, noAssert) {
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) checkOffset(offset, byteLength, this.length);

            var val = this[offset];
            var mul = 1;
            var i = 0;
            while (++i < byteLength && (mul *= 0x100)) {
              val += this[offset + i] * mul;
            }

            return val;
          };

          Buffer.prototype.readUIntBE = function readUIntBE(offset, byteLength, noAssert) {
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) {
              checkOffset(offset, byteLength, this.length);
            }

            var val = this[offset + --byteLength];
            var mul = 1;
            while (byteLength > 0 && (mul *= 0x100)) {
              val += this[offset + --byteLength] * mul;
            }

            return val;
          };

          Buffer.prototype.readUInt8 = function readUInt8(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 1, this.length);
            return this[offset];
          };

          Buffer.prototype.readUInt16LE = function readUInt16LE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 2, this.length);
            return this[offset] | (this[offset + 1] << 8);
          };

          Buffer.prototype.readUInt16BE = function readUInt16BE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 2, this.length);
            return (this[offset] << 8) | this[offset + 1];
          };

          Buffer.prototype.readUInt32LE = function readUInt32LE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);

            return (this[offset] | (this[offset + 1] << 8) | (this[offset + 2] << 16)) + this[offset + 3] * 0x1000000;
          };

          Buffer.prototype.readUInt32BE = function readUInt32BE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);

            return this[offset] * 0x1000000 + ((this[offset + 1] << 16) | (this[offset + 2] << 8) | this[offset + 3]);
          };

          Buffer.prototype.readIntLE = function readIntLE(offset, byteLength, noAssert) {
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) checkOffset(offset, byteLength, this.length);

            var val = this[offset];
            var mul = 1;
            var i = 0;
            while (++i < byteLength && (mul *= 0x100)) {
              val += this[offset + i] * mul;
            }
            mul *= 0x80;

            if (val >= mul) val -= Math.pow(2, 8 * byteLength);

            return val;
          };

          Buffer.prototype.readIntBE = function readIntBE(offset, byteLength, noAssert) {
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) checkOffset(offset, byteLength, this.length);

            var i = byteLength;
            var mul = 1;
            var val = this[offset + --i];
            while (i > 0 && (mul *= 0x100)) {
              val += this[offset + --i] * mul;
            }
            mul *= 0x80;

            if (val >= mul) val -= Math.pow(2, 8 * byteLength);

            return val;
          };

          Buffer.prototype.readInt8 = function readInt8(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 1, this.length);
            if (!(this[offset] & 0x80)) return this[offset];
            return (0xff - this[offset] + 1) * -1;
          };

          Buffer.prototype.readInt16LE = function readInt16LE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 2, this.length);
            var val = this[offset] | (this[offset + 1] << 8);
            return val & 0x8000 ? val | 0xffff0000 : val;
          };

          Buffer.prototype.readInt16BE = function readInt16BE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 2, this.length);
            var val = this[offset + 1] | (this[offset] << 8);
            return val & 0x8000 ? val | 0xffff0000 : val;
          };

          Buffer.prototype.readInt32LE = function readInt32LE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);

            return this[offset] | (this[offset + 1] << 8) | (this[offset + 2] << 16) | (this[offset + 3] << 24);
          };

          Buffer.prototype.readInt32BE = function readInt32BE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);

            return (this[offset] << 24) | (this[offset + 1] << 16) | (this[offset + 2] << 8) | this[offset + 3];
          };

          Buffer.prototype.readFloatLE = function readFloatLE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);
            return ieee754.read(this, offset, true, 23, 4);
          };

          Buffer.prototype.readFloatBE = function readFloatBE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 4, this.length);
            return ieee754.read(this, offset, false, 23, 4);
          };

          Buffer.prototype.readDoubleLE = function readDoubleLE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 8, this.length);
            return ieee754.read(this, offset, true, 52, 8);
          };

          Buffer.prototype.readDoubleBE = function readDoubleBE(offset, noAssert) {
            offset = offset >>> 0;
            if (!noAssert) checkOffset(offset, 8, this.length);
            return ieee754.read(this, offset, false, 52, 8);
          };

          function checkInt(buf, value, offset, ext, max, min) {
            if (!Buffer.isBuffer(buf)) throw new TypeError('"buffer" argument must be a Buffer instance');
            if (value > max || value < min) throw new RangeError('"value" argument is out of bounds');
            if (offset + ext > buf.length) throw new RangeError("Index out of range");
          }

          Buffer.prototype.writeUIntLE = function writeUIntLE(value, offset, byteLength, noAssert) {
            value = +value;
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) {
              var maxBytes = Math.pow(2, 8 * byteLength) - 1;
              checkInt(this, value, offset, byteLength, maxBytes, 0);
            }

            var mul = 1;
            var i = 0;
            this[offset] = value & 0xff;
            while (++i < byteLength && (mul *= 0x100)) {
              this[offset + i] = (value / mul) & 0xff;
            }

            return offset + byteLength;
          };

          Buffer.prototype.writeUIntBE = function writeUIntBE(value, offset, byteLength, noAssert) {
            value = +value;
            offset = offset >>> 0;
            byteLength = byteLength >>> 0;
            if (!noAssert) {
              var maxBytes = Math.pow(2, 8 * byteLength) - 1;
              checkInt(this, value, offset, byteLength, maxBytes, 0);
            }

            var i = byteLength - 1;
            var mul = 1;
            this[offset + i] = value & 0xff;
            while (--i >= 0 && (mul *= 0x100)) {
              this[offset + i] = (value / mul) & 0xff;
            }

            return offset + byteLength;
          };

          Buffer.prototype.writeUInt8 = function writeUInt8(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 1, 0xff, 0);
            this[offset] = value & 0xff;
            return offset + 1;
          };

          Buffer.prototype.writeUInt16LE = function writeUInt16LE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0);
            this[offset] = value & 0xff;
            this[offset + 1] = value >>> 8;
            return offset + 2;
          };

          Buffer.prototype.writeUInt16BE = function writeUInt16BE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 2, 0xffff, 0);
            this[offset] = value >>> 8;
            this[offset + 1] = value & 0xff;
            return offset + 2;
          };

          Buffer.prototype.writeUInt32LE = function writeUInt32LE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0);
            this[offset + 3] = value >>> 24;
            this[offset + 2] = value >>> 16;
            this[offset + 1] = value >>> 8;
            this[offset] = value & 0xff;
            return offset + 4;
          };

          Buffer.prototype.writeUInt32BE = function writeUInt32BE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 4, 0xffffffff, 0);
            this[offset] = value >>> 24;
            this[offset + 1] = value >>> 16;
            this[offset + 2] = value >>> 8;
            this[offset + 3] = value & 0xff;
            return offset + 4;
          };

          Buffer.prototype.writeIntLE = function writeIntLE(value, offset, byteLength, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) {
              var limit = Math.pow(2, 8 * byteLength - 1);

              checkInt(this, value, offset, byteLength, limit - 1, -limit);
            }

            var i = 0;
            var mul = 1;
            var sub = 0;
            this[offset] = value & 0xff;
            while (++i < byteLength && (mul *= 0x100)) {
              if (value < 0 && sub === 0 && this[offset + i - 1] !== 0) {
                sub = 1;
              }
              this[offset + i] = (((value / mul) >> 0) - sub) & 0xff;
            }

            return offset + byteLength;
          };

          Buffer.prototype.writeIntBE = function writeIntBE(value, offset, byteLength, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) {
              var limit = Math.pow(2, 8 * byteLength - 1);

              checkInt(this, value, offset, byteLength, limit - 1, -limit);
            }

            var i = byteLength - 1;
            var mul = 1;
            var sub = 0;
            this[offset + i] = value & 0xff;
            while (--i >= 0 && (mul *= 0x100)) {
              if (value < 0 && sub === 0 && this[offset + i + 1] !== 0) {
                sub = 1;
              }
              this[offset + i] = (((value / mul) >> 0) - sub) & 0xff;
            }

            return offset + byteLength;
          };

          Buffer.prototype.writeInt8 = function writeInt8(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 1, 0x7f, -0x80);
            if (value < 0) value = 0xff + value + 1;
            this[offset] = value & 0xff;
            return offset + 1;
          };

          Buffer.prototype.writeInt16LE = function writeInt16LE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000);
            this[offset] = value & 0xff;
            this[offset + 1] = value >>> 8;
            return offset + 2;
          };

          Buffer.prototype.writeInt16BE = function writeInt16BE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 2, 0x7fff, -0x8000);
            this[offset] = value >>> 8;
            this[offset + 1] = value & 0xff;
            return offset + 2;
          };

          Buffer.prototype.writeInt32LE = function writeInt32LE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000);
            this[offset] = value & 0xff;
            this[offset + 1] = value >>> 8;
            this[offset + 2] = value >>> 16;
            this[offset + 3] = value >>> 24;
            return offset + 4;
          };

          Buffer.prototype.writeInt32BE = function writeInt32BE(value, offset, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) checkInt(this, value, offset, 4, 0x7fffffff, -0x80000000);
            if (value < 0) value = 0xffffffff + value + 1;
            this[offset] = value >>> 24;
            this[offset + 1] = value >>> 16;
            this[offset + 2] = value >>> 8;
            this[offset + 3] = value & 0xff;
            return offset + 4;
          };

          function checkIEEE754(buf, value, offset, ext, max, min) {
            if (offset + ext > buf.length) throw new RangeError("Index out of range");
            if (offset < 0) throw new RangeError("Index out of range");
          }

          function writeFloat(buf, value, offset, littleEndian, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) {
              checkIEEE754(buf, value, offset, 4, 3.4028234663852886e38, -3.4028234663852886e38);
            }
            ieee754.write(buf, value, offset, littleEndian, 23, 4);
            return offset + 4;
          }

          Buffer.prototype.writeFloatLE = function writeFloatLE(value, offset, noAssert) {
            return writeFloat(this, value, offset, true, noAssert);
          };

          Buffer.prototype.writeFloatBE = function writeFloatBE(value, offset, noAssert) {
            return writeFloat(this, value, offset, false, noAssert);
          };

          function writeDouble(buf, value, offset, littleEndian, noAssert) {
            value = +value;
            offset = offset >>> 0;
            if (!noAssert) {
              checkIEEE754(buf, value, offset, 8, 1.7976931348623157e308, -1.7976931348623157e308);
            }
            ieee754.write(buf, value, offset, littleEndian, 52, 8);
            return offset + 8;
          }

          Buffer.prototype.writeDoubleLE = function writeDoubleLE(value, offset, noAssert) {
            return writeDouble(this, value, offset, true, noAssert);
          };

          Buffer.prototype.writeDoubleBE = function writeDoubleBE(value, offset, noAssert) {
            return writeDouble(this, value, offset, false, noAssert);
          };

          // copy(targetBuffer, targetStart=0, sourceStart=0, sourceEnd=buffer.length)
          Buffer.prototype.copy = function copy(target, targetStart, start, end) {
            if (!Buffer.isBuffer(target)) throw new TypeError("argument should be a Buffer");
            if (!start) start = 0;
            if (!end && end !== 0) end = this.length;
            if (targetStart >= target.length) targetStart = target.length;
            if (!targetStart) targetStart = 0;
            if (end > 0 && end < start) end = start;

            // Copy 0 bytes; we're done
            if (end === start) return 0;
            if (target.length === 0 || this.length === 0) return 0;

            // Fatal error conditions
            if (targetStart < 0) {
              throw new RangeError("targetStart out of bounds");
            }
            if (start < 0 || start >= this.length) throw new RangeError("Index out of range");
            if (end < 0) throw new RangeError("sourceEnd out of bounds");

            // Are we oob?
            if (end > this.length) end = this.length;
            if (target.length - targetStart < end - start) {
              end = target.length - targetStart + start;
            }

            var len = end - start;

            if (this === target && typeof Uint8Array.prototype.copyWithin === "function") {
              // Use built-in when available, missing from IE11
              this.copyWithin(targetStart, start, end);
            } else if (this === target && start < targetStart && targetStart < end) {
              // descending copy from end
              for (var i = len - 1; i >= 0; --i) {
                target[i + targetStart] = this[i + start];
              }
            } else {
              Uint8Array.prototype.set.call(target, this.subarray(start, end), targetStart);
            }

            return len;
          };

          // Usage:
          //    buffer.fill(number[, offset[, end]])
          //    buffer.fill(buffer[, offset[, end]])
          //    buffer.fill(string[, offset[, end]][, encoding])
          Buffer.prototype.fill = function fill(val, start, end, encoding) {
            // Handle string cases:
            if (typeof val === "string") {
              if (typeof start === "string") {
                encoding = start;
                start = 0;
                end = this.length;
              } else if (typeof end === "string") {
                encoding = end;
                end = this.length;
              }
              if (encoding !== undefined && typeof encoding !== "string") {
                throw new TypeError("encoding must be a string");
              }
              if (typeof encoding === "string" && !Buffer.isEncoding(encoding)) {
                throw new TypeError("Unknown encoding: " + encoding);
              }
              if (val.length === 1) {
                var code = val.charCodeAt(0);
                if ((encoding === "utf8" && code < 128) || encoding === "latin1") {
                  // Fast path: If `val` fits into a single byte, use that numeric value.
                  val = code;
                }
              }
            } else if (typeof val === "number") {
              val = val & 255;
            } else if (typeof val === "boolean") {
              val = Number(val);
            }

            // Invalid ranges are not set to a default, so can range check early.
            if (start < 0 || this.length < start || this.length < end) {
              throw new RangeError("Out of range index");
            }

            if (end <= start) {
              return this;
            }

            start = start >>> 0;
            end = end === undefined ? this.length : end >>> 0;

            if (!val) val = 0;

            var i;
            if (typeof val === "number") {
              for (i = start; i < end; ++i) {
                this[i] = val;
              }
            } else {
              var bytes = Buffer.isBuffer(val) ? val : Buffer.from(val, encoding);
              var len = bytes.length;
              if (len === 0) {
                throw new TypeError('The value "' + val + '" is invalid for argument "value"');
              }
              for (i = 0; i < end - start; ++i) {
                this[i + start] = bytes[i % len];
              }
            }

            return this;
          };

          // HELPER FUNCTIONS
          // ================

          var INVALID_BASE64_RE = /[^+/0-9A-Za-z-_]/g;

          function base64clean(str) {
            // Node takes equal signs as end of the Base64 encoding
            str = str.split("=")[0];
            // Node strips out invalid characters like \n and \t from the string, base64-js does not
            str = str.trim().replace(INVALID_BASE64_RE, "");
            // Node converts strings with length < 2 to ''
            if (str.length < 2) return "";
            // Node allows for non-padded base64 strings (missing trailing ===), base64-js does not
            while (str.length % 4 !== 0) {
              str = str + "=";
            }
            return str;
          }

          function utf8ToBytes(string, units) {
            units = units || Infinity;
            var codePoint;
            var length = string.length;
            var leadSurrogate = null;
            var bytes = [];

            for (var i = 0; i < length; ++i) {
              codePoint = string.charCodeAt(i);

              // is surrogate component
              if (codePoint > 0xd7ff && codePoint < 0xe000) {
                // last char was a lead
                if (!leadSurrogate) {
                  // no lead yet
                  if (codePoint > 0xdbff) {
                    // unexpected trail
                    if ((units -= 3) > -1) bytes.push(0xef, 0xbf, 0xbd);
                    continue;
                  } else if (i + 1 === length) {
                    // unpaired lead
                    if ((units -= 3) > -1) bytes.push(0xef, 0xbf, 0xbd);
                    continue;
                  }

                  // valid lead
                  leadSurrogate = codePoint;

                  continue;
                }

                // 2 leads in a row
                if (codePoint < 0xdc00) {
                  if ((units -= 3) > -1) bytes.push(0xef, 0xbf, 0xbd);
                  leadSurrogate = codePoint;
                  continue;
                }

                // valid surrogate pair
                codePoint = (((leadSurrogate - 0xd800) << 10) | (codePoint - 0xdc00)) + 0x10000;
              } else if (leadSurrogate) {
                // valid bmp char, but last char was a lead
                if ((units -= 3) > -1) bytes.push(0xef, 0xbf, 0xbd);
              }

              leadSurrogate = null;

              // encode utf8
              if (codePoint < 0x80) {
                if ((units -= 1) < 0) break;
                bytes.push(codePoint);
              } else if (codePoint < 0x800) {
                if ((units -= 2) < 0) break;
                bytes.push((codePoint >> 0x6) | 0xc0, (codePoint & 0x3f) | 0x80);
              } else if (codePoint < 0x10000) {
                if ((units -= 3) < 0) break;
                bytes.push((codePoint >> 0xc) | 0xe0, ((codePoint >> 0x6) & 0x3f) | 0x80, (codePoint & 0x3f) | 0x80);
              } else if (codePoint < 0x110000) {
                if ((units -= 4) < 0) break;
                bytes.push(
                  (codePoint >> 0x12) | 0xf0,
                  ((codePoint >> 0xc) & 0x3f) | 0x80,
                  ((codePoint >> 0x6) & 0x3f) | 0x80,
                  (codePoint & 0x3f) | 0x80
                );
              } else {
                throw new Error("Invalid code point");
              }
            }

            return bytes;
          }

          function asciiToBytes(str) {
            var byteArray = [];
            for (var i = 0; i < str.length; ++i) {
              // Node's code seems to be doing this and not & 0x7F..
              byteArray.push(str.charCodeAt(i) & 0xff);
            }
            return byteArray;
          }

          function utf16leToBytes(str, units) {
            var c, hi, lo;
            var byteArray = [];
            for (var i = 0; i < str.length; ++i) {
              if ((units -= 2) < 0) break;

              c = str.charCodeAt(i);
              hi = c >> 8;
              lo = c % 256;
              byteArray.push(lo);
              byteArray.push(hi);
            }

            return byteArray;
          }

          function base64ToBytes(str) {
            return base64.toByteArray(base64clean(str));
          }

          function blitBuffer(src, dst, offset, length) {
            for (var i = 0; i < length; ++i) {
              if (i + offset >= dst.length || i >= src.length) break;
              dst[i + offset] = src[i];
            }
            return i;
          }

          // ArrayBuffer or Uint8Array objects from other contexts (i.e. iframes) do not pass
          // the `instanceof` check but they should be treated as of that type.
          // See: https://github.com/feross/buffer/issues/166
          function isInstance(obj, type) {
            return (
              obj instanceof type ||
              (obj != null &&
                obj.constructor != null &&
                obj.constructor.name != null &&
                obj.constructor.name === type.name)
            );
          }
          function numberIsNaN(obj) {
            // For IE11 support
            return obj !== obj; // eslint-disable-line no-self-compare
          }

          // Create lookup table for `toString('hex')`
          // See: https://github.com/feross/buffer/issues/219
          var hexSliceLookupTable = (function () {
            var alphabet = "0123456789abcdef";
            var table = new Array(256);
            for (var i = 0; i < 16; ++i) {
              var i16 = i * 16;
              for (var j = 0; j < 16; ++j) {
                table[i16 + j] = alphabet[i] + alphabet[j];
              }
            }
            return table;
          })();
        }).call(this, require("buffer").Buffer);
      },
      { "base64-js": 14, buffer: "buffer", ieee754: 15 },
    ],
    wkx: [
      function (require, module, exports) {
        exports.Types = require("./types");
        exports.Geometry = require("./geometry");
        exports.Point = require("./point");
        exports.LineString = require("./linestring");
        exports.Polygon = require("./polygon");
        exports.MultiPoint = require("./multipoint");
        exports.MultiLineString = require("./multilinestring");
        exports.MultiPolygon = require("./multipolygon");
        exports.GeometryCollection = require("./geometrycollection");
      },
      {
        "./geometry": 3,
        "./geometrycollection": 4,
        "./linestring": 5,
        "./multilinestring": 6,
        "./multipoint": 7,
        "./multipolygon": 8,
        "./point": 9,
        "./polygon": 10,
        "./types": 11,
      },
    ],
  },
  {},
  ["wkx"]
);

export const wkx = requireWkx("wkx");
export const Buffer = requireWkx("buffer").Buffer;
