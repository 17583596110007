import { A } from "@solidjs/router";
import { LocaleSelectorDropdown } from "components";
import { ct } from "components";
import { Component } from "solid-js";
import { myStaff, signOut } from "supabase-client";

type Props = {
  onLogout?: () => Promise<boolean>;
};

const UserInfoC: Component<Props> = (props) => {
  return (
    <>
      <div class="d-flex gap-2 align-items-center justify-content-between">
        <A class="btn btn-light" href="/user">
          <i class="bi-gear me-2" />
          {myStaff()?.first_name} {myStaff()?.last_name}
        </A>
        <LocaleSelectorDropdown />
        <button
          onclick={async () => {
            if (!props.onLogout || (await props.onLogout())) {
              await signOut();
            }
          }}
          class="btn btn-primary"
        >
          <i class="bi-box-arrow-right me-2" />
          {ct.account.logout()}
        </button>
      </div>
    </>
  );
};

export default UserInfoC;
