import { captureException } from "@sentry/browser";
import { ct } from "components";
import { MyDate, MyTime, MyTimestamp } from "helpers";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, Show } from "solid-js";
import { currentTransportcompanyId } from "supabase-client";
import { routes, setPrice, setRoute } from "../../services/state/newpricelist.state";

type Props = {
  origin: API.google_place_details.ResponseBody;
  destination: API.google_place_details.ResponseBody;
  dateGetter: () => MyDate | undefined;
  timeGetter: () => MyTime | undefined;
};

export async function calculateRoute(
  origin: API.google_place_details.ResponseBody,
  destination: API.google_place_details.ResponseBody,
  timestamp: MyTimestamp
) {
  const coordinates = [origin, destination].map((place) => API.PlaceAdapter.getCoordinates(place));
  try {
    const result = await mooovexApiClient.route.compute(coordinates, timestamp, currentTransportcompanyId());
    setRoute(API.PlaceAdapter.getId(origin), API.PlaceAdapter.getId(destination), result.id);
  } catch (error) {
    captureException(error);
    alert(ct["ride.route.calculation.error"]());
  }
}

export async function fetchPrice(routeId: string, passengerCount: number, discount: number, timestamp: MyTimestamp) {
  try {
    const price = await mooovexApiClient.price.compute({
      routeId,
      passengerCount,
      discount,
      timestamp,
      transportcompanyId: currentTransportcompanyId(),
    });

    return price;
  } catch (error) {
    captureException(error);
    alert(ct["price.calculation.error"]());
  }
}

async function calculatePrice(
  origin: API.google_place_details.ResponseBody,
  destination: API.google_place_details.ResponseBody,
  timestamp: MyTimestamp
) {
  const originId = API.PlaceAdapter.getId(origin);
  const destinationId = API.PlaceAdapter.getId(destination);
  const routeId = routes().find(
    (route) => route.originId === originId && route.destinationId === destinationId
  )?.routeId;
  if (routeId) {
    const priceResult = await fetchPrice(routeId, 1, 0, timestamp);
    if (priceResult) {
      setPrice(originId, destinationId, priceResult.price, priceResult.extraPersonPrice);
    }
  }
}

export const RouteTD: Component<Props> = (props) => {
  const route = () =>
    routes().find(
      (route) =>
        route.originId === API.PlaceAdapter.getId(props.origin) &&
        route.destinationId === API.PlaceAdapter.getId(props.destination)
    );

  return (
    <Show
      when={route()}
      fallback={
        <td>
          <button
            class="btn btn-sm btn-border-dark"
            onclick={() => calculateRoute(props.origin, props.destination, MyTimestamp.now())}
          >
            <i class="bi-cpu" />
          </button>
          &nbsp;<span>No Route</span>
        </td>
      }
    >
      <Show
        when={route()!.price}
        fallback={
          <td>
            <button
              class="btn btn-sm btn-border-dark"
              onclick={() =>
                calculatePrice(
                  props.origin,
                  props.destination,
                  MyTimestamp.fromMyDateAndMyTime(
                    props.dateGetter() ?? MyDate.today(),
                    props.timeGetter() ?? MyTime.now()
                  )
                )
              }
            >
              <i class="bi-cpu" />
            </button>
            &nbsp;<span>No Price</span>
          </td>
        }
      >
        {(price) => (
          <td>
            Price: {price().full} € / + {price().person} €
          </td>
        )}
      </Show>
    </Show>
  );
};

export default RouteTD;
