import { ct } from "components";
import { Component, For, Show } from "solid-js";
import { Tables } from "supabase";
import { staffCache, vehiclesCache, zipcodesCache } from "supabase-client";
import classes from "./ClientDriverlist.module.scss";
type Props = {
  onDriverClick?: (driver_id: string) => void;
  liveLocations: Tables<"live_locations">[];
};

export const ClientDriverlist: Component<Props> = (props) => {
  const zipcodes = () =>
    Array.from(
      new Set(
        Array.from(vehiclesCache.values())
          .filter((v) => props.liveLocations.some((l) => l.current_vehicle_id === v.id))
          .map((v) => zipcodesCache.get(v.zipcode_id))
          .sort((a, b) => parseInt(a?.zipcode ?? "") - parseInt(b?.zipcode ?? ""))
      )
    );

  return (
    <table class={classes.table}>
      <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
        {(zipcode) => {
          const zipcodeDrivers = () =>
            props.liveLocations
              .filter((liveLocation) => vehiclesCache.get(liveLocation.current_vehicle_id!)?.zipcode_id === zipcode?.id)
              .map((d) => ({
                ...d,
                vehicle: vehiclesCache.get(d.current_vehicle_id!),
                staff: staffCache.get(d.driver_id),
              }))
              .sort((a, b) => parseInt(a.vehicle?.identifier ?? "") - parseInt(b.vehicle?.identifier ?? ""));

          return (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th class={classes.municipiality}>{zipcode?.municipiality ?? ct["driverlist.noZipcode"]()}</th>
                  <th class="text-center">
                    <i class="bi-people-fill" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <For each={zipcodeDrivers()}>
                  {(driver) => {
                    return (
                      <>
                        <tr class="cursor-pointer align-middle">
                          <td onclick={() => props.onDriverClick?.(driver.driver_id)}>
                            <div class={classes.badge} classList={{ [classes[driver.status ?? "outofservice"]]: true }}>
                              {driver.vehicle?.identifier}
                            </div>
                          </td>
                          <td
                            class={classes.name}
                            title={`${driver.staff?.first_name} ${driver.staff?.last_name}`}
                            onclick={() => props.onDriverClick?.(driver.driver_id)}
                          >
                            {driver.staff?.first_name} {driver.staff?.last_name}
                          </td>
                          <td class={classes.seats} onclick={() => props.onDriverClick?.(driver.driver_id)}>
                            {driver.vehicle?.seats}
                          </td>
                          <td>
                            <Show when={driver.staff?.phone}>
                              {(phone) => (
                                <i
                                  class="bi-telephone-fill text-primary cursor-pointer"
                                  title={`${phone()} (${ct["common.rightClickToCopy"]()})`}
                                  onclick={() => window.open(`tel:${phone()}`)}
                                  oncontextmenu={async (e) => {
                                    e.preventDefault();
                                    await navigator.clipboard.writeText(phone());
                                  }}
                                />
                              )}
                            </Show>
                          </td>
                        </tr>
                      </>
                    );
                  }}
                </For>
              </tbody>
            </>
          );
        }}
      </For>
    </table>
  );
};
