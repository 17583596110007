import { responseBody as placeResponseBody } from "./google.place_details.api.schema";
import { z } from "./openapizod";
import * as Shared from "./shared.api.schema";

export const responseBody = placeResponseBody;

export type ResponseBody = z.infer<typeof responseBody>;

export const requestBody = z.object({
  coordinates: Shared.GeoJson.coordinates,
  language: Shared.locale,
});

export type RequestBody = z.infer<typeof requestBody>;
