import type * as API from "mooovex-api-schema";
import { Component } from "solid-js";
import { MapFeatureCircles } from "./MapFeatureCircles";
import { MapFeatureSymbols } from "./MapFeatureSymbols";

type Props = {
  features: API.Shared.GeoJson.Feature[];
};

export const MapTaxistations: Component<Props> = (props) => {
  return (
    <>
      <MapFeatureCircles
        features={props.features}
        minzoom={13}
        opacity={0.1}
        propertyKeys={{
          disabled: "disabled",
          color: "color",
          opacity: "circleOpacity",
        }}
        radius={15}
      />
      <MapFeatureSymbols
        features={props.features}
        propertyKeys={{ text: "name", disabled: "disabled", opacity: "textOpacity" }}
        minzoom={13}
      />
    </>
  );
};
