export * from "./MapComponent";
export * from "./MapControlWrapper";
export * from "./MapZoomControl";
export * from "./MapPlaceMarker";
export * from "./MapLineString";
export * from "./MapControlGroup";
export * from "./MapDriverMarker";
export * from "./MapFeatureCircles";
export * from "./MapFeatureSymbols";
export * from "./MapTaxistations";
export * from "./MapAnimations";

import { useContext } from "solid-js";
import { MapContext } from "./MapProvider";
export function useMapContext() {
  const context = useContext(MapContext);
  if (!context) throw new Error("You didn't use useMapContext inside a MapProvider");
  return context;
}
