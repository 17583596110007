import { Component, Show } from "solid-js";
import { currentLocale } from "../i18n";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  disabled?: boolean;
};

export const StartTimeButton: Component<Props> = (props) => {
  const {
    emitter,
    startTimeSignal: [startTime],
  } = useRideFormContext();

  const formatter = () => Intl.DateTimeFormat(currentLocale(), { hour: "2-digit", minute: "2-digit" });

  return (
    <button
      disabled={props.disabled}
      class="btn btn-lg flex-grow-1 flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !startTime(),
        "btn-primary": !!startTime(),
      }}
      onclick={() => emitter.emit("startTimePicker.show", startTime())}
    >
      <i class="bi-clock" />
      <Show when={startTime()}>
        {(time) => <span class="ms-1">{formatter().format(time().getMyTimestamp().toDate())}</span>}
      </Show>
    </button>
  );
};
