import { ClientResponse, hc } from "hono/client";
import type { HonoRoutes } from "../../../apps/server/src";

export const client = hc<HonoRoutes>(import.meta.env.VITE_PRICING_SERVER_URL!, {
  headers: {
    Authorization: import.meta.env.VITE_PRICING_API_KEY,
  },
});

export class ApiClientError<T> extends Error {
  public status: number;
  public statusText: string;
  constructor(message: string, response: ClientResponse<T>) {
    super(message, { cause: response });
    this.status = response.status;
    this.statusText = response.statusText;
  }
}
