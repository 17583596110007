import { createTimeDifferenceFromNow } from "@solid-primitives/date";
import { Component, Show } from "solid-js";
import { liveLocationsCache, staffCache, vehiclesCache } from "supabase-client";
import { F } from "../formatters";
import { ct } from "../i18n";
import { IdentifierBadge, LicensePlate } from "../vehicles";

type Props = {
  driver_id: string;
};

export const MapDriverMarkerPopup: Component<Props> = (props) => {
  const liveLocation = () => liveLocationsCache.get(props.driver_id);
  const vehicle = () => vehiclesCache.get(liveLocation()?.current_vehicle_id!);
  const staff = () => staffCache.get(props.driver_id);

  const formattedSpeed = () => F.kmh((liveLocation()?.speed ?? 0) * 3.6);

  const timestampMs = () => (liveLocation()?.timestamp ? Date.parse(liveLocation()?.timestamp!) : Date.now());
  const [timestampDiffMs] = createTimeDifferenceFromNow(timestampMs, 1000);
  const formattedRelativeTime = () => F.relativeTime(timestampDiffMs());

  return (
    <div class="fs-6 d-flex flex-column gap-1 placeholder-glow">
      <div class="d-flex align-items-center justify-content-between gap-2 nowrap">
        <IdentifierBadge value={vehicle()?.identifier} status={liveLocation()?.status ?? undefined} />
        <Show when={staff()} fallback={<div class="placeholder flex-grow-1" />}>
          <div class="fw-bold text-truncate">
            {staff()?.first_name} {staff()?.last_name}
          </div>
          <Show when={staff()?.phone}>
            {(phone) => (
              <button
                class="btn btn-light btn-sm"
                title={`${phone()} (${ct["common.rightClickToCopy"]()})`}
                onclick={() => window.open(`tel:${phone()}`)}
                oncontextmenu={async (e) => {
                  e.preventDefault();
                  await navigator.clipboard.writeText(phone());
                }}
              >
                <i class="bi-telephone-fill text-primary" />
              </button>
            )}
          </Show>
        </Show>
      </div>
      <Show when={vehicle()} fallback={<div class="placeholder flex-grow-1" />}>
        <div class="d-flex justify-content-between align-items-center gap-2 nowrap">
          <div class="text-truncate">{vehicle()?.model}</div>
          <LicensePlate value={vehicle()?.license_plate ?? undefined} />
        </div>
      </Show>
      <div class="d-flex justify-content-between gap-2 nowrap">
        <div>{formattedSpeed()}</div>
        <div class="text-muted">{formattedRelativeTime()}</div>
      </div>
    </div>
  );
};
