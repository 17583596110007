import * as i18n from "@solid-primitives/i18n";
import hd, { HumanizerOptions } from "humanize-duration";
import { dict as de } from "./de";
import { Dict, dict as en } from "./en";
import { dict as it } from "./it";
export * from "@solid-primitives/i18n";

export const dictionaries = { en, de, it };
export let CURRENT_LOCALE: Locale = "en";
export const SUPPORTED_LOCALES = ["en", "de", "it"] as const;
export type Locale = (typeof SUPPORTED_LOCALES)[number];
export type Dictionary = i18n.Flatten<Dict>;

export function d(locale?: Locale) {
  return dictionaries[locale ?? CURRENT_LOCALE];
}

export function setBaseLocale(locale: Locale) {
  CURRENT_LOCALE = locale;
}

export namespace BaseF {
  export function kmh(kmh: number, locale: Locale) {
    return new Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits: 1,
      unit: "kilometer-per-hour",
    }).format(kmh);
  }

  export function mps(mps: number, locale: Locale) {
    return new Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits: 1,
      unit: "meter-per-second",
    }).format(mps);
  }

  export function humanDuration(ms: number, locale: Locale, options?: HumanizerOptions) {
    return hd(ms, { units: ["d", "h", "m"], largest: 2, round: true, language: locale, ...options });
  }

  export function duration(ms: number, locale: Locale) {
    let seconds = Math.floor(ms / 1000);
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return [hours, minutes, seconds].map(padZero).join(":");
  }

  function padZero(num: number) {
    return (num < 10 ? "0" : "") + num;
  }

  export function kilometers(km: number, locale: Locale) {
    return new Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits: 1,
      unit: "kilometer",
    }).format(km);
  }

  export function meters(m: number, locale: Locale) {
    return new Intl.NumberFormat(locale, {
      style: "unit",
      maximumFractionDigits: 1,
      unit: "meter",
    }).format(m);
  }

  export function euros(e: number, locale: Locale) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "EUR",
    }).format(e);
  }

  export function relativeTime(ms: number, locale: Locale) {
    const seconds = ms < 0 ? Math.ceil(ms / 1000) : Math.floor(ms / 1000);
    const minutes = ms < 0 ? Math.ceil(seconds / 60) : Math.floor(seconds / 60);
    const hours = ms < 0 ? Math.ceil(minutes / 60) : Math.floor(minutes / 60);
    const days = ms < 0 ? Math.ceil(hours / 24) : Math.floor(hours / 24);

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto", style: "narrow" });

    if (days !== 0) {
      return rtf.format(days, "day");
    } else if (hours !== 0) {
      return rtf.format(hours, "hour");
    } else if (minutes !== 0) {
      return rtf.format(minutes, "minute");
    } else {
      return rtf.format(seconds, "second");
    }
  }
}
