import { ParentComponent } from "solid-js";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export const MapControlGroup: ParentComponent<Props> = (props) => {
  return (
    <div
      class="d-flex flex-column gap-2"
      style={{
        position: "absolute",
        top: props.top && props.top + "px",
        right: props.right && props.right + "px",
        bottom: props.bottom && props.bottom + "px",
        left: props.left && props.left + "px",
      }}
    >
      {props.children}
    </div>
  );
};
