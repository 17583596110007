/* @refresh reload */
import "./scss/styles.scss";

import * as Sentry from "@sentry/browser";
import { DEV } from "solid-js";
import { render } from "solid-js/web";
import { initSupabase } from "supabase-client";
import App from "./App";

if (!DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

initSupabase(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_KEY);

const dispose = render(() => <App />, document.getElementById("root") as HTMLElement);

if (import.meta.hot) {
  import.meta.hot.dispose(dispose);
}
