import * as ML from "maplibre-gl";
import { JSX, ParentComponent, Show, createSignal, onCleanup, onMount, splitProps } from "solid-js";
import { MapProvider } from "./MapProvider";

type Props = JSX.HTMLAttributes<HTMLDivElement> & {
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

export const MapComponent: ParentComponent<Props> = (props) => {
  let mapElementRef!: HTMLDivElement;
  let map!: ML.Map;

  const [mapLoaded, setMapLoaded] = createSignal(false);

  const [parentProps, myProps, divProps] = splitProps(
    props,
    ["children"],
    ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"]
  );

  onMount(async () => {
    map = new ML.Map({
      container: mapElementRef,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${import.meta.env.VITE_MAPTILER_API_KEY}`,
      attributionControl: false,
      center: [11, 46],
      zoom: 7,
    });

    map.touchZoomRotate.disableRotation();
    map.touchPitch.disable();

    await map.once("load", () => setMapLoaded(true));
  });

  onCleanup(() => {
    setMapLoaded(false);
    map?.remove();
  });

  return (
    <>
      <div {...divProps} ref={mapElementRef}>
        <Show when={mapLoaded()}>
          <MapProvider map={map}>{parentProps.children}</MapProvider>
        </Show>
      </div>
    </>
  );
};
