import { JSX, Match, ParentComponent, Switch, createSignal, splitProps } from "solid-js";
import styles from "./scss/modules/MyButton.module.scss";

type ButtonColor =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "available"
  | "occupied"
  | "invisible"
  | "outofservice"
  | "dark"
  | "light";
type ButtonVariant = "outline" | "border" | undefined;
type ButtonSize = "sm" | "lg";
type SpinnerStyle = "border" | "grow";

type Props = {
  iconElement?: JSX.Element;
  iconClass?: string;
  color: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
  spinner?: SpinnerStyle;
  onclick?: () => any;
};

export const MyButton: ParentComponent<
  Props & Omit<JSX.ButtonHTMLAttributes<HTMLDivElement>, "onClick" | "onclick">
> = (allProps) => {
  const [props, buttonProps] = splitProps(allProps, [
    "children",
    "iconElement",
    "iconClass",
    "color",
    "variant",
    "size",
    "spinner",
    "class",
    "loading",
    "onclick",
  ]);

  const buttonClasses = props.class?.split(" ") ?? [];

  const classes = () =>
    [
      styles.button,
      ...buttonClasses,
      "btn",
      props.size ? `btn-${props.size}` : "",
      `btn-${allProps.variant ? `${allProps.variant}-` : ""}${allProps.color}`,
    ].join(" ");

  const [loading, setLoading] = createSignal(false);

  async function handleAsyncClick() {
    setLoading(true);
    try {
      await props.onclick?.();
    } finally {
      setLoading(false);
    }
  }

  const isLoading = () => loading() || props.loading;

  return (
    <div
      class={classes()}
      {...buttonProps}
      classList={{ "grayed-out": isLoading() || buttonProps.disabled }}
      onClick={handleAsyncClick}
    >
      <Switch>
        <Match when={props.spinner && isLoading()}>
          <div class={styles.spinner}>
            <i class={`spinner-${props.spinner ?? "border"} spinner-${props.spinner ?? "border"}-sm`} />
          </div>
        </Match>
        <Match when={props.iconElement}>{props.iconElement}</Match>
        <Match when={props.iconClass}>
          <i class={props.iconClass} />
        </Match>
      </Switch>
      {props.children}
    </div>
  );
};
