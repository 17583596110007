import { z } from "./openapizod";

export const requestBody = z.object({
  search_text: z.string(),
  language: z.enum(["de", "en", "it"]).optional().default("it"),
});

export type RequestBody = z.infer<typeof requestBody>;

export const responseBodyItem = z.object({
  google_place_id: z.string(),
  name: z.string(),
  formatted_address: z.string(),
  types: z.array(z.string()),
});

export type ResponseBodyItem = z.infer<typeof responseBodyItem>;
export type ResponseBody = ResponseBodyItem[];
