import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { A } from "@solidjs/router";
import { Component, createSignal, Show } from "solid-js";
import { z } from "zod";
import { ct } from "./i18n";
import * as localized from "./localized_schemas";
import { setLastUsedEmail } from "./storage";

const getSchema = () =>
  z.object({
    email: localized.email(),
    password: localized.requiredString(),
  });

type Schema = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  onSubmit?: (loginData: Schema) => Promise<void>;
  resetPasswordHref?: string;
  registerHref?: string;
  email?: string;
};

export const LoginForm: Component<Props> = (props) => {
  const [loginStatus, setLoginStatus] = createSignal<"ready" | "error" | "loading">("ready");

  const { form, errors, isValid, resetField, data } = createForm<Schema>({
    extend: [validator({ schema: getSchema() })],
    initialValues: {
      email: props.email,
    },
    onSubmit: async (values) => {
      setLoginStatus("loading");
      try {
        await props.onSubmit?.(values);
        setLoginStatus("ready");
        setLastUsedEmail(values.email);
      } catch (error) {
        console.log(error);
        setLoginStatus("error");
        resetField("password");
      }
    },
  });

  const resetPasswordHref = () => props.resetPasswordHref && `${props.resetPasswordHref}?email=${data().email}`;
  const registerHref = () => props.registerHref && `${props.registerHref}?email=${data().email}`;

  return (
    <form ref={form}>
      <fieldset disabled={loginStatus() === "loading"}>
        <div class="form-floating mb-1">
          <input
            name="email"
            type="email"
            class="form-control"
            classList={{ "is-invalid": !!errors().email?.length }}
            placeholder="name@example.com"
          />
          <label for="email" class="form-label">
            {ct.account.email._()}
          </label>
          <div class="text-danger" classList={{ invisible: !errors().email?.length }}>
            {errors().email?.[0] ?? "-"}
          </div>
        </div>
        <div class="form-floating mb-1">
          <input
            name="password"
            type="password"
            class="form-control"
            classList={{ "is-invalid": !!errors().password?.length }}
            placeholder={ct.account.password()}
          />
          <label for="password" class="form-label">
            {ct.account.password()}
          </label>
          <div class="text-danger" classList={{ invisible: !errors().password?.length }}>
            {errors().password?.[0] ?? "-"}
          </div>
          <Show when={resetPasswordHref()}>
            <A href={resetPasswordHref()!} class="form-text">
              {ct.account.resetPassword()}
            </A>
          </Show>
        </div>
        <Show when={loginStatus() === "error"}>
          <div class="alert alert-danger">{ct.validation.login.error()}</div>
        </Show>
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100 d-flex justify-content-center align-items-center gap-2"
          disabled={!isValid()}
        >
          <Show when={loginStatus() === "loading"}>
            <div class="spinner-border spinner-border-sm" />
          </Show>
          {ct.account.login()}
        </button>
        <Show when={registerHref()}>
          <A href={registerHref()!} class="form-text">
            {ct.account.register()}
          </A>
        </Show>
      </fieldset>
    </form>
  );
};

export default LoginForm;
