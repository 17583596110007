import { ErrorBoundary, ParentComponent, Show, mergeProps } from "solid-js";
import { ct } from "./i18n";
import { Default } from "./util";

type Props = {
  retry: (() => any) | undefined;
  layout?: "horizontal" | "vertical";
  size?: "sm" | "lg";
  Wrapper?: ParentComponent;
};

export const ErrorBoundaryFallback: ParentComponent<Props> = (props) => {
  const mergedProps = mergeProps<[Default<Props>, Props]>(
    {
      layout: "vertical",
      size: "sm",
      Wrapper: (props) => (
        <div class="h-100 w-100 d-flex align-items-center justify-content-center">{props.children}</div>
      ),
      retry: undefined,
    },
    props
  );

  return (
    <ErrorBoundary
      fallback={(error, resetError) => (
        <mergedProps.Wrapper>
          <div
            class="d-flex gap-2 flex-wrap align-items-center justify-content-center"
            classList={{
              "flex-column": mergedProps.layout === "vertical",
              "flex-row": mergedProps.layout === "horizontal",
            }}
          >
            <div
              class="fw-bold"
              classList={{
                "fs-3": mergedProps.size === "lg",
              }}
            >
              {ct.common.error()}
            </div>
            <code>{error.toString()}</code>
            <Show when={mergedProps.retry}>
              <button
                class="btn btn-sm btn-border-primary"
                classList={{
                  "btn-sm": mergedProps.size === "sm",
                }}
                onclick={() => {
                  mergedProps.retry!();
                  resetError();
                }}
              >
                {ct.common.tryAgain()}
              </button>
            </Show>
          </div>
        </mergedProps.Wrapper>
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
};
