import { PlusCircle } from "lucide-solid";
import { Component, createUniqueId } from "solid-js";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  disabled?: boolean;
  maxPlaceCount?: number;
};

export const PlaceAddButton: Component<Props> = (props) => {
  const {
    placeContainersSignal: [placeContainers, setPlaceContainers],
    emitter,
  } = useRideFormContext();

  function add() {
    if (props.maxPlaceCount && placeContainers().length >= props.maxPlaceCount) return;
    const newId = createUniqueId();
    setPlaceContainers([...placeContainers(), { containerId: newId, place: undefined }]);
    emitter.emit("placeSearch.show", newId);
  }

  return (
    <button
      disabled={props.disabled || !!(props.maxPlaceCount && placeContainers().length >= props.maxPlaceCount)}
      class="btn btn-light p-2"
      onclick={add}
    >
      <PlusCircle size={32} />
    </button>
  );
};
