import { GeoJSONSource } from "maplibre-gl";
import * as API from "mooovex-api-schema";
import { Component, createEffect, createUniqueId, onCleanup, onMount } from "solid-js";
import { useMapContext } from "./map.common";

type Props = {
  linestring?: API.Shared.GeoJson.LineString;
  style?: "default" | "return_route";
};

export const MapLineString: Component<Props> = (props) => {
  const map = useMapContext();
  const layerId = createUniqueId();
  onMount(() => {
    map.addLayer({
      id: layerId,
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        lineMetrics: props.style !== "return_route",
      },
      paint:
        props.style === "return_route"
          ? {
              "line-color": "red",
              "line-width": 3,
              "line-dasharray": [3, 2],
            }
          : {
              "line-width": 4,
              "line-gradient": ["interpolate", ["linear"], ["line-progress"], 0, "#2b71b7", 1, "#23bb4c"],
            },
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
    });
  });

  createEffect(() => {
    const source = map.getSource(layerId) as GeoJSONSource | undefined;
    const features = [];
    if (props.linestring) {
      features.push({
        type: "Feature",
        geometry: props.linestring,
        properties: {},
      } satisfies API.Shared.GeoJson.Feature);
    }
    if (source) {
      source.setData({
        type: "FeatureCollection",
        features,
      });
    }
  });

  onCleanup(() => {
    map.removeLayer(layerId);
  });
  return null;
};
