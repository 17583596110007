import * as API from "mooovex-api-schema";
import { Component, Show } from "solid-js";
import { useRideFormContext } from "../rideForm/rideForm.common";
import classes from "./PlaceSearchContainer.module.scss";
import { PlaceSearchIcon } from "./PlaceSearchIcon";

type Props = {
  containerId: string;
  place?: API.google_place_details.ResponseBody;
  disabled?: boolean;
  placeholder?: string;
  loading?: boolean;
};

export const PlaceSearchContainerOverlay: Component<Props> = (props) => {
  const address = () => props.place && API.PlaceAdapter.getAddress(props.place);

  const {
    placeContainersSignal: [placeContainers],
  } = useRideFormContext();

  const sortedPlaceContainers = () => placeContainers().filter((c) => c.place);
  const myIndex = () => sortedPlaceContainers().findIndex((c) => c.containerId === props.containerId);

  return (
    <div
      class="input-group flex-nowrap bg-light"
      classList={{
        "grayed-out": props.disabled,
      }}
    >
      <div class={classes.draghandle}>
        <PlaceSearchIcon
          loading={props.loading}
          place={props.place}
          color={
            props.place &&
            (myIndex() === 0 ? "#2b71b7" : myIndex() === sortedPlaceContainers().length - 1 ? "#23bb4c" : undefined)
          }
        />
      </div>
      <Show
        when={props.place}
        fallback={<div class={`${classes.placeinput} ${classes.empty} text-muted`}>{props.placeholder}</div>}
      >
        {(pl) => (
          <>
            <div class={classes.placeinput}>
              <span class="fw-bold">{pl().name}</span>&nbsp;<span class="text-muted">{address()}</span>
            </div>
            <i class={`${classes.x} bi-x-lg`} />
          </>
        )}
      </Show>
    </div>
  );
};
