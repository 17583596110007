import { Component, Show } from "solid-js";
import { currentLocale } from "../i18n";
import { useRideFormContext } from "./rideForm.common";

type Props = {
  disabled?: boolean;
};

export const StartDateButton: Component<Props> = (props) => {
  const {
    emitter,
    startDateSignal: [startDate],
  } = useRideFormContext();
  const formatter = () => Intl.DateTimeFormat(currentLocale(), { day: "2-digit", month: "2-digit", year: "numeric" });

  return (
    <button
      disabled={props.disabled}
      class="btn btn-lg flex-grow-1 flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !startDate(),
        "btn-primary": !!startDate(),
      }}
      onclick={() => emitter.emit("startDatePicker.show", startDate())}
    >
      <i class="bi-calendar-week" />
      <Show when={startDate()}>{(date) => <span class="ms-1">{formatter().format(date().toDate())}</span>}</Show>
    </button>
  );
};
