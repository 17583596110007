import { ParentComponent, createContext, useContext } from "solid-js";

type Props = {
  map: maplibregl.Map;
};
export const MapContext = createContext<maplibregl.Map>();

export const MapProvider: ParentComponent<Props> = (props) => {
  return <MapContext.Provider value={props.map}>{props.children}</MapContext.Provider>;
};
