import { GeoJSONSource } from "maplibre-gl";
import type * as API from "mooovex-api-schema";
import { Component, createEffect, createUniqueId, mergeProps, onCleanup, onMount } from "solid-js";
import { useMapContext } from "./map.common";

type Props = {
  features: API.Shared.GeoJson.Feature[];
  fontSize?: number;
  textColor?: string;
  textStrokeColor?: string;
  opacity?: number;
  alignment?: "map" | "viewport";
  minzoom?: number;
  maxzoom?: number;
  propertyKeys: {
    text: string;
    fontSize?: string;
    textColor?: string;
    textStrokeColor?: string;
    opacity?: string;
    disabled?: string;
  };
};

export const MapFeatureSymbols: Component<Props> = (componentProps) => {
  const props = mergeProps<[Partial<Props>, Props]>(
    {
      alignment: "viewport",
      textColor: "black",
      textStrokeColor: "white",
      opacity: 1,
      minzoom: 13,
      maxzoom: 24,
      fontSize: 12,
    },
    componentProps
  );

  const map = useMapContext();
  const layerId = createUniqueId();
  onMount(() => {
    map.addLayer({
      id: layerId,
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
      filter: props.propertyKeys?.disabled ? ["!=", "true", props.propertyKeys.disabled] : undefined,
      layout: {
        "text-field": ["get", props.propertyKeys.text],
        "text-size": props.propertyKeys?.fontSize ? ["get", props.propertyKeys.fontSize] : props.fontSize,
      },
      paint: {
        "text-halo-width": 1,
        "text-color": props.propertyKeys?.textColor ? ["get", props.propertyKeys.textColor] : props.textColor,
        "text-halo-color": props.propertyKeys?.textStrokeColor
          ? ["get", props.propertyKeys.textStrokeColor]
          : props.textStrokeColor,
        "text-opacity": props.propertyKeys?.opacity ? ["get", props.propertyKeys.opacity] : props.opacity,
      },
      minzoom: props.minzoom,
      maxzoom: props.maxzoom,
    });
  });

  createEffect(() => {
    const source = map.getSource(layerId) as GeoJSONSource | undefined;
    if (source) {
      source.setData({
        type: "FeatureCollection",
        features: props.features,
      });
    }
  });

  onCleanup(() => {
    map.removeLayer(layerId);
  });
  return null;
};
