import { Component, For, Show } from "solid-js";

import { Tables } from "supabase";
import {
  liveTaxistationsMap,
  setActiveTaxistationRequest,
  setDisabledTaxistationRequest,
  setDisabledZipcodeRequest,
  zipcodesCache,
} from "supabase-client";
import classes from "./Taxistations.module.scss";

export const TaxistationsC: Component = () => {
  const groupedTaxistations = () =>
    Array.from(liveTaxistationsMap.values()).reduce<Record<string, Tables<"taxistations">[]>>((acc, station) => {
      const { zipcode_id } = station;
      if (acc[zipcode_id]) {
        acc[zipcode_id].push(station);
      } else {
        acc[zipcode_id] = [station];
      }
      return acc;
    }, {});

  const zipcode_ids = () => Object.keys(groupedTaxistations()).map((v) => parseInt(v));

  return (
    <div class={classes.container}>
      <For each={zipcode_ids()}>
        {(zipcode_id) => {
          const taxistationsOfZipcode_id = () => groupedTaxistations()[zipcode_id];
          const zipcodeEnabled = () => taxistationsOfZipcode_id().some((t) => !t.disabled);
          return (
            <>
              <div class="d-flex">
                <div class="fw-bold flex-grow-1">{zipcodesCache.get(zipcode_id)?.municipiality ?? zipcode_id}</div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={zipcodeEnabled()}
                    onclick={async () => await setDisabledZipcodeRequest(zipcode_id, zipcodeEnabled())}
                  />
                </div>
              </div>
              <ul class={`list-group mb-2 ${classes.listgroup}`}>
                <For each={taxistationsOfZipcode_id()}>
                  {(taxistation) => (
                    <li class="list-group-item text-start py-0" classList={{ active: taxistation.active }}>
                      <div class="d-flex">
                        <div
                          class="flex-grow-1"
                          classList={{
                            "text-decoration-line-through": taxistation.disabled,
                            "cursor-pointer": !taxistation.disabled,
                          }}
                          onclick={async () =>
                            !taxistation.disabled &&
                            (await setActiveTaxistationRequest(taxistation.id, taxistation.zipcode_id))
                          }
                        >
                          {taxistation.name}
                        </div>
                        <Show when={!taxistation.active}>
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input cursor-pointer"
                              type="checkbox"
                              checked={!taxistation.disabled}
                              onclick={async () =>
                                await setDisabledTaxistationRequest(taxistation.id, !taxistation.disabled)
                              }
                            />
                          </div>
                        </Show>
                      </div>
                    </li>
                  )}
                </For>
              </ul>
            </>
          );
        }}
      </For>
    </div>
  );
};
