import { z } from "./openapizod";
import { ResponseBodyItem as GHResponseItem, responseBodyItem as ghResponseItem } from "./autocomplete.api.schema";
import { responseBodyItem as googleResponseItem } from "./google.autocomplete.api.schema";
import { responseBody as googleResponse } from "./google.place_details.api.schema";

export function isGh(value: AutocompleteItem | Place): value is GHResponseItem {
  return "osm_id" in value;
}

export function getId(value: Place | AutocompleteItem): string | number {
  return isGh(value) ? value.osm_id : value.google_place_id;
}

export function getCoordinates(value: Place) {
  return isGh(value) ? value.point : value.location.coordinates;
}

export function getAddress(value: Place | AutocompleteItem) {
  return isGh(value)
    ? [value.street, value.housenumber, value.city, value.postcode, value.state, value.country]
        .filter((v) => typeof v !== "undefined")
        .join(", ")
    : value.formatted_address;
}

export const autocompleteItem = ghResponseItem.or(googleResponseItem);
export const place = ghResponseItem.or(googleResponse);

export type AutocompleteItem = z.infer<typeof autocompleteItem>;
export type Place = z.infer<typeof place>;
