import { captureException } from "@sentry/browser";
import { debounce } from "@solid-primitives/scheduled";
import { makePersisted } from "@solid-primitives/storage";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, Show, createSignal, onCleanup, onMount } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useRideFormContext } from "../rideForm/rideForm.common";
import { createUserStorageSignal } from "../storage";
import { PlaceSearchResultList } from "./placeSearch.common";
type Props = {};

const [placeFav, setPlaceFav] = createUserStorageSignal<
  Record<number | string, API.google_autocomplete.ResponseBodyItem>
>("placeSearch.favorites", {});

export const PlaceSearchOverlay: Component<Props> = (props) => {
  const { emitter } = useRideFormContext();

  async function autocomplete(value: string) {
    if (value.length < 3) {
      setPlaces(undefined);
      return;
    }

    try {
      const result = await mooovexApiClient.place.autocomplete(value, currentLocale());
      setPlaces(result);
    } catch (error) {
      setPlaces(undefined);
      emitter.emit("placeSearch.error", ct.place.failedToGetPlaces());
      captureException(error);
    }
  }

  const debouncedAutocomplete = debounce(autocomplete, 250);
  const [isOpen, setIsOpen] = createSignal(false);
  const [places, setPlaces] = createSignal<API.google_autocomplete.ResponseBodyItem[]>();
  const [currentContainerId, setCurrentContainerId] = createSignal<string>();

  function onEscapeKey(e: KeyboardEvent) {
    if (e.key === "Escape") {
      close();
    }
  }

  onMount(() => {
    emitter
      .on("placeSearch.show", show)
      .on("placeSearch.value", close)
      .on("placeSearch.currentLocation", close)
      .on("placeSearch.value", onPlaceSearchValue)
      .on("placeSearch.favorite.add", addToPlaceFavorites)
      .on("placeSearch.favorite.remove", removeFromPlaceFavorites);
  });

  onCleanup(() => {
    emitter
      .off("placeSearch.show", show)
      .off("placeSearch.value", close)
      .off("placeSearch.currentLocation", close)
      .off("placeSearch.value", onPlaceSearchValue)
      .off("placeSearch.favorite.add", addToPlaceFavorites)
      .off("placeSearch.favorite.remove", removeFromPlaceFavorites);

    removeEventListener("keyup", onEscapeKey);
  });

  const [placeSearchHistory, setPlaceSearchHistory] = makePersisted(
    createSignal<API.google_autocomplete.ResponseBodyItem[]>([]),
    {
      name: "places.history",
    }
  );

  function addToPlaceSearchHistory(place: API.google_autocomplete.ResponseBodyItem) {
    const id = API.PlaceAdapter.getId(place);
    if (placeSearchHistory().some((p) => API.PlaceAdapter.getId(p) === id)) return;
    setPlaceSearchHistory((prev) => [place, ...prev].slice(0, 3));
  }

  function show(containerId: string) {
    setCurrentContainerId(containerId);

    transitionElementRef.addEventListener("transitionend", onTransitionEnd, { once: true });

    setIsOpen(true);
    addEventListener("keyup", onEscapeKey);
  }

  function onTransitionEnd() {
    inputRef.focus();
  }

  function onPlaceSearchValue(containerId: string, place?: API.google_autocomplete.ResponseBodyItem) {
    if (place) {
      addToPlaceSearchHistory(place);
    }
  }

  function close() {
    transitionElementRef.removeEventListener("transitionend", onTransitionEnd);
    removeEventListener("keyup", onEscapeKey);
    setIsOpen(false);
    debouncedAutocomplete.clear();
    setPlaces(undefined);
    if (inputRef) inputRef.value = "";
  }

  function addToPlaceFavorites(place: API.google_autocomplete.ResponseBodyItem) {
    setPlaceFav((old) => ({ ...old, [API.PlaceAdapter.getId(place)]: place }));
  }

  function removeFromPlaceFavorites(id: number | string) {
    setPlaceFav((old) => {
      delete old[id];
      return { ...old };
    });
  }

  let inputRef!: HTMLInputElement;
  let transitionElementRef!: HTMLDivElement;

  return (
    <div
      ref={transitionElementRef}
      class="d-flex flex-column"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "z-index": 70,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${isOpen() ? "0" : "100%"})`,
        overflow: "hidden",
      }}
    >
      <Show when={currentContainerId()}>
        {(currentContainerId) => (
          <>
            <div class="input-group shadow p-2">
              <button class="btn btn-border-dark bi-arrow-left" onclick={close} />
              <input
                type="text"
                class="form-control form-control-lg"
                ref={inputRef}
                oninput={(e) => debouncedAutocomplete(e.currentTarget.value)}
              />
            </div>
            <div class="flex-grow-1 overflow-scroll px-2">
              <hr />
              <div
                class="d-flex align-items-center cursor-pointer"
                onclick={() => emitter.emit("placeSearch.currentLocation", currentContainerId())}
              >
                <i class="bi-bullseye me-2" />
                <span class="fw-bold">{ct.ride.currentLocation()}</span>
              </div>
              <hr />
              <Show
                when={places()}
                fallback={
                  <>
                    <Show when={placeFav() && Object.keys(placeFav()).length}>
                      <div class="text-muted lh-1 small text-center">
                        <i class="bi-star-fill me-2" />
                        {ct.place.search.favorites()}
                      </div>
                      <PlaceSearchResultList
                        places={Object.values(placeFav())}
                        containerId={currentContainerId()}
                        favorites={placeFav()}
                        compact
                      />
                      <hr />
                    </Show>
                    <Show when={placeSearchHistory().length}>
                      <div class="text-muted lh-1 small text-center">
                        <i class="bi-clock-history" />
                        &nbsp;{ct.place.search.history()}
                      </div>
                      <PlaceSearchResultList
                        places={placeSearchHistory()}
                        containerId={currentContainerId()}
                        favorites={placeFav()}
                        compact
                      />
                      <hr />
                    </Show>
                  </>
                }
              >
                {(places) => (
                  <>
                    <div class="text-muted lh-1 small text-center">
                      <i class="bi-search" />
                      &nbsp;
                      <Show when={places().length} fallback={ct.common.noResults()}>
                        {ct.place.search.results()}
                      </Show>
                    </div>
                    <PlaceSearchResultList
                      places={places()}
                      containerId={currentContainerId()}
                      favorites={placeFav()}
                    />
                    <hr />
                  </>
                )}
              </Show>
            </div>
          </>
        )}
      </Show>
    </div>
  );
};
