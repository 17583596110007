import AirDatepicker, { AirDatepickerButtonPresets, AirDatepickerLocale, AirDatepickerPosition } from "air-datepicker";
import deLocale from "air-datepicker/locale/de";
import enLocale from "air-datepicker/locale/en";
import itLocale from "air-datepicker/locale/it";
import { RideFormEmitter, useRideFormContext } from "components/src/rideForm/rideForm.common";
import { MyDate, MyTimestamp } from "helpers";
import { Locale } from "i18n-base";
import { Component, createEffect, onCleanup, onMount } from "solid-js";

deLocale.clear = "Löschen";

const locales: Record<Locale, AirDatepickerLocale> = {
  de: deLocale,
  en: enLocale,
  it: itLocale,
};

type Props = {
  locale: Locale;
  placeholder?: string;
  datepicker?: boolean;
  timepicker?: boolean;
  onSelect?: (value: MyTimestamp | undefined) => void;
  clearButton?: boolean;
  class?: string;
  position: AirDatepickerPosition;
};

export const Datepicker: Component<Props> = (props) => {
  let inputRef!: HTMLInputElement;
  let datepicker: AirDatepicker;

  let emitter: RideFormEmitter | undefined;
  try {
    emitter = useRideFormContext().emitter;
  } catch (error) {}

  function onValue(value: MyDate | undefined) {
    if (!value) datepicker.clear();
  }

  onMount(() => {
    datepicker = new AirDatepicker(inputRef, { autoClose: true });
    emitter?.on("startDatePicker.value", onValue);
  });

  createEffect(() => {
    const buttonPresets: AirDatepickerButtonPresets[] = [];
    if (props.clearButton) buttonPresets.push("clear");
    datepicker.update({
      locale: locales[props.locale],
      onlyTimepicker: !(props.datepicker ?? true),
      timepicker: props.timepicker,
      buttons: buttonPresets,
      classes: props.class,
      onSelect({ date }) {
        if (date === undefined) {
          props.onSelect?.(undefined);
          return;
        }
        if (date instanceof Date) {
          props.onSelect?.(MyTimestamp.fromDate(date));
        }
      },
      position: props.position,
    });
  });

  onCleanup(() => {
    emitter?.off("startDatePicker.value", onValue);
    datepicker.destroy();
  });

  return <input class="form-control" type="text" readonly ref={inputRef} placeholder={props.placeholder} />;
};
