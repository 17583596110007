import { d } from "i18n-base";
import * as API from "mooovex-api-schema";
import { z } from "zod";

export const requiredString = () => z.string().min(1, d().validation.requiredField);
export const email = () => z.string().email(d().validation.emailInvalid);
export const phoneNumber = () =>
  z
    .string()
    .refine((input) => API.Shared.isValidPhoneNumber(input), d().validation.invalidPhone)
    .transform((input) => API.Shared.parsePhoneNumber(input).number.toString());
