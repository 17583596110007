import { z } from "./openapizod";
import * as Shared from "./shared.api.schema";

export const responseBodyItem = z.object({
  point: Shared.GeoJson.coordinates,
  extent: Shared.GeoJson.coordinates.array().min(2).max(2).optional(),
  osm_id: z.number(),
  osm_type: z.enum(["N", "R", "W"]),
  osm_key: z.string(),
  osm_value: z.string(),
  name: z.string(),
  country: z.string(),
  countrycode: z.string(),
  city: z.string().optional(),
  state: z.string().optional(),
  street: z.string().optional(),
  housenumber: z.string().optional(),
  postcode: z.string().optional(),
});

export type ResponseBodyItem = z.infer<typeof responseBodyItem>;

export const responseBody = z.array(responseBodyItem);

export type ResponseBody = z.infer<typeof responseBody>;

export const requestBody = z.object({
  q: z.string().min(1),
  limit: z.number().int().min(1).optional(),
  locale: z.enum(["de", "en", "fr"]).optional(),
  location_bias: Shared.GeoJson.coordinates.optional(),
  osm_tags: z.array(z.string().min(1)).optional(),
  bbox: z.tuple([Shared.GeoJson.coordinates.max(2), Shared.GeoJson.coordinates.max(2)]).optional(),
  provider: z.string().optional(),
});

export type RequestBody = z.infer<typeof requestBody>;
