import { Marker, Popup } from "maplibre-gl";
import { ParentComponent, Show, createEffect, onCleanup, onMount } from "solid-js";
import { render } from "solid-js/web";
import { liveLocationsCache, vehiclesCache } from "supabase-client";
import { driverMarkerSizeMultiplier } from "../storage";
import classes from "./MapDriverMarker.module.scss";
import { MapDriverMarkerPopup } from "./MapDriverMarkerPopup";
import { useMapContext } from "./map.common";
type Props = {
  driver_id: string;
  follow?: boolean;
};

export class MapDriverMarkerClass extends Marker {
  public static instances = new Map<string, MapDriverMarkerClass>();

  openPopup() {
    MapDriverMarkerClass.instances.forEach((marker) => marker.closePopup());
    this.getPopup().addTo(this._map);
  }

  closePopup() {
    this.getPopup().remove();
  }
}

export const MapDriverMarker: ParentComponent<Props> = (props) => {
  const map = useMapContext();
  let marker!: MapDriverMarkerClass;
  let popup!: Popup;
  let cleanupMarkerIcon: () => void;
  let cleanupPopup: () => void;

  const liveLocation = () => liveLocationsCache.get(props.driver_id)!;
  const vehicle = () => vehiclesCache.get(liveLocation().current_vehicle_id!);
  const driverstatus = () => liveLocation().status;
  const driverstatusClass = () => driverstatus() && classes[driverstatus()!];
  const identifier = () => vehicle()?.identifier;
  const heading = () => liveLocation().heading;
  const speed = () => liveLocation().speed ?? 0;

  onMount(() => {
    marker = new MapDriverMarkerClass({
      element: (<div class={`maplibregl-marker ${classes.marker} ${driverstatusClass()}`} />) as HTMLElement,
    })
      .setLngLat([liveLocation().longitude!, liveLocation().latitude!])
      .addTo(map);

    cleanupMarkerIcon = render(
      () => (
        <>
          <div class={`${classes.icon} ${driverstatusClass()}`}>{identifier()}</div>
          <Show when={typeof heading() === "number" && heading() !== -1 && speed() * 3.6 >= 10}>
            <div
              class={classes.direction}
              style={{
                transform: `rotate(${heading()}deg)`,
              }}
            >
              <div class={`${classes.arrow} ${driverstatusClass()}`} />
            </div>
          </Show>
        </>
      ),
      marker.getElement()
    );

    popup = new Popup({
      closeButton: true,
      closeOnMove: false,
      closeOnClick: true,
      anchor: "bottom",
      focusAfterOpen: false,
    });

    const tempDiv = document.createElement("div");
    cleanupPopup = render(() => <MapDriverMarkerPopup driver_id={props.driver_id} />, tempDiv);
    popup.setDOMContent(tempDiv.firstChild!);

    marker.setPopup(popup).addTo(map);

    MapDriverMarkerClass.instances.set(props.driver_id, marker);
  });

  createEffect(() => popup.setOffset([0, -13 * driverMarkerSizeMultiplier()]));

  onCleanup(() => {
    cleanupMarkerIcon();
    cleanupPopup();
    marker.setPopup(undefined);
    popup.remove();
    marker.remove();
    MapDriverMarkerClass.instances.delete(props.driver_id);
  });

  createEffect(() => {
    const { latitude, longitude } = liveLocation();
    if (latitude && longitude) {
      marker.setLngLat([longitude, latitude]);
      if (props.follow) {
        const zoomlevel = calculateZoomLevel(speed() * 30, latitude, map.getContainer().clientWidth);
        map.flyTo({ center: marker.getLngLat(), zoom: Math.min(zoomlevel, 18) });
      }
    }
  });

  return null;
};

function calculateZoomLevel(widthMeters: number, latitude: number, mapWidth: number): number {
  // Constants
  const earthCircumference = 40075016.686; // Equatorial circumference of the Earth in meters
  const tileSize = 512; // Tile size in pixels

  // Calculate the zoom level
  const zoomLevel = Math.log2(
    (earthCircumference * Math.cos(latitude * (Math.PI / 180))) / ((widthMeters / mapWidth) * tileSize)
  );

  return zoomLevel;
}
