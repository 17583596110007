import { ReactiveMap } from "@solid-primitives/map";
import { batch } from "solid-js";
import { Tables } from "supabase";
import { supabase, userSignal } from "./supabase";

export const staffCache = new ReactiveMap<string, Tables<"staff">>();

export const myStaff = () => staffCache.get(userSignal()?.id!);

export async function fetchStaffById(id: string) {
  const { data, error } = await supabase.from("staff").select().eq("user_id", id).maybeSingle();

  if (error) throw error;

  if (data) {
    staffCache.set(data.user_id, data);
  } else {
    staffCache.delete(id);
  }

  return data;
}

export async function fetchStaffByIdBatch(ids: string[]) {
  const { data, error } = await supabase.from("staff").select().in("user_id", ids);

  if (error) throw error;

  batch(() => {
    for (const staff of data) {
      staffCache.set(staff.user_id, staff);
    }
  });
  return data;
}

export async function fetchMyStaff() {
  const userId = userSignal()?.id;
  if (userId) {
    return await fetchStaffById(userId);
  }

  return null;
}

export async function setLocaleRequest(locale: Tables<"staff">["locale"]) {
  const { data, error } = await supabase
    .from("staff")
    .update({ locale })
    .eq("user_id", userSignal()!.id)
    .select("*")
    .single();
  if (error) throw error;

  staffCache.set(userSignal()?.id!, data);

  return data.locale;
}
