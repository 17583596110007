import { Modal } from "bootstrap";
import { createContext, createEffect, createSignal, onCleanup, ParentComponent } from "solid-js";

export const ModalContext = createContext<ReturnType<typeof createModal>>();
function createModal(options?: Partial<Modal.Options>) {
  const [isVisible, setVisibility] = createSignal(false);
  const show = () => setVisibility(true);
  const hide = () => setVisibility(false);

  let modal: Modal;
  let ref: HTMLDivElement;

  function initModal(elem: HTMLDivElement) {
    ref = elem;
    modal = new Modal(ref, options);
    ref.addEventListener("hidden.bs.modal", () => setVisibility(false));
    ref.addEventListener("shown.bs.modal", () => setVisibility(true));

    createEffect(
      () => {
        if (isVisible()) {
          modal.show();
        } else {
          modal.hide();
        }
      },
      { defer: true }
    );
  }

  const modalFunctions = {
    isVisible,
    setVisibility,
    show,
    hide,
    onShow: (callback: () => void) => ref.addEventListener("show.bs.modal", callback),
    onHide: (callback: () => void) => ref.addEventListener("hide.bs.modal", callback),
    removeOnShow: (callback: () => void) => ref.removeEventListener("show.bs.modal", callback),
    removeOnHide: (callback: () => void) => ref.removeEventListener("hide.bs.modal", callback),
  } as const;

  const ModalComponent: ParentComponent<{
    size?: "sm" | "lg" | "xl";
    scrollable?: boolean;
    align?: "top" | "center" | "bottom";
  }> = (props) => {
    onCleanup(() => {
      modal.dispose();
    });

    const classList = {
      "modal-sm": props.size === "sm",
      "modal-lg": props.size === "lg",
      "modal-xl": props.size === "xl",
      "modal-dialog-scrollable": props.scrollable ?? true,
      "modal-dialog-centered": props.align === "center",
      "modal-dialog-bottom": props.align === "bottom",
    };

    return (
      <ModalContext.Provider value={[ModalComponent, modalFunctions] as const}>
        <div ref={initModal} class="modal fade" tabindex="-1">
          <div class="modal-dialog" classList={classList}>
            <div class="modal-content overflow-hidden">{props.children}</div>
          </div>
        </div>
      </ModalContext.Provider>
    );
  };

  return [ModalComponent, modalFunctions] as const;
}

export { createModal };
