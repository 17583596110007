import { useSearchParams } from "@solidjs/router";
import { FormCard, ResetPasswordForm } from "components";
import { Component } from "solid-js";
import { sendPasswordResetEmail } from "supabase-client";

const ResetPasswordPage: Component = () => {
  const [searchParams] = useSearchParams<{ email?: string }>();

  return (
    <FormCard>
      <ResetPasswordForm
        onSubmit={async ({ email }) => {
          await sendPasswordResetEmail(
            email,
            `${import.meta.env.VITE_FRONTEND_URL}/resetPassword/otp?email=${encodeURIComponent(email)}`
          );
        }}
        loginHref="/login"
        email={searchParams.email}
      />
    </FormCard>
  );
};

export default ResetPasswordPage;
