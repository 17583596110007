import { MyTime } from "./MyTime.class";
import { MyTimestamp } from "./MyTimestamp.class";
import { Clonable, Comparable, Serializable } from "./utils";

export class MyDate implements Serializable<string>, Clonable<MyDate>, Comparable {
  private constructor(date: Date) {
    this.date = new Date(date);
    this.date.setHours(0, 0, 0, 0);
  }

  private date: Date;

  static fromJSON(value: string): MyDate {
    return new MyDate(new Date(value));
  }

  static today(): MyDate {
    return new MyDate(new Date());
  }

  static fromDate(date: Date): MyDate {
    return new MyDate(date);
  }

  toDate(): Date {
    return new Date(this.date);
  }

  toString(): string {
    return `${this.date.getFullYear()}-${(this.date.getMonth() + 1).toString().padStart(2, "0")}-${this.date.getDate().toString().padStart(2, "0")}`;
  }

  clone(): MyDate {
    return new MyDate(this.date);
  }

  valueOf(): number {
    return this.date.valueOf();
  }

  toJSON(): string {
    return this.toString();
  }

  getYear(): number {
    return this.date.getFullYear();
  }

  getMonth(): number {
    return this.date.getMonth() + 1;
  }

  getDay(): number {
    return this.date.getDate();
  }

  getWeekday(): number {
    return ((this.date.getDay() + 6) % 7) + 1;
  }

  getMyTimestamp(myTime?: MyTime): MyTimestamp {
    return MyTimestamp.fromMyDateAndMyTime(this, myTime ?? MyTime.now());
  }
}
