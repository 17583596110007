import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { A } from "@solidjs/router";
import { ct } from "./i18n";
import { Component, Show, createSignal, onMount, useContext } from "solid-js";
import { z } from "zod";
import { ModalContext } from "./Modal";

type Props = {
  onSubmit?: (password: string) => Promise<void>;
  backHref?: string;
};

export const ChangePasswordForm: Component<Props> = (props) => {
  const [statusSignal, setStatusSignal] = createSignal<"loading" | "ready" | "error">("ready");

  const modalContext = useContext(ModalContext);

  const schema = z
    .object({
      newPassword: z.string().min(1),
      repeatNewPassword: z.string().min(1),
    })
    .superRefine(({ newPassword, repeatNewPassword }, ctx) => {
      if (newPassword !== repeatNewPassword) {
        ctx.addIssue({
          code: "custom",
          path: ["repeatNewPassword"],
          message: ct.validation.passwordsDontMatch(),
        });
      }
    });

  const { form, errors, isValid, reset } = createForm<z.infer<typeof schema>>({
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      setStatusSignal("loading");
      try {
        await props.onSubmit?.(values.newPassword);
        setStatusSignal("ready");
      } catch (error) {
        console.log(error);
        setStatusSignal("error");
      }
    },
  });

  if (modalContext) {
    const [Modal, { onHide }] = modalContext;
    onHide(reset);
  }
  return (
    <>
      <form ref={form}>
        <fieldset disabled={statusSignal() === "loading"}>
          <div class="form-floating mb-1">
            <input
              name="newPassword"
              type="password"
              class="form-control"
              classList={{ "is-invalid": !!errors().newPassword?.length }}
              placeholder=" "
            />
            <label for="newPassword" class="form-label">
              {ct.account.password()}
            </label>
            <div class="text-danger" classList={{ invisible: !errors().newPassword?.length }}>
              {errors().newPassword?.[0] ?? "-"}
            </div>
          </div>
          <div class="form-floating mb-1">
            <input
              name="repeatNewPassword"
              type="password"
              class="form-control"
              classList={{ "is-invalid": !!errors().repeatNewPassword?.length }}
              placeholder=" "
            />
            <label for="repeatNewPassword" class="form-label">
              {ct.account.passwordRepeat()}
            </label>
            <div class="text-danger" classList={{ invisible: !errors().repeatNewPassword?.length }}>
              {errors().repeatNewPassword?.[0] ?? "-"}
            </div>
          </div>
          <Show when={props.backHref}>
            <A href={props.backHref ?? "/"} class="form-text">
              {ct.common.back()}
            </A>
          </Show>
          <Show when={statusSignal() === "error"}>
            <div class="alert alert-danger">{ct.validation.passwordChange.error()}</div>
          </Show>
          <button
            type="submit"
            class="btn btn-lg btn-primary w-100 d-flex justify-content-center align-items-center gap-2"
            disabled={!isValid()}
          >
            <Show when={statusSignal() === "loading"}>
              <div class="spinner-border spinner-border-sm" />
            </Show>
            {ct.account.changePassword()}
          </button>
        </fieldset>
      </form>
    </>
  );
};
