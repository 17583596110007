import * as API from "mooovex-api-schema";
import { Component, For } from "solid-js";
import { PlaceSearchAutocompleteItem } from "./PlaceSearchAutocompleteItem";

type Props = {
  places: API.google_autocomplete.ResponseBodyItem[];
  containerId: string;
  favorites?: Record<number, API.google_autocomplete.ResponseBodyItem>;
  compact?: boolean;
};

export const PlaceSearchResultList: Component<Props> = (props) => {
  return (
    <For each={props.places}>
      {(place) => {
        const isFavorited = () => Object.keys(props.favorites ?? []).includes(API.PlaceAdapter.getId(place).toString());
        return (
          <>
            <hr />
            <PlaceSearchAutocompleteItem
              place={place}
              containerId={props.containerId}
              isFavorited={isFavorited()}
              compact={props.compact}
            />
          </>
        );
      }}
    </For>
  );
};
