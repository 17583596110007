import { wkx } from "wkx";
import { z } from "./openapizod";
import * as API from "./mooovex-api-schema";
import * as Shared from "./shared.api.schema";

export const rideSegmentType = z.enum(["empty", "normal", "waiting"]);

export type RideSegmentType = z.infer<typeof rideSegmentType>;

export const responseBody = z.object({
  id: z.string(),
  created_at: z.string(),
  ride_id: z.string(),
  driver_id: z.string().uuid().optional(),
  vehicle_id: z.string().optional(),
  ride_segment_type: rideSegmentType,
  sequence: z.number().int().optional(),
  start_place: API.autocomplete.responseBodyItem.optional(),
  end_place: API.autocomplete.responseBodyItem.optional(),
  start_date: z.string().optional(),
  start_time: z.string().optional(),
  end_date: z.string().optional(),
  end_time: z.string().optional(),
  passengers_got_on: z.number().int().optional(),
  passengers_got_off: z.number().int().optional(),
  surcharge: z.number().optional(),
  collected_geolocations: Shared.GeoJson.linestring.optional(),
  route_id: z.string().optional(),
  wait_time: z.number().int().optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;

export namespace Get {
  export const requestParams = z.object({ id: z.string() });
  export type RequestParams = z.infer<typeof requestParams>;
}

export namespace Post {
  export const requestBody = z.object({
    id: z.string().optional(),
    created_at: z.string().optional(),
    ride_id: z.string(),
    driver_id: z.string().uuid().optional(),
    vehicle_id: z.string().optional(),
    ride_segment_type: rideSegmentType,
    sequence: z.number().int().optional(),
    start_place: API.autocomplete.responseBodyItem.optional(),
    end_place: API.autocomplete.responseBodyItem.optional(),
    start_date: z.string().optional(),
    start_time: z.string().optional(),
    end_date: z.string().optional(),
    end_time: z.string().optional(),
    passengers_got_on: z.number().int().optional(),
    passengers_got_off: z.number().int().optional(),
    surcharge: z.number().optional(),
    collected_geolocations: z.instanceof(wkx.MultiPoint).optional(),
    route_id: z.string().optional(),
    wait_time: z.number().int().optional(),
  });
  export type RequestBody = z.infer<typeof requestBody>;
}
