import { MyDate, MyTime } from "helpers";
import type * as API from "mooovex-api-schema";
import { ParentComponent, Signal, createContext } from "solid-js";
import { RideFormEmitter } from "./rideForm.common";

type Props = Context & {};

export type PlaceContainer = {
  containerId: string;
  place: API.google_place_details.ResponseBody | undefined;
};

type Context = {
  emitter: RideFormEmitter;
  routeSignal: Signal<API.route.ResponseBody | undefined>;
  priceSignal: Signal<number | undefined>;
  discountSignal: Signal<number>;
  passengerCountSignal: Signal<number | undefined>;
  startTimeSignal: Signal<MyTime | undefined>;
  startDateSignal: Signal<MyDate | undefined>;
  centeredDriverIdSignal: Signal<string | undefined>;
  placeContainersSignal: Signal<PlaceContainer[]>;
  routeLoadingSignal: Signal<boolean>;
  priceLoadingSignal: Signal<boolean>;
  sortedWaypoints: () => API.google_place_details.ResponseBody[];
  formVisibleSignal: Signal<boolean>;
};

export const RideFormContext = createContext<Context>();

export const RideFormProvider: ParentComponent<Props> = (props) => {
  return <RideFormContext.Provider value={props}>{props.children}</RideFormContext.Provider>;
};
