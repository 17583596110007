import { z } from "./openapizod";

export const bookingType = z.enum(["booking", "direct", "offer"]);

export type BookingType = z.infer<typeof bookingType>;

export const responseBody = z.object({
  id: z.string(),
  created_at: z.string(),
  booking_type: bookingType,
  confirmed: z.boolean(),
  created_by: z.string().uuid().optional(),
  transportcompany_id: z.string().optional(),
  comments: z.string().optional(),
  customer_id: z.string().optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;

export namespace Get {
  export const requestParams = z.object({ id: z.string() });
  export type RequestParams = z.infer<typeof requestParams>;
}

export namespace Post {
  export const requestBody = z.object({
    booking_type: bookingType,
    id: z.string().optional(),
    created_at: z.string().optional(),
    created_by: z.string().uuid().optional(),
    transportcompany_id: z.string().optional(),
    confirmed: z.boolean().optional(),
    comments: z.string().optional(),
    customer_id: z.string().optional(),
  });
  export type RequestBody = z.infer<typeof requestBody>;
}
