import maplibregl from "maplibre-gl";
import { ParentComponent, createEffect, onCleanup, onMount } from "solid-js";
import { useMapContext } from "./map.common";
type Props = {
  lngLat?: number[];
  color?: string;
};

export const MapPlaceMarker: ParentComponent<Props> = (props) => {
  const map = useMapContext();
  let marker!: maplibregl.Marker;
  onMount(() => {
    marker = new maplibregl.Marker({
      element: (
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="27.38" height="27.38" viewBox="0 0 27.38 27.38">
            <circle cx="13.69" cy="13.69" r="13.69" style="opacity:.2" />
            <circle cx="13.69" cy="13.69" r="6.03" />
          </svg>
        </div>
      ) as HTMLElement,
    });
  });

  createEffect(() => {
    if (props.lngLat) {
      marker.setLngLat([props.lngLat[0], props.lngLat[1]]);
      marker.addTo(map);
    } else {
      marker.remove();
    }
  });

  createEffect(() => {
    const svgs = marker.getElement().getElementsByTagName("svg");
    if (svgs.length) {
      for (const path of svgs[0].getElementsByTagName("path")) {
        path.setAttribute("fill", props.color ?? "#000");
      }

      for (const circle of svgs[0].getElementsByTagName("circle")) {
        circle.setAttribute("fill", props.color ?? "#000");
      }
    }
  });

  onCleanup(() => {
    marker.remove();
  });

  return null;
};
